import { GraphqlSelectInput } from '../../inputs/graphqlSelectInput';
import styled from 'styled-components';
import { roomsForGateway } from '../../../graph/rooms';
import { update as updateGateway } from '../../../graph/gateways';
import React from 'react';
import { GATEWAY_TYPE_LABELS, GATEWAY_TYPES } from '../../entities/room/enums';
import responsive from '../../../se/utilities/responsive';
import { Mutation } from '@apollo/client/react/components';

const InputWrapper = styled.div`
  width: 15em;

  ${responsive.xs.andSmaller`
    width: 100%;
  `};
`;

const ENROLMENT_READER_VALUE = -1;
const DISCHARGE_GATEWAY_VALUE = -2;
const EXIT_GATEWAY_VALUE = -3;

class UpdateSelect extends React.Component {
  constructor(props) {
    super(props);
    if (props.type === GATEWAY_TYPES.ENROLMENT_READER) {
      this.state = { value: ENROLMENT_READER_VALUE };
    } else if (props.type === GATEWAY_TYPES.DISCHARGE_GATEWAY) {
      this.state = { value: DISCHARGE_GATEWAY_VALUE };
    } else if (props.type === GATEWAY_TYPES.EXIT_GATEWAY) {
      this.state = { value: EXIT_GATEWAY_VALUE };
    } else {
      this.state = { value: this.props.room };
    }
  }

  onChange = mutation => value => {
    const { room, gateway } = this.props;

    this.setState({ value });
    // if enrolment reader
    if (value === String(ENROLMENT_READER_VALUE)) {
      mutation({ variables: { id: gateway, roomId: null, gatewayType: GATEWAY_TYPES.ENROLMENT_READER } });
    } else if (value === String(DISCHARGE_GATEWAY_VALUE)) {
      mutation({ variables: { id: gateway, roomId: null, gatewayType: GATEWAY_TYPES.DISCHARGE_GATEWAY } });
    } else if (value === String(EXIT_GATEWAY_VALUE)) {
      mutation({ variables: { id: gateway, roomId: null, gatewayType: GATEWAY_TYPES.EXIT_GATEWAY } });
    } else if (room !== parseInt(value)) {
      mutation({ variables: { id: gateway, roomId: parseInt(value), gatewayType: GATEWAY_TYPES.GATEWAY } });
    }
  };

  render() {
    const { gateways, gateway, hospitalId } = this.props;

    const enrollmentAssigned = gateways.find(_ => _.type === 'ENROLMENT_READER' && _.id !== gateway);
    const enrolmentOption = !enrollmentAssigned
      ? { [ENROLMENT_READER_VALUE]: GATEWAY_TYPE_LABELS.ENROLMENT_READER }
      : {};

    const dischargeAssigned = gateways.find(_ => _.type === 'DISCHARGE_GATEWAY' && _.id !== gateway);
    const dischargeOption = !dischargeAssigned
      ? { [DISCHARGE_GATEWAY_VALUE]: GATEWAY_TYPE_LABELS.DISCHARGE_GATEWAY }
      : {};

    const exitAssigned = gateways.find(_ => _.type === 'EXIT_GATEWAY' && _.id !== gateway);
    const exitOption = !exitAssigned ? { [EXIT_GATEWAY_VALUE]: GATEWAY_TYPE_LABELS.EXIT_GATEWAY } : {};

    const additionalOptions = { ...enrolmentOption, ...dischargeOption, ...exitOption };

    return (
      <Mutation mutation={updateGateway}>
        {editGateway => (
          <InputWrapper>
            <GraphqlSelectInput
              disabled={this.props.disabled}
              entityName={'Room'}
              placeholder={'Room'}
              graphqlList={roomsForGateway}
              variables={{ hospitalId }}
              value={this.state.value}
              onChange={this.onChange(editGateway)}
              additionalOptions={additionalOptions}
            />
          </InputWrapper>
        )}
      </Mutation>
    );
  }
}

export default UpdateSelect;
