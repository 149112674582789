import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import MedicalPassportRow from '../../components/MedicalPassportRow';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  hepatic,
  infectious,
  musculoskeletal,
  neurological,
  oncologic,
  pain,
  psychiatric,
  pulmonary,
  renal,
  vascular,
} from '../paths';
import toAge from '../../../../../../questionnaire/toAge';
import toBMI from '../../../../../../questionnaire/toBMI';
import toWeight from '../../../../../../questionnaire/toWeight';
import toHeight from '../../../../../../questionnaire/toHeight';
import toSex from '../../../../../../questionnaire/toSex';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import toHighlightsString4 from '../../../../../../questionnaire/toHighlightsString4';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import getAgeAndBmi from './pageUtils';
import get from 'lodash/get';
import { useValue } from '../../ValueContext';

export const MedicalPassportCheckboxStyledWrapper = ({ children, borderLeft }) => (
  <div
    style={{
      borderLeft: borderLeft ? borderLeft : '1px solid black',
      padding: '7px 0 0 20px',
    }}
  >
    {children}
  </div>
);

const Page3 = ({ showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { age, bmi } = getAgeAndBmi(
    defaultValue?.demographicInformation?.dob,
    defaultValue?.demographicInformation?.bmi
  );
  const { questionnaireAnswers } = useValue();
  const drinking = get(
    questionnaireAnswers,
    'sections.Psychiatric.schema.psychiatric_list.checkboxes["Drink beer, wine or liquor"]',
    false
  );
  const drugs = get(
    questionnaireAnswers,
    'sections.Psychiatric.schema.psychiatric_list.checkboxes["History of using recreational or street drugs"]',
    false
  );

  const smoking = get(questionnaireAnswers, 'sections.basicscreening.schema["smoking status"].option[0]', '');
  const isSmoking = smoking === '' || smoking === 'N' ? false : true;

  return (
    <div id="page3" className="page" style={{ fontSize: '0.8em' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <h2>Patient Medical History</h2>
          <div>{hospitalName || ''}</div>
        </div>
        <div>
          {showQRCode && (
            <img
              alt="Barcoded value"
              src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
            />
          )}
        </div>
      </div>
      <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '55% 45%',
            borderBottom: '1px solid black',
          }}
        >
          <div>
            <div
              style={{
                border: '1px solid black',
                borderTop: 0,
                minHeight: '60px',
                height: '100%',
                padding: '0.1rem 0.2rem 0 0',
                borderBottom: '0px',
              }}
            >
              <div
                style={{
                  paddingBottom: '35px',
                }}
              >
                <MedicalPassportTextArea name="procedure.name" label="Procedure" />
              </div>
            </div>
          </div>
          <div>
            <div style={{ bottom: '5px', paddingLeft: '3px' }}>
              <div style={{ marginBottom: '0.2rem' }}>
                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" />
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                />
                <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="cardiologist"
                  label="Cardiologist"
                  path={['demographicInformation', 'cardiologist']}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="procedure.pcp"
                  path={['demographicInformation', 'primaryCareDoctor']}
                  label="PCP"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderLeft: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportTextArea
            label="Med Allergies/Sensitivities"
            name="allergies1"
            path={['allergies']}
            reducer={toHighlightsString}
            warning={true}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            borderLeft: '1px solid black',
            height: '30px',
          }}
        >
          <MedicalPassportCheckboxStyledWrapper
            borderLeft={'0'}
            children={
              <div>
                <input type="checkbox" checked={isSmoking} id="Smoking Hx" name="page3.smoking" />
                <label htmlFor="Smoking Hx">Smoking Hx</label>
              </div>
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <div>
                <input type="checkbox" id="etohCheckbox" checked={drinking} />
                <label htmlFor="etohCheckbox">ETOH Hx</label>
              </div>
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <div>
                <input type="checkbox" id="drugCheckbox" checked={drugs} />
                <label htmlFor="drugCheckbox">Drug Hx</label>
              </div>
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField name="ht" label="Ht" path={['demographicInformation', 'bmi']} reducer={toHeight} />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField name="wt" label="Wt" path={['demographicInformation', 'bmi']} reducer={toWeight} />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField
                name="bmi"
                label="BMI"
                path={['demographicInformation', 'bmi']}
                reducer={toBMI}
                warning={bmi !== '-' ? bmi > 40 : false}
              />
            }
          />
        </div>
        <div style={{ ...styles.medicationsInnerDiv }}>
          <MedicalPassportTextArea
            label="Surgical/Anesthesia Hx"
            name="anesthesia1"
            paths={[['priorSurgeries'], ['anesthesia complications']]}
            reducer={toHighlightsString4}
          />
        </div>
      </div>
      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>
          <div>Review of Systems</div>
        </div>
        <div
          style={{
            border: '1px solid black',
            lineHeight: '1.8em',
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <MedicalPassportRow
            label="Cardiovascular"
            name="systems.cardiovascular"
            paths={cardiovascular}
            style={{ borderTop: 0 }}
            index={0}
          />
          <MedicalPassportRow label="Pulmonary" name="systems.pulmonary" paths={pulmonary} index={1} />
          <MedicalPassportRow label="Genitourinary" name="systems.Genitourinary" paths={renal} index={2} />
          <MedicalPassportRow label="Hepatic" name="systems.hepatic" paths={hepatic} index={3} />
          <MedicalPassportRow label="Neurological" name="systems.neurological" paths={neurological} index={4} />
          <MedicalPassportRow
            label="Gastrointestinal"
            name="systems.gastrointestinal"
            paths={gastrointestinal}
            index={5}
          />
          <MedicalPassportRow label="Vascular" name="systems.vascular" paths={vascular} index={6} />
          <MedicalPassportRow label="Endocrine/Metabolic" name="systems.endocrine2" paths={endocrine} index={7} />
          <MedicalPassportRow
            label="Musculoskeletal"
            name="systems.musculoskeletal"
            paths={musculoskeletal}
            index={8}
          />
          <MedicalPassportRow label="Psychiatric" name="systems.psychiatric" paths={psychiatric} index={9} />
          <MedicalPassportRow label="Oncology" name="systems.ontological" paths={oncologic} index={10} />
          <MedicalPassportRow label="Infectious Disease" name="systems.infDis" paths={infectious} index={11} />
          <MedicalPassportRow label="Other" name="systems.other" paths={pain} index={12} />
        </div>
      </section>
    </div>
  );
};

export default Page3;
