import React, { FormEvent, Ref, useEffect, useRef, useState } from 'react';
import config from '../../../../../config';
import { Button, Typography, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import { gql, useMutation } from '@apollo/client';

interface QuestionnaireFormProps {
  type: 'PreOp' | 'InTake';
  exchangeId: number;
  accessToken: string;
  dateOfBirth?: string;
  hasPhoneNumber?: boolean;
  onDone: () => void;
  onStarted: () => void;
  onStopped: () => void;
}

const QuestionnaireForm = ({
  type,
  exchangeId,
  accessToken,
  dateOfBirth,
  hasPhoneNumber,
  onDone,
  onStarted,
  onStopped,
}: QuestionnaireFormProps) => {
  const [started, setStarted] = useState(false);

  const [requestInvitation, { loading: busy }] = useMutation(
    gql`
      mutation requestInvitation($exchangeId: Long!) {
        requestInvitation(questionnaireExchangeId: $exchangeId)
      }
    `,
    {
      variables: { exchangeId },
    }
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setStarted(true);
  };

  const handleInvite = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      await requestInvitation();
      onDone();
    } catch (e) {
      console.error(e);
    }
  };

  const handleReset = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onDone();
  };

  useEffect(() => {
    if (!started) {
      return;
    }

    onStarted();

    return () => onStopped();
  }, [started]);

  return started ? (
    <Form exchangeId={exchangeId} accessToken={accessToken} dateOfBirth={dateOfBirth} onClose={onDone} />
  ) : (
    <Box textAlign="center" display="flex" flexDirection="column" justifyContent="middle" maxWidth="62ch">
      <Typography variant="h5" gutterBottom>
        Incomplete {type === 'InTake' ? 'Registration Package' : 'PreOp Questionnaire'}
      </Typography>
      <Typography gutterBottom>Please fill it out.</Typography>
      <Box
        flexDirection="column"
        component="form"
        onSubmit={handleSubmit}
        onReset={handleReset}
        display="flex"
        justifyContent="center"
        textAlign="left"
        mt={2}
      >
        <Box mt={type === 'InTake' ? 8 : 2} display="flex" flexDirection="column">
          <Button size="large" type="submit" variant="contained" color="primary">
            Open {type === 'InTake' ? 'Registration Package' : 'PreOp Questionnaire'}
          </Button>
        </Box>
        {hasPhoneNumber && (
          <Box mt={2} display="flex" flexDirection="column">
            <Button size="large" type="button" onClick={handleInvite} variant="text" color="secondary">
              Send Link via SMS
            </Button>
          </Box>
        )}
        <Box display="flex" flexDirection="column">
          <Button size="large" type="reset" variant="text" color="secondary">
            Skip
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

interface FormProps {
  exchangeId: number;
  accessToken: string;
  dateOfBirth?: string;
  onClose: () => void;
}

const Form = ({ exchangeId, accessToken, dateOfBirth, onClose }: FormProps) => {
  const theme = useTheme();
  const rootRef = useRef<HTMLDivElement>();
  const [size, setSize] = useState<{ width: number; height: number } | undefined>(undefined);

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
    });

    resizeObserver.observe(rootRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={rootRef as Ref<HTMLDivElement>}
      style={{
        position: 'relative',
        flex: 1,
        alignSelf: 'stretch',
        borderRadius: theme.spacing(2),
        background: theme.palette.grey[100],
      }}
    >
      <Fab
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 0,
          right: -theme.spacing(9),
          zIndex: 20,
        }}
      >
        <CloseIcon />
      </Fab>
      {size && (
        <>
          <div style={{ marginBottom: 890 }}>
            <Frame
              dateOfBirth={dateOfBirth}
              src={`${config.careURL}/questionnaire/${accessToken}?hideUploads=true`}
              width={size!.width}
              height={size!.height}
              onComplete={onClose}
            />
          </div>
        </>
      )}
    </div>
  );
};

interface FrameProps {
  dateOfBirth?: string;
  src: string;
  width: number;
  height: number;
  onComplete: () => void;
}

const Frame = ({ dateOfBirth, src, width, height, onComplete }: FrameProps) => {
  const iframeRef = useRef<HTMLIFrameElement>();

  useEffect(() => {
    const iframe = iframeRef.current;

    if (!iframe) {
      return;
    }

    const handleMessage = (message: MessageEvent) => {
      if (message.source !== iframe.contentWindow) {
        return;
      }

      if (message.data?.event === 'Complete') {
        onComplete();
      }

      if (message.data?.request === 'IsWaitingRoomTablet') {
        iframe.contentWindow!.postMessage({ requestId: message.data?.requestId, response: true }, '*');
      }

      if (message.data?.request === 'DateOfBirth') {
        iframe.contentWindow!.postMessage({ requestId: message.data?.requestId, response: dateOfBirth }, '*');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <iframe
      ref={iframeRef as Ref<HTMLIFrameElement>}
      src={src}
      width={width}
      height={height}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    />
  );
};

export default QuestionnaireForm;
