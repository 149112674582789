import React from 'react';
import styled from 'styled-components';

const Sheet = styled.div`
  position: absolute;
  height: 4em;

  border-radius: 4px;
  background: #1e2855;

  // z-index: ${props => 100 - props.order};
  z-index: 1;

  width: ${props => 100 - props.order * 2}%;

  bottom: ${props => 0.4375 * props.order}em;

  border: 1px solid #121d4d;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Stack = styled.div`
  position: relative;
  width: 100%;

  margin-bottom: 0.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  height: ${props => (props.visible ? '5em' : 0)};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 100ms ease;
`;

const Label = styled.label`
  color: #abe3ff;
  font-size: 1.2em;
  z-index: 101;
`;

const Condenser = ({ count, isTop }) => (
  <Stack visible={count > 0}>
    {[...Array(Math.min(count, 1))].map((_, i) => (
      <Sheet key={i} order={i}>
        {i === 0 && <Label>{`${count} ${isTop ? 'Previous' : 'Upcoming'} Procedure${count > 1 ? 's' : ''}`}</Label>}
      </Sheet>
    ))}
  </Stack>
);

export default Condenser;
