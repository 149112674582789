import { red } from '@material-ui/core/colors';
import { Chip, SvgIcon } from '@material-ui/core';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface AllergyWarningProps {
  allergies: string;
}

function AllergyIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill={props.style.color || '#000'}
          d="M19.75 5.33a1.25 1.25 0 0 0-1.25 1.25v4.59h-.83V4.08a1.25 1.25 0 0 0-2.5 0v7.09h-.84V3.25a1.25 1.25 0 1 0-2.5 0v7.92H11V5.33a1.25 1.25 0 0 0-2.5 0v9.93l-3.59-2a1 1 0 0 0-.5-.14a1 1 0 0 0-.66.25l-1.08 1L9.21 21a3.29 3.29 0 0 0 2.37 1h6.09A3.33 3.33 0 0 0 21 18.67V6.58a1.25 1.25 0 0 0-1.25-1.25M11 15a1 1 0 1 1 1-1a1 1 0 0 1-1 1m2 3a1 1 0 1 1 1-1a1 1 0 0 1-1 1m5-2a1 1 0 1 1 1-1a1 1 0 0 1-1 1m-1 3a1 1 0 1 1 1-1a1 1 0 0 1-1 1m-2-4a1 1 0 1 1 1-1a1 1 0 0 1-1 1Z"
        />
      </svg>
    </SvgIcon>
  );
}

const AllergyWarning = ({ allergies }: AllergyWarningProps) => {
  const classes = useStyles();

  return (
    <Chip
      icon={<AllergyIcon style={{ color: red[600] }} />}
      classes={{
        root: classes.chipAllergies,
      }}
      size="small"
      label={allergies}
    />
  );
};

const useStyles = makeStyles(theme => ({
  chipAllergies: {
    backgroundColor: red[100],
    color: red[900],
    minWidth: 0,
  },
}));

export default AllergyWarning;
