import React from 'react';
import styles from '../styles';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import getAgeAndBmi from './pageUtils';
import MedicalPassportRow from '../components/MedicalPassportRow';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  hepatic,
  infectious,
  musculoskeletal,
  neurological,
  oncologic,
  pain,
  psychiatric,
  pulmonary,
  renal,
  vascular,
} from './paths';
import toAge from '../../../../../questionnaire/toAge';
import toSex from '../../../../../questionnaire/toSex';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import get from 'lodash/get';
import { useValue } from '../ValueContext';

export const MedicalPassportCheckboxStyledWrapper = ({ children, borderLeft }) => (
  <div
    style={{
      borderLeft: borderLeft ? borderLeft : '1px solid black',
      padding: '7px 0 0 20px',
    }}
  >
    {children}
  </div>
);
const CommonSubPartOneOriginalVariant = () => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div>Anesthetic Plan:</div>
    <div style={{ lineHeight: '1.6em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '6px' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.ga" label="GA" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.mac" label="MAC" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.moderateSedation" label="Moderate Sedation" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.regionalBlock" label="Regional Block" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.sab" label="SAB" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.pnb" label="PNB" />
        <MedicalPassportCheckbox name="page4.assessment2.other" />
        <MedicalPassportField name="page4.assessment2.otherText" label="Other" />
      </div>
    </div>
  </div>
);

const CommonSubPartOneAlteredVariant = () => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div>Anesthetic Plan:</div>
    <div style={{ lineHeight: '1.6em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '6px' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.ga" label="GA" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.mac" label="MAC" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.sab" label="SAB" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.pnb" label="PNB" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.moderateSedation" label="Moderate Sedation" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.regionalBlock" label="Regional Block" />
        <MedicalPassportCheckbox name="page4.assessment2.other" />
        <MedicalPassportField name="page4.assessment2.otherText" label="Other" />
      </div>
    </div>
  </div>
);

const CommonPartOne = ({ variant }) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    }}
  >
    <div style={{ border: '1px solid black', padding: '.5em', borderRight: 0 }}>
      <div style={{ display: 'flex', gap: '40px' }}>
        <div>ASA Class:</div>
        <MedicalPassportCheckbox label="1" name="page4.asaClass1" />
        <MedicalPassportCheckbox label="2" name="page4.asaClass2" />
        <MedicalPassportCheckbox label="3" name="page4.asaClass3" />
        <MedicalPassportCheckbox label="4" name="page4.asaClass4" />
      </div>
      <div style={{ lineHeight: '1.6em' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
          <div>NPO Status Verified?</div>
          <div>
            <MedicalPassportCheckbox name="page4.NPOStatusVerifiedYes" label="Yes" />
          </div>
          <div>
            <MedicalPassportCheckbox name="page4.NPOStatusVerifiedNo" label="No" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
          <div>Pt. Reassessed Day of Surgery?</div>
          <div>
            <MedicalPassportCheckbox name="page4.reassuredDayOfSurgeryYes" label="Yes" />
          </div>
          <div>
            <MedicalPassportCheckbox name="page4.reassuredDayOfSurgeryNo" label="No" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 2fr' }}>
          <div>Postoperative Plan:</div>
          <div>
            <MedicalPassportCheckbox name="page4.pacu" label="PACU/Home" />
          </div>
        </div>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid black',
        padding: '.25em',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Assessment:</div>
        <div style={{ lineHeight: '1.6em' }}>
          <MedicalPassportCheckbox name="page4.noContradictionAnesthesia" label="No contradiction to anesthesia" />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MedicalPassportCheckbox name="page4.assessment1.other" />
            <MedicalPassportField name="page4.assessment1.otherText" label="Other" />
          </div>
        </div>
      </div>
      {variant === 'original' ? <CommonSubPartOneOriginalVariant /> : <CommonSubPartOneAlteredVariant />}
    </div>
  </div>
);

const Page4 = ({ showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { age, bmi } = getAgeAndBmi(
    defaultValue?.demographicInformation?.dob,
    defaultValue?.demographicInformation?.bmi
  );
  const { questionnaireAnswers } = useValue();
  const drinking = get(
    questionnaireAnswers,
    'sections.Psychiatric.schema.psychiatric_list.checkboxes["Drink beer, wine or liquor"]',
    false
  );
  const drugs = get(
    questionnaireAnswers,
    'sections.Psychiatric.schema.psychiatric_list.checkboxes["History of using recreational or street drugs"]',
    false
  );
  const smoking = get(questionnaireAnswers, 'sections.basicscreening.schema["smoking status"].option[0]', '');
  const isSmoking = smoking === '' || smoking === 'N' ? false : true;

  return (
    <div id="page4" className="page" style={{ fontSize: '0.8rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <h3>{hospitalName || ''}</h3>
          <h3>ANESTHESIA ASSESSMENT</h3>
        </div>
        <div>
          {showQRCode && (
            <img
              alt="Barcoded value"
              src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
            />
          )}
        </div>
      </div>
      <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '55% 45%',
            borderBottom: '1px solid black',
          }}
        >
          <div>
            <div
              style={{
                border: '1px solid black',
                borderTop: 0,
                minHeight: '60px',
                height: '100%',
                padding: '0.1rem 0.2rem 0 0',
                borderBottom: '0px',
              }}
            >
              <div
                style={{
                  paddingBottom: '35px',
                }}
              >
                <MedicalPassportTextArea name="procedure.name" label="Procedure" fontSize={14} />
              </div>
              <div
                style={{
                  border: '1px solid black',
                  padding: '0.2rem 0.1rem 0 0',
                  borderBottom: 0,
                  borderRight: 0,
                  borderLeft: 0,
                }}
              >
                <MedicalPassportField
                  name="page4.activityLevel"
                  label="Activity Level"
                  path={['basicscreening', 'level of physical activity']}
                  reducer={toHighlightsString}
                  fontSize={14}
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ bottom: '5px', paddingLeft: '3px' }}>
              <div style={{ marginBottom: '0.2rem' }}>
                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={14} />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={14} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={14} />
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={14} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                  fontSize={14}
                />
                <MedicalPassportField
                  name="sex"
                  label="Sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toSex}
                  fontSize={14}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="cardiologist"
                  label="Cardiologist"
                  path={['demographicInformation', 'cardiologist']}
                  fontSize={14}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="procedure.pcp"
                  path={['demographicInformation', 'primaryCareDoctor']}
                  label="PCP"
                  fontSize={14}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderLeft: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportTextArea
            label="Med Allergies/Sensitivities"
            name="allergies1"
            path={['allergies']}
            reducer={toHighlightsString}
            warning={true}
            fontSize={14}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            borderLeft: '1px solid black',
            height: '30px',
          }}
        >
          <MedicalPassportCheckboxStyledWrapper
            borderLeft={'0'}
            children={
              <div>
                <input type="checkbox" checked={isSmoking} id="Smoking Hx" name="page3.smoking" />
                <label htmlFor="Smoking Hx">Smoking Hx</label>
              </div>
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <div>
                <input type="checkbox" id="etohCheckbox" checked={drinking} />
                <label htmlFor="etohCheckbox">ETOH Hx</label>
              </div>
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <div>
                <input type="checkbox" id="drugCheckbox" checked={drugs} />
                <label htmlFor="drugCheckbox">Drug Hx</label>
              </div>
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField
                name="ht"
                label="Ht"
                path={['demographicInformation', 'bmi']}
                reducer={toHeight}
                fontSize={14}
              />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField
                name="wt"
                label="Wt"
                path={['demographicInformation', 'bmi']}
                reducer={toWeight}
                fontSize={14}
              />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField
                name="bmi"
                label="BMI"
                path={['demographicInformation', 'bmi']}
                reducer={toBMI}
                warning={bmi !== '-' ? bmi > 40 : false}
                fontSize={14}
              />
            }
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <div style={styles.medicationsInnerDiv}>
            <MedicalPassportTextArea
              label="Current Medications"
              name="medications1"
              path={['medications']}
              reducer={toHighlightsString}
              fontSize={14}
            />
          </div>
          <div style={{ ...styles.medicationsInnerDiv }}>
            <MedicalPassportTextArea
              label="Surgical/Anesthesia Hx"
              name="anesthesia1"
              path={['priorSurgeries']}
              reducer={toHighlightsString}
              fontSize={14}
            />
          </div>
        </div>
      </div>
      <div style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
        <MedicalPassportTextArea
          label={'Hx of Anesthesia Complications'}
          name="page4.hxAnesthesiaComplications"
          path={['anesthesia complications']}
          fontSize={14}
        />
      </div>

      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>
          <div>Review of Systems</div>
        </div>
        <div
          style={{
            border: '1px solid black',
            lineHeight: '1.8em',
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <MedicalPassportRow
            label="Cardiovascular"
            name="systems.cardiovascular"
            paths={cardiovascular}
            style={{ borderTop: 0 }}
            index={0}
            fontSize={14}
          />
          <MedicalPassportRow label="Pulmonary" name="systems.pulmonary" paths={pulmonary} index={1} fontSize={14} />
          <MedicalPassportRow
            label="Genitourinary"
            name="systems.genitourinary"
            paths={renal}
            index={2}
            fontSize={14}
          />
          <MedicalPassportRow label="Hepatic" name="systems.hepatic" paths={hepatic} index={3} fontSize={14} />
          <MedicalPassportRow
            label="Neurological"
            name="systems.neurological"
            paths={neurological}
            index={4}
            fontSize={14}
          />
          <MedicalPassportRow
            label="Gastrointestinal"
            name="systems.gastrointestinal"
            paths={gastrointestinal}
            index={5}
            fontSize={14}
          />
          <MedicalPassportRow label="Vascular" name="systems.vascular" paths={vascular} index={6} fontSize={14} />
          <MedicalPassportRow
            label="Endocrine/Metabolic"
            name="systems.endocrine2"
            paths={endocrine}
            index={7}
            fontSize={14}
          />
          <MedicalPassportRow
            label="Musculoskeletal"
            name="systems.musculoskeletal"
            paths={musculoskeletal}
            index={8}
            fontSize={14}
          />
          <MedicalPassportRow
            label="Psychiatric"
            name="systems.psychiatric"
            paths={psychiatric}
            index={9}
            fontSize={14}
          />
          <MedicalPassportRow label="Oncology" name="systems.ontological" paths={oncologic} index={10} fontSize={14} />
          <MedicalPassportRow
            label="Infectious Disease"
            name="systems.infDis"
            paths={infectious}
            index={11}
            fontSize={14}
          />
          <MedicalPassportRow label="Other" name="systems.other" paths={pain} index={12} fontSize={14} />
        </div>
      </section>
      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>Physical Exam/Labs/Anesthesia Plan</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <div style={{ paddingLeft: '.25em', paddingTop: '.25em' }}>Vital Signs</div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, 1fr)',
            }}
          >
            <MedicalPassportField name="page4.temp" label="Temp" />
            <MedicalPassportField name="page4.bp" label="BP" />
            <MedicalPassportField name="page4.pulse" label="Pulse" />
            <MedicalPassportField name="page4.resp" label="Resp" />
            <MedicalPassportField name="page4.o2Sat" label="O2Sat" />
            <MedicalPassportField name="page4.giu" label="GLU" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6,1fr)', height: '80px' }}>
          <div style={{ border: '1px solid black', borderRight: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}>
              <div>General</div>
              <MedicalPassportCheckbox
                name="page4.generalWnl"
                label="WNL"
                style={{ display: 'flex', alignItems: 'center' }}
              />
            </div>
          </div>
          <div style={{ border: '1px solid black', borderRight: 0 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '15px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}>
                <div>Heart</div>
                <MedicalPassportCheckbox
                  name="page4.heartWnl"
                  label="WNL"
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MedicalPassportCheckbox
                  name="page4.heartRegularRateRhythm"
                  label="Regular Rate + Rhythm"
                  style={{ display: 'flex' }}
                />
              </div>
            </div>
          </div>
          <div style={{ border: '1px solid black', borderRight: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', padding: '.25em' }}>
              <div>Lungs</div>
              <div>
                <MedicalPassportCheckbox
                  name="page4.lungsWnl"
                  label="WNL"
                  style={{ display: 'flex', alignItems: 'center' }}
                />
                <MedicalPassportCheckbox
                  name="page4.lungsCta"
                  label="CTA"
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </div>
            </div>
          </div>
          <div style={{ border: '1px solid black', borderRight: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}>
              <div>Teeth</div>
              <MedicalPassportCheckbox
                name="page4.teethWnl"
                label="WNL"
                style={{ display: 'flex', alignItems: 'center' }}
              />
            </div>
          </div>
          <div style={{ height: '67px', border: '1px solid black', padding: '.4em .25em', paddingBottom: '9px' }}>
            Airway Assessment:
          </div>
          <div
            style={{
              border: '1px solid black',
              borderLeft: 0,
              padding: '.25em',
            }}
          >
            <div>Other</div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3,1fr)',
            height: '30px',
            border: '1px solid black',
            borderBottom: 0,
            borderTop: 0,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 .25em' }}>
            <div>Labs reviewed</div>
            <MedicalPassportCheckbox
              name="page4.labYes"
              label="Yes"
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <MedicalPassportCheckbox name="page4.labNo" label="N/A" style={{ display: 'flex', alignItems: 'center' }} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 .25em' }}>
            <div>EKG/ECHO reviewed</div>
            <MedicalPassportCheckbox
              name="page4.ekgYes"
              label="Yes"
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <MedicalPassportCheckbox name="page4.ekgNo" label="N/A" style={{ display: 'flex', alignItems: 'center' }} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 .25em' }}>
            <div>HCG</div>
            <MedicalPassportCheckbox
              name="page4.pregnantYes"
              label="Neg"
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <MedicalPassportCheckbox
              name="page4.pregnantNo"
              label="N/A"
              style={{ display: 'flex', alignItems: 'center' }}
            />
          </div>
        </div>

        <CommonPartOne variant={'altered'} />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <div style={{ textAlign: 'center' }}>
            <div>________________________________</div>
            <div>Anesthesia</div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div>________________________________</div>
            <div>Date/Time</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page4;
