export enum ProcedureStepType {
  Admitted = 'ADMITTED',
  WrIn = 'WR_IN',
  WrOut = 'WR_OUT',
  PreOpIn = 'PRE_OP_IN',
  BlockNerve = 'BLOCK_NERVE',
  PreOpOut = 'PRE_OP_OUT',
  OrIn = 'OR_IN',
  AnestheticStart = 'ANESTHETIC_START',
  TimeOut = 'TIME_OUT',
  ReadyForSurgery = 'READY_FOR_SURGERY',
  OperationStart = 'OPERATION_START',
  CallNextPatient = 'CALL_NEXT_PATIENT',
  Closing = 'CLOSING',
  SurgeonLeftOR = 'SURGEON_LEFT_OR',
  DressingOn = 'DRESSING_ON',
  OperationEnd = 'OPERATION_END',
  AnestheticEnd = 'ANESTHETIC_END',
  OrOut = 'OR_OUT',
  CleaningCompleted = 'CLEANING_COMPLETED',
  PacuIn = 'PACU_IN',
  PacuReadyToLeave = 'PACU_READY_TO_LEAVE',
  PacuOut = 'PACU_OUT',
  PostOpIn = 'POSTOP_IN',
  PostOpOut = 'POSTOP_OUT',
  Discharged = 'DISCHARGED',
}

export interface ProcedureStep {
  id: number;
  name: string;
  type: ProcedureStepType;
  order: number;
  timestamp?: string;
}
