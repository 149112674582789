import { gql } from '@apollo/client';

import { fragments as procedureTypeFragments } from './procedureTypes';
import { fragments as physicianFragments } from './physicians';
import { fragments as anesthesiologistFragments } from './anesthesiologists';

const procedureBaseFields = `
  id
  status
  startTime
  startTimeText
  endTimeText
  duration
  orId
  or
  isCanceled
  physicianId
  physicianHstId
  physicianName
  physicianNameHST
  physicianColor
  patientId
  patientHstId
  patientName
  patientNameUnformatted
  patientInitials
  patientDateOfBirth
  patientDateOfBirthISO
  patientAge
  patientSex
  patientHomePhone
  patientCellPhone
  caretakerCellPhone
  familyCellPhone
  patientEmailAddress
  procedureTypeId
  procedureType
  procedureMod
  diagnosis
  equipment
  cptCodes
  bilaterally
  visitType
  room
  insuranceName
  policyName
  anesthesiologistId
  anesthesiologistHstId
  anesthesiologistName
  anesthesiaType
  allergy
  notes
  visit
  preferredLanguage
`;

export const fragments = {
  all: gql`
    fragment PatientData on Patient {
      id
      hstId
      name
      initials
      braceletId
      type
      notificationNumbers
      procedureType {
        ...ProcedureTypeData
      }
      physician {
        ...PhysicianData
      }
      room {
        id
        name
        type
      }
      status
      phoneNumber
      bed
      operationRoom {
        id
        name
      }
      preOpBed
      pacuBed
      priority
      icon
      color
      createdAt
      changedRoomAt
      dischargedAt
      voidedAt
      note
      caretakerConsent
      caretakerMessages {
        phoneNumber
        content
      }
      caretakerThread {
        id
        hasUnreadMessages
      }
      patientThread {
        id
        hasUnreadMessages
      }
      preOpNote
      pacuNote
      blockNerveFollow
    }
    ${procedureTypeFragments.all}
    ${physicianFragments.all}
  `,
  patientEvents: gql`
    fragment PatientEvents on Patient {
      events {
        admittedAt
        wrAt
        wrOutAt
        preOpAt
        blockNerveAt
        preOpOutAt
        orAt
        orOutAt
        cleaningCompletedAt
        recoveryAt
        recoveryOutAt
        postOpAt
        postOpOutAt
        dpuAt
        ableToVisitPACUAt
        ableToVisitPOSTOPAt
        dischargedAt
        readyForSurgeonAt
        readyForOrAt
        holdProcedureAt
        anestheticStartAt
        timeOutAt
        readyForSurgeryAt
        procedureStartAt
        calledNextPatientAt
        closingStartAt
        surgeonLeftAt
        procedureEndAt
        anestheticEndAt
        helpAt
        dressingOnAt
      }
    }
  `,
  roomTimes: gql`
    fragment RoomTimes on Patient {
      waitingRoomTime
      prepTime
      orTime
      pacuTime
      postopTime
    }
  `,
  patientLog: gql`
    fragment PatientLog on Patient {
      log {
        id
        entries {
          type
          room {
            id
            name
            type
          }
          manualEntryBy {
            id
            name
            superRole
          }
          manualTimestamp
          status
          delivery
          value
          performedBy {
            id
            name
            superRole
          }
        }
        timestamp
        createdBy {
          id
          name
          superRole
        }
        createdAt
      }
    }
  `,
};

export const list = gql`
  query patients {
    patients {
      ...PatientData
      ...PatientLog
    }
  }
  ${fragments.all}
  ${fragments.patientLog}
`;

export const nextPatientMRN = gql`
  query nextPatientMRN {
    nextPatientMRN
  }
`;

export const patientSubscription = gql`
  subscription patients {
    patients {
      ...PatientData
      ...PatientLog
    }
  }
  ${fragments.all}
  ${fragments.patientLog}
`;

export const patientPickerSubscription = gql`
  subscription patients {
    patients {
      ...PatientData
      ...PatientLog
      procedure {
        id
        patientSex
        patientAge
      }
    }
  }
  ${fragments.all}
  ${fragments.patientLog}
`;

export const listActiveAndPastPatients = gql`
  subscription patients($filter: Filter) {
    patients: activeAndPastPatients(filter: $filter) {
      procedure {
        startTime
        duration
        patientAge
        patientSex
      }
      dischargedByDisappearance
      ...PatientData
      ...PatientEvents
    }
  }
  ${fragments.all}
  ${fragments.patientEvents}
`;

export const listPacuPrepPostopPatients = gql`
  subscription {
    patients: listPacuPrepPostopPatients {
      ...PatientData
      anesthesiologist {
        ...AnesthesiologistData
      }
      log {
        id
        entries {
          type
          room {
            id
            name
            type
          }
          manualEntryBy {
            id
            name
          }
          status
        }
        timestamp
      }
    }
  }
  ${fragments.all}
  ${anesthesiologistFragments.all}
`;

const ChartingData = `
  id
  questionnaire {
    id
    name
    icon
    type
    content
    order
  }
  questionnaireExchange {
    id
    procedureId
    answers
    submittedAt
  }
  completed
  defaultAnswers
`;

const ChartingsData = `
  preOpCharts {
    ${ChartingData}
  }
  orCharts {
    ${ChartingData}
  }
  pacuCharts {
    ${ChartingData}
  }
`;

export const subscription = gql`
  subscription patient($id: Long!) {
    patient(id: $id) {
      ...PatientData
      ...RoomTimes
      ...PatientLog
      procedure {
        rideHomeCellPhone
        ${procedureBaseFields}
        entryQuestionnaire {
          id
          questionnaireVersion
          progress
          questions
          answers
          progress
          submittedAt
        }
        ${ChartingsData}
        questionnaireInviteSMSCount
        questionnaireInviteEmailCount
        events {
          id
          type
          timestamp
          patientPin
          channel
          content
          user {
            id
            name
          }
          text
          status
          anesthesiaReviewed
          exchangeId
        }
      }
      orRoomTimes {
        enteredAt
        exitedAt
      }
    }
  }
  ${fragments.all}
  ${fragments.roomTimes}
  ${fragments.patientLog}
`;

export const item = gql`
  query patient($id: Long!) {
    patient(id: $id) {
      ...PatientData
      ...RoomTimes
      ...PatientLog
      procedure {
        id
      }
    }
  }
  ${fragments.all}
  ${fragments.roomTimes}
  ${fragments.patientLog}
`;

export const pastItem = gql`
  query patient($id: Long!) {
    pastPatient(id: $id) {
      ...PatientData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createPatient(
    $name: String
    $procedureId: Long
    $braceletId: String!
    $type: PatientType
    $notificationNumbers: String
    $phoneNumber: String
    $procedureType: Long
    $physician: Long!
    $autoBracelet: Boolean
    $note: String
    $caretakerConsent: Boolean
  ) {
    createPatient(
      name: $name
      procedureId: $procedureId
      braceletId: $braceletId
      type: $type
      notificationNumbers: $notificationNumbers
      phoneNumber: $phoneNumber
      procedureType: $procedureType
      physician: $physician
      autoBracelet: $autoBracelet
      note: $note
      caretakerConsent: $caretakerConsent
    ) {
      ...PatientData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editPatient(
    $id: Long!
    $name: String
    $braceletId: String
    $type: PatientType
    $notificationNumbers: String
    $phoneNumber: String
    $procedureType: Long
    $physician: Long
    $roomId: Long
    $icon: String
    $color: String
    $note: String
    $caretakerConsent: Boolean
  ) {
    editPatient(
      id: $id
      name: $name
      braceletId: $braceletId
      type: $type
      notificationNumbers: $notificationNumbers
      phoneNumber: $phoneNumber
      procedureType: $procedureType
      physician: $physician
      manualRoomId: $roomId
      icon: $icon
      color: $color
      note: $note
      caretakerConsent: $caretakerConsent
    ) {
      ...PatientData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deletePatient($id: Long!) {
    deletePatient(id: $id)
  }
`;

export const assignBed = gql`
  mutation assignBed($id: Long!, $bed: String) {
    assignBed(id: $id, bed: $bed)
  }
`;

export const editPreOpNote = gql`
  mutation editPreOpNote($id: Long!, $note: String) {
    editPreOpNote(id: $id, note: $note)
  }
`;

export const editPacuNote = gql`
  mutation editPacuNote($id: Long!, $note: String) {
    editPacuNote(id: $id, note: $note)
  }
`;

export const editBlockNerveFollow = gql`
  mutation editBlockNerveFollow($id: Long!, $blockFollow: Boolean) {
    editBlockNerveFollow(id: $id, blockFollow: $blockFollow)
  }
`;

export const assignPriority = gql`
  mutation assignPriority($id: Long!, $priority: String) {
    assignPriority(id: $id, priority: $priority)
  }
`;

export const setReady = gql`
  mutation setPatientReady($id: Long!) {
    setPatientReady(patientId: $id)
  }
`;

export const setFamilyReadyPACU = gql`
  mutation setFamilyReadyPACU($id: Long!) {
    setFamilyReadyPACU(patientId: $id)
  }
`;

export const setFamilyReadyPOSTOP = gql`
  mutation setFamilyReadyPOSTOP($id: Long!) {
    setFamilyReadyPOSTOP(patientId: $id)
  }
`;

export const setFamilyReadyForPickupPACU = gql`
  mutation setFamilyReadyForPickupPACU($id: Long!) {
    setFamilyReadyForPickupPACU(patientId: $id)
  }
`;

export const setFamilyReadyForPickupPOSTOP = gql`
  mutation setFamilyReadyForPickupPOSTOP($id: Long!) {
    setFamilyReadyForPickupPOSTOP(patientId: $id)
  }
`;

export const setFamilyReadyInPOSTOP = gql`
  mutation setFamilyReadyPOSTOP($id: Long!) {
    setFamilyReadyPOSTOP(patientId: $id)
  }
`;

export const setPatientReadyInPREP = gql`
  mutation setPatientReadyInPREP($id: Long!) {
    setPatientReadyInPREP(patientId: $id)
  }
`;

export const setPatientReadyInPACU = gql`
  mutation setPatientReadyInPACU($id: Long!) {
    setPatientReadyInPACU(patientId: $id)
  }
`;

export const setPatientReadyInPOSTOP = gql`
  mutation setPatientReadyInPOSTOP($id: Long!) {
    setPatientReadyInPOSTOP(patientId: $id)
  }
`;

export const setPatientNotReadyInPREP = gql`
  mutation setPatientNotReadyInPREP($id: Long!) {
    setPatientNotReadyInPREP(patientId: $id)
  }
`;

export const setPatientNotReadyInPACU = gql`
  mutation setPatientNotReadyInPACU($id: Long!) {
    setPatientNotReadyInPACU(patientId: $id)
  }
`;

export const setPatientNotReadyInPOSTOP = gql`
  mutation setPatientNotReadyInPOSTOP($id: Long!) {
    setPatientNotReadyInPOSTOP(patientId: $id)
  }
`;

export const setPatientReadyForSurgeon = gql`
  mutation setPatientReadyForSurgeon($id: Long!) {
    setPatientReadyForSurgeon(patientId: $id)
  }
`;

export const setPatientNotReadyForSurgeon = gql`
  mutation setPatientNotReadyForSurgeon($id: Long!) {
    setPatientNotReadyForSurgeon(patientId: $id)
  }
`;

export const notifyReadyForSurgeon = gql`
  mutation notifyReadyForSurgeon($id: Long!) {
    notifyReadyForSurgeon(patientId: $id)
  }
`;

export const notifyReadyForAnesthesiologist = gql`
  mutation notifyReadyForAnesthesiologist($id: Long!) {
    notifyReadyForAnesthesiologist(patientId: $id)
  }
`;

export const setRoom = gql`
  mutation setRoomForPatient($id: Long!, $roomId: Long!) {
    setRoomForPatient(patientId: $id, roomId: $roomId)
  }
`;

export const resetProcedure = gql`
  mutation resetProcedure($id: Long!) {
    resetProcedure(id: $id)
  }
`;

export const startAdditionalTimeout = gql`
  mutation startAdditionalTimeout($id: Long!) {
    startAdditionalTimeout(id: $id)
  }
`;

export const exitProcedure = gql`
  mutation exitProcedure($id: Long!) {
    exitProcedure(id: $id)
  }
`;

export const discharge = gql`
  mutation discharge($id: Long!) {
    discharge(id: $id)
  }
`;

export const resendFamilyPageSms = gql`
  mutation resendFamilyPageSms($patientId: Long!) {
    resendFamilyPageSms(patientId: $patientId)
  }
`;

export const cancelVisit = gql`
  mutation cancelVisit($id: Long!, $reason: String) {
    cancelVisit(id: $id, reason: $reason)
  }
`;

export const setReadyToSeeFamilyPreOp = gql`
  mutation setReadyToSeeFamilyPreOp($id: Long!) {
    setReadyToSeeFamilyPreOp(patientId: $id)
  }
`;

export const setNotReadyToSeeFamilyPreOp = gql`
  mutation setNotReadyToSeeFamilyPreOp($id: Long!) {
    setNotReadyToSeeFamilyPreOp(patientId: $id)
  }
`;

export const setHoldProcedure = gql`
  mutation setHoldProcedure($id: Long!) {
    setHoldProcedure(patientId: $id)
  }
`;

export const setUndoHoldProcedure = gql`
  mutation setUndoHoldProcedure($id: Long!) {
    setUndoHoldProcedure(patientId: $id)
  }
`;

export const setBlockNerve = gql`
  mutation setBlockNerve($id: Long!) {
    setBlockNerve(patientId: $id)
  }
`;

export const setUndoBlockNerve = gql`
  mutation setUndoBlockNerve($id: Long!) {
    setUndoBlockNerve(patientId: $id)
  }
`;

export const setHelp = gql`
  mutation setHelp($id: Long!) {
    setHelp(patientId: $id)
  }
`;

export const setUndoHelp = gql`
  mutation setUndoHelp($id: Long!) {
    setUndoHelp(patientId: $id)
  }
`;

export const editOrTimes = gql`
  mutation editOrTimes(
    $patientId: Long!
    $roomId: Long!
    $enteredAt: DateTime
    $timeoutStart: DateTime
    $procedureStart: DateTime
    $calledNextPatient: DateTime
    $closingStart: DateTime
    $surgeonLeft: DateTime
  ) {
    editOrTimes(
      patientId: $patientId
      roomId: $roomId
      enteredAt: $enteredAt
      timeoutStart: $timeoutStart
      procedureStart: $procedureStart
      calledNextPatient: $calledNextPatient
      closingStart: $closingStart
      surgeonLeft: $surgeonLeft
    )
  }
`;

export const listSubscription = gql`
  subscription {
    waitingRoom {
      id
      name
      icon
      color
      changedRoomAt
      room {
        id
        name
        type
      }
      log {
        id
        entries {
          type
          room {
            id
            name
            type
          }
          manualEntryBy {
            id
            name
          }
          manualTimestamp
          status
        }
        timestamp
      }
    }
  }
`;

export default {
  list,
  listActiveAndPastPatients,
  item,
  setRoom,
  pastItem,
  create,
  update,
  remove,
  subscription,
};
