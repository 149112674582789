import React, { Fragment } from 'react';
import Day from './Day';

const Week = ({
  dates = [],
  procedures = {},
  dateSelection,
  rooms,
  onCellClick,
  showDayNames,
  firstWeek,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
}) => {
  return (
    <>
      {dates.map((date, i) => (
        <Day
          procedures={procedures}
          dateSelection={dateSelection}
          rooms={rooms}
          showDayNames={showDayNames}
          firstWeek={firstWeek}
          setEditProcedureMonthly={setEditProcedureMonthly}
          openProcedureForm={openProcedureForm}
          canUseScheduleViewProcedure={canUseScheduleViewProcedure}
          date={date}
        />
      ))}
    </>
  );
};

export default Week;
