import React, { FC } from 'react';
import GraphLayout from '../analytics/GraphLayout';
import HospitalRating from '../analytics/HospitalRating';
import PWAInstallBanner from '../../../se/components/PWAInstallBanner';
import { analyticsConfigQuery, dashboardQuery, dashboardQueryPrev } from '../../../graph/dashboard';
import { useQuery } from '@apollo/client';
import pick from 'lodash/fp/pick';
import { AnalyticsLayout } from '../Analytics';
import { Box, Paper } from '@material-ui/core';
import ProcedureCancellationChart from './v2/ProcedureCancellationChart';
import PatientJourneyChart from './v2/PatientJourneyChart';
import AnalyticsPanelTitle from './components/AnalyticsPanelTitle';
import ORPerformanceChart from './v2/ORPerformanceChart';
const pickFilter = pick(['physician', 'procedureType', 'dateRange']);

const GeneralAnalytics: FC<{ filter: any; setFilter: any; isBusinessManager: boolean }> = ({
  filter,
  setFilter,
  isBusinessManager,
}) => {
  const { dateRange, ...rest } = filter || {};

  const toDate = new Date(
    dateRange?.date?._year,
    dateRange?.date?._month - 1, // Subtract 1 from month
    dateRange?.date?._day
  );

  const customDateRange = {
    from: (() => {
      const fromDate = new Date(toDate);
      fromDate.setFullYear(fromDate.getFullYear() - (dateRange?.period?._years || 0));
      fromDate.setMonth(fromDate.getMonth() - (dateRange?.period?._months || 0));
      fromDate.setDate(fromDate.getDate() - (dateRange?.period?._days || 0));

      return '' + fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
    })(),
    to: '' + dateRange?.date?._year + '-' + dateRange?.date?._month + '-' + dateRange?.date?._day,
  };

  const { data: prevData } = useQuery(dashboardQueryPrev, {
    variables: { filter: { dateRange: customDateRange, ...pickFilter(rest) } },
  });

  const prevStatistics = prevData?.dashboardPrev || [];

  const { data, loading } = useQuery(dashboardQuery, {
    variables: { filter: { dateRange: dateRange.toJSON(), ...pickFilter(rest) } },
    fetchPolicy: 'network-only',
  });

  const statistics = data?.dashboard || [];
  const analyticsConfig = useQuery(analyticsConfigQuery);

  const totalPatientArray = [analyticsConfig?.data?.analyticsConfiguration?.totalPatientsThreshold];

  const configArray = [
    analyticsConfig?.data?.analyticsConfiguration?.waitingRoomTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.preopTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.orUtilThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.orTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.cleaningTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.popiTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.pacuTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.postOpTimeThreshold,
  ];

  return (
    <>
      <Box mt={2}>
        <PWAInstallBanner />
      </Box>

      {!isBusinessManager && <HospitalRating filter={filter} />}
      <AnalyticsLayout filter={filter} setFilter={setFilter} loading={loading}>
        <GraphLayout
          key={`${statistics[0]?.id}-${0}`}
          {...statistics[0]}
          greenLineY={totalPatientArray[0]}
          data={statistics[0]}
          prevStat={prevStatistics[0]}
        />
        <Paper style={{ display: 'flex', flexDirection: 'column' }}>
          <Box p={2}>
            <AnalyticsPanelTitle gutterBottom>Average Patient Journey</AnalyticsPanelTitle>
          </Box>
          <Box style={{ flex: 1 }}>
            <PatientJourneyChart filter={{ dateRange: dateRange.toJSON(), ...pickFilter(rest) }} />
          </Box>
        </Paper>
        <Paper style={{ display: 'flex', flexDirection: 'column' }}>
          <Box p={2}>
            <AnalyticsPanelTitle gutterBottom>OR Performance</AnalyticsPanelTitle>
          </Box>
          <Box style={{ flex: 1 }}>
            <ORPerformanceChart filter={{ dateRange: dateRange.toJSON(), ...pickFilter(rest) }} />
          </Box>
        </Paper>
        <Paper style={{ display: 'flex', flexDirection: 'column' }}>
          <Box p={2}>
            <AnalyticsPanelTitle gutterBottom>Procedure Cancellation Reasons</AnalyticsPanelTitle>
          </Box>
          <Box style={{ flex: 1 }}>
            <ProcedureCancellationChart filter={{ dateRange: dateRange.toJSON(), ...pickFilter(rest) }} />
          </Box>
        </Paper>
        {statistics.slice(1).map((stat, i) => (
          <GraphLayout
            key={`${stat.id}-${i}`}
            {...stat}
            greenLineY={configArray[i]}
            data={statistics.slice(1)[i]}
            prevStat={prevStatistics.slice(1)[i]}
          />
        ))}
      </AnalyticsLayout>
    </>
  );
};

export default GeneralAnalytics;
