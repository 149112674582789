import { addMinutes, compareAsc } from 'date-fns';
import { get } from 'lodash';
import { NO_STATUS, STATUSES as PATIENT_STATUSES } from '../../../entities/patient/enums';

export const getRemainingScheduledProcedures = procedures => {
  const nextProcedureIndex = (procedures || []).findIndex(
    p =>
      (get(p, 'patient.status', '') === NO_STATUS || get(p, 'patient.status', '') === PATIENT_STATUSES.ADMITTED)
  );
  const nextProceduresInOperationRoom = nextProcedureIndex !== -1 ? procedures.slice(nextProcedureIndex) : [];
  return [procedures?.[nextProcedureIndex], nextProceduresInOperationRoom];
};
