export const tryParseJson = (text?: string | null) => {
  try {
    return text ? JSON.parse(text) : null;
  } catch (e) {
    console.error('error', e);
    return null;
  }
};

export const tryStringifyJson = (json?: object | null) => {
  try {
    return json ? JSON.stringify(json) : null;
  } catch (e) {
    console.error('error', e);
    return null;
  }
};
