import { ProcedureEvent, ProcedureStatus } from './ProcedureEvent';
import { Patient } from './Patient';
import Vendor, { Representative } from './Vendor';
import Entity from './Entity';
import Physician from './Physician';
import Anesthesiologist from './Anesthesiologist';
import { QuestionnaireAndAnswers } from './Questionnaire';
import StaffShift from './StaffShift';

export type ProcedureType = {
  expectedPreOpDuration?: number;
  expectedDuration?: number;
  expectedPacuDuration?: number;
} & Entity;

export enum ShiftState {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export type ProcedureVendor = {
  id: number;
  procedureId: number;
  vendor: Vendor;
  shiftState: ShiftState;
  implant?: string;
  price?: string;
  representative?: Representative;
};

export interface Procedure {
  id: number;
  status?: ProcedureStatus;
  startTime?: string;
  startTimeText?: string;
  endTimeText?: string;
  duration?: number;
  orId?: number;
  or?: string;
  isCanceled?: string;
  physicianId?: number;
  physicianHstId?: number;
  physicianName?: string;
  physicianNameHST?: string;
  physicianColor?: string;
  patientId?: string;
  patientHstId?: number;
  patientName?: string;
  patientNameUnformatted?: string;
  patientInitials?: string;
  patientDateOfBirth?: string;
  patientDateOfBirthISO?: string;
  patientAge?: string;
  patientSex?: string;
  patientHomePhone?: string;
  patientCellPhone?: string;
  caretakerCellPhone?: string;
  familyCellPhone?: string;
  patientEmailAddress?: string;
  patientAddress?: string;
  procedureTypeId: number;
  procedureType: string;
  procedureMod?: string;
  diagnosis?: string;
  equipment?: string;
  cptCodes?: string;
  bilaterally?: string;
  visitType?: string;
  room?: string;
  insuranceName?: string;
  policyName?: string;
  anesthesiologistId?: string;
  anesthesiologistHstId?: string;
  anesthesiologistName?: string;
  anesthesiaType: string;
  allergy?: string;
  notes?: string;
  visit: number;
  physician?: Physician;
  patient?: Patient;
  anesthesiologist?: Anesthesiologist;
  staff?: {
    id: number;
    name: string;
  }[];
  assignedVendors: ProcedureVendor[];
  vendorRepresentative?: {
    id: number;
    vendor: Vendor;
    implant?: string;
    price?: number;
    representative?: Representative;
  };
  preOpCharts?: QuestionnaireAndAnswers[];
  orCharts?: QuestionnaireAndAnswers[];
  pacuCharts?: QuestionnaireAndAnswers[];
  physicianCharts?: QuestionnaireAndAnswers[];
  anesthesiologistPreOpCharts?: QuestionnaireAndAnswers[];
  anesthesiologistOrCharts?: QuestionnaireAndAnswers[];
  anesthesiologistPacuCharts?: QuestionnaireAndAnswers[];
  events?: ProcedureEvent[];
  allergyAssessment?: string;
  fallRiskAssessment?: string;
  medications?: string;
  anesthesiaReviewed?: boolean;
  staffShifts?: StaffShift[];
  procedurePriority?: number;
  color?: string;
}
