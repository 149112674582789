import { isValid, parse } from 'date-fns';
import { createTheme, makeStyles, Theme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {
  AccessoriesTableQuestion,
  AnesthesiaChartQuestion,
  AnesthesiaTableQuestion,
  CheckboxesQuestion,
  CheckboxQuestion,
  ChecklistQuestion,
  CirculationCheckTableQuestion, CirculationCheckTableV2Question,
  DateQuestion,
  DescriptionQuestion,
  ImagesQuestion, ItemCountsTableQuestion,
  ListQuestion,
  MatrixTableQuestion,
  MeasurementExtraField,
  MeasurementsQuestion, MeasurementsV2Question, MeasurementsV3Question,
  NotesQuestion,
  ObjectQuestion, OperativeExtremityTableQuestion,
  PostAnesthesiaRecoveryScoreQuestion, PostAnesthesiaRecoveryScoreV2Question, PostAnesthesiaRecoveryScoreV3Question,
  Question,
  SelectQuestion,
  SignatureQuestion,
  TextQuestion,
  YesNoQuestion,
} from '../types/Question';
import {
  AccessoriesTableAnswer,
  AnesthesiaChartAnswer,
  AnesthesiaTableAnswer,
  Answer,
  CheckboxAnswer,
  CheckboxesAnswer,
  ChecklistAnswer,
  CirculationCheckTableAnswer, CirculationCheckTableV2Answer,
  DateAnswer,
  ImagesAnswer, ItemCountsTableAnswer,
  ListAnswer,
  MatrixTableAnswer,
  MeasurementsAnswer, MeasurementsV2Answer, MeasurementsV3Answer,
  NotesAnswer,
  ObjectAnswer, OperativeExtremityTableAnswer,
  PostAnesthesiaRecoveryScoreAnswer, PostAnesthesiaRecoveryScoreV2Answer, PostAnesthesiaRecoveryScoreV3Answer,
  SelectAnswer,
  SignatureAnswer,
  TextAnswer,
  YesCheckboxAnswerWithChildren,
  YesNoAnswer,
  YesNoAnswerWithChildren,
} from '../types/Answer';
import YesNoTextAnswer from './answers/YesNoTextAnswer';
import DateTextAnswer from './answers/DateText';
import TextInputAnswer from './answers/TextInputAnswer';
import React, { cloneElement, createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import isBoolean from 'lodash/isBoolean';
import ImagesPreview from './answers/ImagesPreview';
import YesNoInput from './questions/YesNoInput';
import TextInput from './questions/TextInput';
import DateInput from './questions/DateInput';
import CheckboxesInput from './questions/CheckboxesInput';
import AutocompleteInput from './questions/AutocompleteInput';
import groupBy from 'lodash/fp/groupBy';
import { Box } from '@material-ui/core';
import RadioGroupInput from './questions/RadioGroupInput';
import SignatureInput from './questions/SignatureInput';
import './Form.css';
import NotesInput from './questions/NotesInput';
import NotesInputAnswer from './answers/NotesInputAnswer';
import ChecklistInput from './questions/ChecklistInput';
import { useSlide } from '../components/pages/kiosk/charting/SlideProvider';
import { useSlider } from '../components/pages/kiosk/charting/SliderProvider';
import ChartV1, { Measurements, Medication } from '../components/pages/kiosk/charting/ChartV1';
import { usePacuCharts } from '../components/pages/kiosk/charting/PacuChartsContext';
import { QuestionnaireAndAnswers } from '../types/Questionnaire';
import PostAnesthesiaRecoveryScoreV2 from '../components/pages/kiosk/charting/PostAnesthesiaRecoveryScoreV2';
import CirculationCheckTable from '../components/pages/kiosk/charting/CirculationCheckTable';
import AccessoriesTable from '../components/pages/kiosk/charting/AccessoriesTable';
import {
  sanitizeMeasurements,
  sanitizeMeasurementsV2,
  sanitizeMeasurementsV3,
} from '../components/pages/inject/Vitals';
import SelectInput from './questions/SelectInput';
import { FormRendererSpec } from './types';
import clsx from 'clsx';
import randomId from './questions/util/randomId';
import ListForm from './components/ListForm';
import Slides from './components/Slides';
import CheckboxInput from './questions/CheckboxInput';
import AnesthesiaChartV1 from './components/AnesthesiaChartV1';
import AnesthesiaTableV1 from './components/AnesthesiaTableV1';
import isArray from 'lodash/isArray';
import MatrixTable from '../components/pages/kiosk/charting/MatrixTable';
import Description from './questions/Description';
import ReactHtmlParser from 'react-html-parser';
import AnesthesiaChartV2 from './components/AnesthesiaChartV2';
import PostAnesthesiaRecoveryScoreV1 from '../components/pages/kiosk/charting/PostAnesthesiaRecoveryScoreV1';
import ChartV2, { MeasurementsV2 } from '../components/pages/kiosk/charting/ChartV2';
import ItemCountsTable from '../components/pages/kiosk/charting/ItemCountsTable';
import PostAnesthesiaRecoveryScoreV3 from '../components/pages/kiosk/charting/PostAnesthesiaRecoveryScoreV3';
import ChartV3 from '../components/pages/kiosk/charting/ChartV3';
import CirculationCheckTableV2 from '../components/pages/kiosk/charting/CirculationCheckTableV2';
import OperativeExtremityTable from '../components/pages/kiosk/charting/OperativeExtremityTable';

export type ObjectQuestionChildren<T, Q extends ObjectQuestion> = { [key in keyof Q['props']['fields']]: T };
export type YesNoQuestionChildren<T> = { child: T | null };

export type Tags = {
  [key: string]: string;
};

type FormRenderer<T, Q extends Question> = (
  tags: Tags,
  question?: Q,
  answer?: Answer<Q>,
  onChange?: (newAnswer: Answer<Q> | ((prev: Answer<Q>) => Answer<Q>)) => void
) => T;
type FormRendererProvider<T> = (spec: FormRendererSpec<T>) => FormRenderer<T, Question>;

const provideFormRenderer: FormRendererProvider<ReactNode> = spec => (tags, question, answer, onChange) => {
  if (question) {
    switch (question.type) {
      case 'Object': {
        const children = Object.entries(question.props.fields).reduce((res, curr) => {
          const [name, child] = curr;
          return {
            ...res,
            [name]: provideFormRenderer(spec)(
              tags,
              child,
              answer ? (answer as ObjectAnswer<ObjectQuestion>)[name] : undefined,
              newAnswer => {
                if (question?.props?.slides) {
                  onChange?.((prev: object) => (prev ? { ...prev, [name]: newAnswer } : { [name]: newAnswer }));
                } else {
                  onChange?.(answer ? { ...answer, [name]: newAnswer } : { [name]: newAnswer });
                }
              }
            ),
          };
        }, {});
        return spec.renderObject(children, tags, question.props, answer as ObjectAnswer<ObjectQuestion>);
      }
      case 'List': {
        const children = !!answer
          ? (answer as ObjectAnswer<ObjectQuestion>[]).map((answerItem, index) =>
              provideFormRenderer(spec)(tags, question.props.listItem, answerItem ? answerItem : undefined, newAnswer =>
                onChange?.(
                  (answer as ObjectAnswer<ObjectQuestion>[]).map((e, eIndex) => (eIndex === index ? newAnswer : e))
                )
              )
            )
          : undefined;
        return spec.renderList(children, tags, question.props, answer as ListAnswer, onChange);
      }
      case 'Text':
        return spec.renderText(tags, question['props'], answer as TextAnswer, onChange);
      case 'Notes':
        return spec.renderNotes(tags, question['props'], answer as NotesAnswer, onChange);
      case 'Checkbox':
        return spec.renderCheckbox(tags, question['props'], answer as CheckboxAnswer, onChange);
      case 'Checkboxes': {
        const checkboxesWithChild = question.props.checkboxes.map(checkbox => ({
          ...checkbox,
          child:
            checkbox?.ifYes &&
            provideFormRenderer(spec)(
              tags,
              checkbox?.ifYes,
              answer ? (answer[checkbox.name] as YesCheckboxAnswerWithChildren)?.ifYes : undefined,
              newAnswer => {
                onChange?.(
                  answer
                    ? { ...(answer as object), [checkbox.name]: { value: true, ifYes: newAnswer } }
                    : { [checkbox.name]: { value: true, ifYes: newAnswer } }
                );
              }
            ),
        }));

        return spec.renderCheckboxes(
          tags,
          { ...question['props'], checkboxes: checkboxesWithChild },
          answer as CheckboxesAnswer,
          onChange
        );
      }
      case 'Select':
        const optionsWithChild = question.props.options.map(option => ({
          ...option,
          child:
            option?.ifYes &&
            provideFormRenderer(spec)(
              tags,
              option?.ifYes,
              answer?.name === option?.name ? answer?.ifYes : undefined,
              newAnswer => {
                onChange?.({ ...answer, ifYes: newAnswer });
              }
            ),
        }));
        return spec.renderSelect(
          tags,
          { ...question['props'], options: optionsWithChild },
          answer as SelectAnswer,
          onChange
        );
      case 'Description':
        return spec.renderDescription(tags, question['props']);
      case 'YesNo':
        const children = {
          child:
            question['props'].ifYes && (answer === true || (answer as YesNoAnswerWithChildren)?.value === true)
              ? provideFormRenderer(spec)(
                  tags,
                  question['props'].ifYes,
                  answer ? (answer as YesNoAnswerWithChildren).ifYes : undefined,
                  newAnswer => {
                    onChange?.(answer ? { ...(answer as object), ifYes: newAnswer } : { ifYes: newAnswer });
                  }
                )
              : question['props'].ifNo && (answer === false || (answer as YesNoAnswerWithChildren)?.value === false)
              ? provideFormRenderer(spec)(
                  tags,
                  question['props'].ifNo,
                  answer ? (answer as YesNoAnswerWithChildren).ifNo : undefined,
                  newAnswer =>
                    onChange?.(
                      answer
                        ? {
                            ...(answer as object),
                            ifNo: newAnswer,
                          }
                        : { ifNo: newAnswer }
                    )
                )
              : null,
        };
        const onYesNoChange =
          question['props'].ifYes || question['props'].ifNo
            ? (newAnswer: YesNoAnswer) =>
                onChange?.(answer ? { ...(answer as object), value: newAnswer } : { value: newAnswer })
            : onChange;
        return spec.renderYesNo(children, tags, question['props'], answer as YesNoAnswer, onYesNoChange);
      case 'Date':
        return spec.renderDate(tags, question['props'], answer as DateAnswer, onChange);
      case 'Images':
        return spec.renderImages(tags, question['props'], answer as ImagesAnswer, onChange);
      case 'Signature':
        return spec.renderSignature(tags, question['props'], answer as SignatureAnswer, onChange);
      case 'Checklist':
        return spec.renderChecklist(tags, question['props'], answer as ChecklistAnswer, onChange);
      case 'Measurments':
      case 'Measurements':
        return spec.renderMeasurements(tags, question['props'], answer as MeasurementsAnswer, onChange);
      case 'MeasurementsV2':
        return spec.renderMeasurementsV2(tags, question['props'], answer as MeasurementsV2Answer, onChange);
      case 'MeasurementsV3':
        return spec.renderMeasurementsV3(tags, question['props'], answer as MeasurementsV3Answer, onChange);
      case 'AnesthesiaChart':
        return spec.renderAnesthesiaChart(tags, question['props'], answer as AnesthesiaChartAnswer, onChange);
      case 'AnesthesiaTable':
        return spec.renderAnesthesiaTable(tags, question['props'], answer as AnesthesiaTableAnswer, onChange);
      case 'ItemCountsTable':
        return spec.renderItemCountsTable(tags, question['props'], answer as ItemCountsTableAnswer, onChange);
      case 'MatrixTable':
        return spec.renderMatrixTable(tags, question['props'], answer as MatrixTableAnswer, onChange);
      case 'PostAnesthesiaRecoveryScore':
        return spec.renderPostAnesthesiaRecoveryScore(
          tags,
          question['props'],
          answer as PostAnesthesiaRecoveryScoreAnswer,
          onChange
        );
      case 'PostAnesthesiaRecoveryScoreV2':
        return spec.renderPostAnesthesiaRecoveryScoreV2(
          tags,
          question['props'],
          answer as PostAnesthesiaRecoveryScoreV2Answer,
          onChange
        );
      case 'PostAnesthesiaRecoveryScoreV3':
        return spec.renderPostAnesthesiaRecoveryScoreV3(
          tags,
          question['props'],
          answer as PostAnesthesiaRecoveryScoreV3Answer,
          onChange
        );
      case 'CirculationCheckTable':
        return spec.renderCirculationCheckTable(
          tags,
          question['props'],
          answer as CirculationCheckTableAnswer,
          onChange
        );
      case 'CirculationCheckTableV2':
        return spec.renderCirculationCheckTableV2(
          tags,
          question['props'],
          answer as CirculationCheckTableV2Answer,
          onChange
        );
      case 'AccessoriesTable':
        return spec.renderAccessoriesTable(tags, question['props'], answer as AccessoriesTableAnswer, onChange);
      case 'OperativeExtremityTable':
        return spec.renderOperativeExtremityTable(tags, question['props'], answer as OperativeExtremityTableAnswer, onChange);
    }
  }
  return undefined;
};

const Stack = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}))(Box);

export function useSlideIn(): () => void {
  const slider = useSlider();
  const slide = useSlide();

  return useCallback(() => {
    if (slider === null || slide === null) {
      return;
    }

    slider.slideTo(slide);
  }, [slider, slide]);
}

export const renderEditableFormUI = provideFormRenderer({
  renderDate(
    tags: Tags,
    question: DateQuestion['props'],
    answer: Answer<DateQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <DateInput {...question} answer={answer} onChange={onChange as (newAnswer: DateAnswer) => void} />
    ) : null;
  },
  renderText(
    tags: Tags,
    question: TextQuestion['props'],
    answer: Answer<TextQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <TextInput {...question} answer={answer} onChange={onChange as (newAnswer: TextAnswer) => void} />
    ) : null;
  },
  renderNotes(
    tags: Tags,
    question: NotesQuestion['props'],
    answer: Answer<NotesQuestion> | undefined,
    onChange
  ): ReactNode {
    return <NotesInput {...question} answer={answer} onChange={onChange as (newAnswer: TextAnswer) => void} />;
  },
  renderCheckboxes(
    tags: Tags,
    question: CheckboxesQuestion['props'],
    answer: Answer<CheckboxesQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <CheckboxesInput {...question} answer={answer} onChange={onChange as (newAnswer: CheckboxesAnswer) => void} />
    ) : null;
  },
  renderCheckbox(
    tags: Tags,
    question: CheckboxQuestion['props'],
    answer: Answer<CheckboxQuestion> | undefined,
    onChange
  ): ReactNode {
    return <CheckboxInput answer={answer} onChange={onChange} />;
  },
  renderSelect(
    tags: Tags,
    question: SelectQuestion['props'],
    answer: Answer<SelectQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    if (render) {
      if (question?.autocomplete) {
        return (
          <AutocompleteInput {...question} answer={answer} onChange={onChange as (newAnswer: SelectAnswer) => void} />
        );
      } else if (question?.select) {
        return <SelectInput {...question} answer={answer} onChange={onChange as (newAnswer: SelectAnswer) => void} />;
      } else {
        return (
          <RadioGroupInput {...question} answer={answer} onChange={onChange as (newAnswer: SelectAnswer) => void} />
        );
      }
    } else {
      return null;
    }
  },
  renderDescription(tags: Tags, question: DescriptionQuestion['props']): ReactNode {
    return <Description htmlContent={question.htmlContent} />;
  },
  renderYesNo(
    children,
    tags: Tags,
    question: YesNoQuestion['props'],
    answer: Answer<YesNoQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <YesNoInput {...children} {...question} answer={answer} onChange={onChange as (newAnswer: YesNoAnswer) => void} />
    ) : null;
  },
  renderObject(
    children,
    tags: Tags,
    question: ObjectQuestion['props'],
    answer: Answer<ObjectQuestion> | undefined,
    onChange?: (newAnswer: Answer<ObjectQuestion>) => void
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;

    return render ? (
      <ObjectForm children={children} tags={tags} question={question} answer={answer} onChange={onChange} />
    ) : null;
  },
  renderList(
    children,
    tags: Tags,
    question: ListQuestion['props'],
    answer: Answer<ListQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <ListForm children={children} tags={tags} question={question} answer={answer} onChange={onChange} />
    ) : null;
  },
  // TODO Jelena: check this
  renderImages(): ReactNode {
    // const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    // return render ? <ImagesInput {...question} answer={answer} onChange={onChange as (newAnswer: ImagesAnswer) => void} /> : null;
    return null;
  },
  // TODO Jelena: check this
  renderSignature(
    tags: Tags,
    question: SignatureQuestion['props'],
    answer: Answer<SignatureQuestion> | undefined,
    onChange
  ): ReactNode {
    return <SignatureInput {...question} answer={answer} onChange={onChange as (newAnswer: SignatureAnswer) => void} />;
  },
  renderChecklist(
    tags: Tags,
    question: ChecklistQuestion['props'],
    answer: Answer<ChecklistQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <ChecklistInput {...question} answer={answer} onChange={onChange as (newAnswer: ChecklistAnswer) => void} />
    ) : null;
  },
  renderMeasurements(
    tags: Tags,
    question: MeasurementsQuestion['props'],
    answer: Answer<MeasurementsQuestion> | undefined,
    onChange: (answer: Answer<MeasurementsQuestion>) => void
  ): ReactNode {
    const pacuCharts = usePacuCharts();
    const medicationExtra = Array.isArray(question.extraFields)
      ? question.extraFields.find(field => field.interpreter === 'medication')
      : undefined;
    const medications = medicationExtra ? getMedication(medicationExtra, pacuCharts) : undefined;
    return (
      <ChartV1
        value={{
          measurments: sanitizeMeasurements(
            Array.isArray(answer)
              ? (answer as Measurements[]) ?? []
              : Array.isArray(answer?.measurments)
              ? (answer?.measurments as Measurements[])
              : []
          ),
          signature: Array.isArray(answer) ? undefined : answer?.signature,
        }}
        medications={medications}
        onChange={value => onChange(value)}
      />
    );
  },
  renderMeasurementsV2(
    tags: Tags,
    question: MeasurementsQuestion['props'],
    answer: Answer<MeasurementsQuestion> | undefined,
    onChange: (answer: Answer<MeasurementsQuestion>) => void
  ): ReactNode {
    const pacuCharts = usePacuCharts();
    const medicationExtra = Array.isArray(question.extraFields)
      ? question.extraFields.find(field => field.interpreter === 'medication')
      : undefined;
    const medications = medicationExtra ? getMedication(medicationExtra, pacuCharts) : undefined;
    return (
      <ChartV2
        value={{
          measurments: sanitizeMeasurementsV2(
            Array.isArray(answer)
              ? (answer as MeasurementsV2[]) ?? []
              : Array.isArray(answer?.measurments)
                ? (answer?.measurments as MeasurementsV2[])
                : []
          ),
          signature: Array.isArray(answer) ? undefined : answer?.signature,
        }}
        medications={medications}
        onChange={value => onChange(value)}
      />
    );
  },
  renderMeasurementsV3(
    tags: Tags,
    question: MeasurementsV3Question['props'],
    answer: Answer<MeasurementsV3Question> | undefined,
    onChange: (answer: Answer<MeasurementsV3Question>) => void
  ): ReactNode {
    const pacuCharts = usePacuCharts();
    const medicationExtra = Array.isArray(question.extraFields)
      ? question.extraFields.find(field => field.interpreter === 'medication')
      : undefined;
    const medications = medicationExtra ? getMedication(medicationExtra, pacuCharts) : undefined;
    return (
      <ChartV3
        value={{
          measurments: sanitizeMeasurementsV3(
            Array.isArray(answer)
              ? (answer as MeasurementsV2[]) ?? []
              : Array.isArray(answer?.measurments)
                ? (answer?.measurments as MeasurementsV2[])
                : []
          ),
          signature: Array.isArray(answer) ? undefined : answer?.signature,
        }}
        medications={medications}
        onChange={value => onChange(value)}
      />
    );
  },
  renderAnesthesiaChart<Q extends AnesthesiaChartQuestion>(
    tags: Tags,
    question: AnesthesiaChartQuestion['props'],
    answer?: Answer<AnesthesiaChartQuestion>,
    onChange?: (newAnswer: Answer<AnesthesiaChartQuestion>) => void
  ): ReactNode {
    if (question?.version) {
      switch (question?.version) {
        case 'v1':
          return (
            <AnesthesiaChartV1 answer={isArray(answer) ? answer : {}} onChange={newAnswer => onChange && onChange(newAnswer)} />
          );
        case 'v2':
          return (
            <AnesthesiaChartV2 answer={isArray(answer) ? answer : {}} onChange={newAnswer => onChange && onChange(newAnswer)} />
          );
        default:
          return (
            <AnesthesiaChartV1 answer={isArray(answer) ? answer : {}} onChange={newAnswer => onChange && onChange(newAnswer)} />
          );
      }
    }

    return (
      <AnesthesiaChartV1 answer={isArray(answer) ? answer : {}} onChange={newAnswer => onChange && onChange(newAnswer)} />
    );
  },
  renderAnesthesiaTable(
    tags: Tags,
    question: AnesthesiaTableQuestion['props'],
    answer?: Answer<AnesthesiaTableQuestion> | undefined,
    onChange?: (newAnswer: Answer<AnesthesiaTableQuestion>) => void
  ): ReactNode {
    return (
      <AnesthesiaTableV1 answer={isArray(answer) ? answer : []} onChange={newAnswer => onChange && onChange(newAnswer)} />
    );
  },
  renderItemCountsTable(
    tags: Tags,
    question: ItemCountsTableQuestion['props'],
    answer?: Answer<ItemCountsTableQuestion> | undefined,
    onChange?: (newAnswer: Answer<ItemCountsTableQuestion>) => void
  ): ReactNode {
    return (
      <ItemCountsTable answer={answer} onChange={newAnswer => onChange && onChange(newAnswer)} />
    );
  },
  renderMatrixTable(
    tags: Tags,
    question: MatrixTableQuestion['props'],
    answer: Answer<MatrixTableQuestion> | undefined,
    onChange: (newAnswer: Answer<MatrixTableQuestion>) => void
  ): ReactNode {
    // @ts-ignore
    return <MatrixTable answer={answer} onChange={newAnswer => onChange && onChange(newAnswer)} {...question} />;
  },
  renderPostAnesthesiaRecoveryScore(
    tags: Tags,
    question: PostAnesthesiaRecoveryScoreQuestion['props'],
    answer: Answer<PostAnesthesiaRecoveryScoreQuestion> | undefined,
    onChange: (newAnswer: Answer<PostAnesthesiaRecoveryScoreQuestion>) => void
  ): ReactNode {

    // @ts-ignore
    return (
      <PostAnesthesiaRecoveryScoreV1
        value={answer}
        onChange={newAnswer => onChange && onChange(newAnswer)}
        {...question}
      />
    );
  },
  renderPostAnesthesiaRecoveryScoreV2(
    tags: Tags,
    question: PostAnesthesiaRecoveryScoreV2Question['props'],
    answer: Answer<PostAnesthesiaRecoveryScoreV2Question> | undefined,
    onChange: (newAnswer: Answer<PostAnesthesiaRecoveryScoreV2Question>) => void
  ): ReactNode {

    // @ts-ignore
    return (
      <PostAnesthesiaRecoveryScoreV2
        value={answer}
        onChange={newAnswer => onChange && onChange(newAnswer)}
        {...question}
      />
    );
  },
  renderPostAnesthesiaRecoveryScoreV3(
    tags: Tags,
    question: PostAnesthesiaRecoveryScoreV3Question['props'],
    answer: Answer<PostAnesthesiaRecoveryScoreV3Question> | undefined,
    onChange: (newAnswer: Answer<PostAnesthesiaRecoveryScoreV3Question>) => void
  ): ReactNode {

    // @ts-ignore
    return (
      <PostAnesthesiaRecoveryScoreV3
        value={answer}
        onChange={newAnswer => onChange && onChange(newAnswer)}
        {...question}
      />
    );
  },
  renderCirculationCheckTable(
    tags: Tags,
    question: CirculationCheckTableQuestion['props'],
    answer: Answer<CirculationCheckTableQuestion> | undefined,
    onChange: (newAnswer: Answer<CirculationCheckTableQuestion>) => void
  ): ReactNode {
    // @ts-ignore
    return (
      <CirculationCheckTable value={answer} onChange={newAnswer => onChange && onChange(newAnswer)} {...question} />
    );
  },
  renderCirculationCheckTableV2(
    tags: Tags,
    question: CirculationCheckTableV2Question['props'],
    answer: Answer<CirculationCheckTableV2Question> | undefined,
    onChange: (newAnswer: Answer<CirculationCheckTableV2Question>) => void
  ): ReactNode {
    // @ts-ignore
    return (
      <CirculationCheckTableV2 value={answer} onChange={newAnswer => onChange && onChange(newAnswer)} {...question} />
    );
  },
  renderAccessoriesTable(
    tags: Tags,
    question: AccessoriesTableQuestion['props'],
    answer: Answer<AccessoriesTableQuestion> | undefined,
    onChange: (newAnswer: Answer<AccessoriesTableQuestion>) => void
  ): ReactNode {
    // @ts-ignore
    return <AccessoriesTable value={answer} onChange={newAnswer => onChange && onChange(newAnswer)} {...question} />;
  },
  renderOperativeExtremityTable(
    tags: Tags,
    question: OperativeExtremityTableQuestion['props'],
    answer: Answer<OperativeExtremityTableQuestion> | undefined,
    onChange: (newAnswer: Answer<OperativeExtremityTableQuestion>) => void
  ): ReactNode {
    // @ts-ignore
    return <OperativeExtremityTable value={answer} onChange={newAnswer => onChange && onChange(newAnswer)} {...question} />;
  },
});

interface ObjectFormProps<T, Q extends ObjectQuestion> {
  children: ObjectQuestionChildren<T, Q>;
  tags: Tags;
  question: Q['props'];
  answer?: Answer<Q>;
  onChange?: (newAnswer: Answer<Q>) => void;
}

const ObjectForm = <T, Q extends ObjectQuestion>({
  children,
  tags,
  question,
  answer,
  onChange,
}: ObjectFormProps<T, Q>) => {
  const onComplete = useContext(CompletionContext);
  const muiClasses = useStyles();

  const handleComplete = (name: string, index: number) => () => {
    const root = document.querySelector(`#${slidesId}-${index}`);

    if (!root) {
      onComplete();
      return;
    }

    const allElements = root.querySelectorAll(
      Object.keys(question.fields)
        .map(name => `#${id}-${name}`)
        .join(',')
    );

    const currentIndex = Array.from(allElements).findIndex(element => element.id === `${id}-${name}`);

    if (currentIndex < 0) {
      onComplete();
      return;
    }

    const nextElement = allElements[currentIndex + 1];

    if (!nextElement) {
      onComplete();
      return;
    }

    const target = nextElement.querySelector('input, textarea, select, button');

    if (!target) {
      onComplete();
      return;
    }

    (target as HTMLElement).focus();
  };

  const id = useMemo(() => randomId(6), []);
  const slidesId = useMemo(() => randomId(6), []);

  const renderFields = (fieldNames: string[] | undefined, index: number, autofocus: boolean) => (
    <div id={`${slidesId}-${index}`} style={{ width: question.grow ? '100%' : undefined }}>
      {question.columns ? (
        <Box flex={question.grow} style={{ display: 'flex', gap: 24, justifyContent: 'center' }}>
          {Object.entries(
            groupBy(1)(
              Object.entries(question.columns).filter(([fieldName]) =>
                fieldNames ? fieldNames.indexOf(fieldName) >= 0 : true
              )
            )
          )
            .map(entry => entry as unknown as [string, [string, number][]])
            .map(([key, fields], j) => (
              <Stack key={key} flexDirection={(question as any).inline ? 'row' : 'column'}>
                {fields
                  .map(([fieldName]) => [fieldName, children[fieldName]] as const)
                  .filter(([, child]) => !!child)
                  .map(([name, child], i) => {
                    // @ts-ignore
                    const formType = child.type.name;

                    return (
                      <>
                        <CompletionContext.Provider key={name} value={handleComplete(name, index)}>
                          <ConditionContext.Provider value={autofocus && i === 0 && j === 0}>
                            <Box
                              id={id + '-' + name}
                              display={name !== 'checkinNotes' ? 'block' : 'none'}
                              style={(question as any).inline ? { flex: 1 } : undefined}
                              className={clsx({
                                [muiClasses.checkboxesInput]: formType === 'CheckboxesInput',
                                [muiClasses.objectForm]: formType === 'ObjectForm',
                              })}
                            >
                              {cloneElement(child as any, { name, answer: answer ? answer[name] : undefined })}
                            </Box>
                          </ConditionContext.Provider>
                        </CompletionContext.Provider>
                      </>
                    );
                  })}
              </Stack>
            ))}
        </Box>
      ) : (
        <div id={`${slidesId}-${index}`} style={{ width: question.grow ? '100%' : undefined }}>
          <Box
            flex={question.grow}
            display="flex"
            flexDirection={(question as any).inline ? 'row' : 'column'}
            style={{ gap: '1rem' }}
          >
            {Object.entries(children)
              .map(([fieldName]) => [fieldName, children[fieldName]] as const)
              .filter(([, child]) => !!child)
              .map(([name, child], i) => (
                <CompletionContext.Provider key={name} value={handleComplete(name, index)}>
                  <ConditionContext.Provider value={autofocus && i === 0}>
                    <div
                      id={id + '-' + name}
                      style={
                        (question as any).inline ? { flex: (question.fields[name].props as any).grow ?? 1 } : undefined
                      }
                    >
                      {cloneElement(child as any, { name, answer: answer ? answer[name] : undefined })}
                    </div>
                  </ConditionContext.Provider>
                </CompletionContext.Provider>
              ))}
          </Box>
        </div>
      )}
    </div>
  );

  const condition = useContext(ConditionContext);

  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme({
          ...theme,
          typography: {
            fontSize: 16,
          },
        })
      }
    >
      <ConditionContext.Provider value={false}>
        {question.slides ? (
          <Slides
            slides={Object.entries(groupBy(1)(Object.entries(question.slides))).map(
              entry => entry as unknown as [string, [string, number][]]
            )}
            renderFields={renderFields}
            autofocus={condition}
          />
        ) : (
          renderFields(undefined, 0, condition)
        )}
      </ConditionContext.Provider>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(theme => ({
  checkboxesInput: {
    minWidth: '36ch',
    display: 'flex',
  },
  objectForm: {
    minWidth: '36ch',
  },
}));

export const ConditionContext = createContext<boolean>(false);

export const CompletionContext = createContext<() => void>(() => {});

export const DischargeTimeContext = createContext<Date | undefined>(undefined);
export const MedicationsContext = createContext<unknown | undefined>(undefined);
export const ChartsContext = createContext<QuestionnaireAndAnswers[] | undefined>(undefined);

export const renderFormAnswer = provideFormRenderer({
  renderDate(tags: Tags, question: DateQuestion['props'], answer: Answer<DateQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <DateTextAnswer {...question} answer={answer} /> : null;
  },
  renderText(tags: Tags, question: TextQuestion['props'], answer: Answer<TextQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInputAnswer {...question} answer={answer} /> : null;
  },
  renderNotes(tags: Tags, question: NotesQuestion['props'], answer: Answer<NotesQuestion> | undefined): ReactNode {
    return <NotesInputAnswer {...question} answer={answer} />;
  },
  renderCheckboxes(
    tags: Tags,
    question: CheckboxesQuestion['props'],
    answer: Answer<CheckboxesQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInputAnswer {...question} answer={Object.values(answer || {}).join(',')} /> : null;
  },
  renderCheckbox(
    tags: Tags,
    question: CheckboxQuestion['props'],
    answer: Answer<CheckboxQuestion> | undefined
  ): ReactNode {
    return null;
  },
  renderSelect(tags: Tags, question: SelectQuestion['props'], answer: Answer<SelectQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <TextInputAnswer {...question} answer={answer?.label ? ReactHtmlParser(answer.label) : ''} />
    ) : null;
  },
  renderDescription(tags: Tags, question: SelectQuestion['props']): ReactNode {
    return null;
  },
  renderYesNo(
    children,
    tags: Tags,
    question: YesNoQuestion['props'],
    answer: Answer<YesNoQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <YesNoTextAnswer {...children} {...question} answer={answer} /> : null;
  },
  renderList(): ReactNode {
    return null;
  },
  renderObject(
    children,
    tags: Tags,
    question: ObjectQuestion['props'],
    answer: Answer<ObjectQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <div>
        {Object.entries(children)
          .filter(([child]) => !!child)
          .map(([name, child], index) => (
            <div key={index}>{cloneElement(child as any, { name, answer: answer ? answer[name] : undefined })}</div>
          ))}
      </div>
    ) : null;
  },
  renderImages(tags: Tags, question: ImagesQuestion['props'], answer?: Answer<ImagesQuestion> | undefined): ReactNode {
    return <ImagesPreview label={question.label} fileNames={answer} />;
  },
  renderSignature(
    tags: Tags,
    question: SignatureQuestion['props'],
    answer?: Answer<SignatureQuestion> | undefined
  ): ReactNode {
    return <img src={answer?.signature} alt="signature" />;
  },
  renderChecklist(
    tags: Tags,
    question: ChecklistQuestion['props'],
    answer?: Answer<ChecklistQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInputAnswer {...question} answer={Object.values(answer || {}).join(',')} /> : null;
  },
  renderMeasurements(
    tags: Tags,
    question: MeasurementsQuestion['props'],
    answer?: Answer<MeasurementsQuestion> | undefined
  ): ReactNode {
    return null;
  },
  renderMeasurementsV2(
    tags: Tags,
    question: MeasurementsV2Question['props'],
    answer?: Answer<MeasurementsV2Question> | undefined
  ): ReactNode {
    return null;
  },
  renderMeasurementsV3(
    tags: Tags,
    question: MeasurementsV3Question['props'],
    answer?: Answer<MeasurementsV3Question> | undefined
  ): ReactNode {
    return null;
  },
  renderAnesthesiaChart<Q extends AnesthesiaChartQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): ReactNode {
    return null;
  },
  renderAnesthesiaTable<Q extends AnesthesiaTableQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): ReactNode {
    return null;
  },
  renderMatrixTable<Q extends MatrixTableQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q> | undefined
  ): ReactNode {
    return null;
  },
  renderPostAnesthesiaRecoveryScore<Q extends PostAnesthesiaRecoveryScoreQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q> | undefined
  ): ReactNode {
    return null;
  },
  renderPostAnesthesiaRecoveryScoreV2<Q extends PostAnesthesiaRecoveryScoreV2Question>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q> | undefined
  ): ReactNode {
    return null;
  },
  renderPostAnesthesiaRecoveryScoreV3<Q extends PostAnesthesiaRecoveryScoreV3Question>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q> | undefined
  ): ReactNode {
    return null;
  },
  renderCirculationCheckTable<Q extends CirculationCheckTableQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q> | undefined
  ): ReactNode {
    return null;
  },
  renderCirculationCheckTableV2<Q extends CirculationCheckTableV2Question>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q> | undefined
  ): ReactNode {
    return null;
  },
  renderAccessoriesTable<Q extends AccessoriesTableQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q> | undefined
  ): ReactNode {
    return null;
  },
});

const provideFormStatusRenderer: FormRendererProvider<boolean> =
  spec =>
  (tags, question, answer): boolean => {
    if (question) {
      switch (question.type) {
        case 'Object': {
          const children = Object.entries(question.props.fields).reduce((res, curr) => {
            const [name, child] = curr;
            return {
              ...res,
              [name]: provideFormStatusRenderer(spec)(
                tags,
                child,
                answer ? (answer as ObjectAnswer<ObjectQuestion>)[name] : undefined
              ),
            };
          }, {});
          return spec.renderObject(children, tags, question.props, answer as ObjectAnswer<ObjectQuestion>);
        }
        case 'List': {
          const children = !!answer
            ? (answer as ObjectAnswer<ObjectQuestion>[]).map(answerItem =>
                provideFormStatusRenderer(spec)(tags, question.props.listItem, answerItem ? answerItem : undefined)
              )
            : undefined;
          return spec.renderList(children, tags, question['props'], answer as ListAnswer);
        }
        case 'Text':
          return spec.renderText(tags, question['props'], answer as TextAnswer);
        case 'Notes':
          return spec.renderNotes(tags, question['props'], answer as NotesAnswer);
        case 'Checkboxes':
          return spec.renderCheckboxes(tags, question['props'], answer as CheckboxesAnswer);
        case 'Checkbox':
          return spec.renderCheckbox(tags, question['props'], answer as CheckboxAnswer);
        case 'Select':
          return spec.renderSelect(tags, question['props'], answer as SelectAnswer);
        case 'Description':
          return spec.renderDescription(tags, question['props']);
        case 'YesNo':
          const children = {
            child:
              question['props'].ifYes && (answer === true || (answer as YesNoAnswerWithChildren)?.value === true)
                ? provideFormStatusRenderer(spec)(
                    tags,
                    question['props'].ifYes,
                    answer ? (answer as YesNoAnswerWithChildren).ifYes : undefined
                  )
                : question['props'].ifNo && (answer === false || (answer as YesNoAnswerWithChildren)?.value === false)
                ? provideFormStatusRenderer(spec)(
                    tags,
                    question['props'].ifNo,
                    answer ? (answer as YesNoAnswerWithChildren).ifNo : undefined
                  )
                : null,
          };
          return spec.renderYesNo(children, tags, question['props'], answer as YesNoAnswer);
        case 'Date':
          return spec.renderDate(tags, question['props'], answer as DateAnswer);
        case 'Images':
          return spec.renderImages(tags, question['props'], answer as ImagesAnswer);
        case 'Signature':
          return spec.renderSignature(tags, question['props'], answer as SignatureAnswer);
        case 'Checklist':
          return spec.renderChecklist(tags, question['props'], answer as ChecklistAnswer);
        case 'Measurments':
        case 'Measurements':
          return spec.renderMeasurements(tags, question['props'], answer as MeasurementsAnswer);
        case 'MeasurementsV2':
          return spec.renderMeasurementsV2(tags, question['props'], answer as MeasurementsV2Answer);
        case 'MeasurementsV3':
          return spec.renderMeasurementsV3(tags, question['props'], answer as MeasurementsV3Answer);
        case 'AnesthesiaChart':
          return spec.renderAnesthesiaChart(tags, question['props'], answer as AnesthesiaChartAnswer);
        case 'AnesthesiaTable':
          return spec.renderAnesthesiaTable(tags, question['props'], answer as AnesthesiaTableAnswer);
        case 'ItemCountsTable':
          return spec.renderItemCountsTable(tags, question['props'], answer as ItemCountsTableAnswer);
        case 'MatrixTable':
          return spec.renderMatrixTable(tags, question['props'], answer as MatrixTableAnswer);
        case 'PostAnesthesiaRecoveryScore':
          return spec.renderPostAnesthesiaRecoveryScore(
            tags,
            question['props'],
            answer as PostAnesthesiaRecoveryScoreAnswer
          );
        case 'PostAnesthesiaRecoveryScoreV2':
          return spec.renderPostAnesthesiaRecoveryScoreV2(
            tags,
            question['props'],
            answer as PostAnesthesiaRecoveryScoreV2Answer
          );
        case 'PostAnesthesiaRecoveryScoreV3':
          return spec.renderPostAnesthesiaRecoveryScoreV3(
            tags,
            question['props'],
            answer as PostAnesthesiaRecoveryScoreV3Answer
          );
        case 'CirculationCheckTable':
          return spec.renderCirculationCheckTable(tags, question['props'], answer as CirculationCheckTableAnswer);
        case 'CirculationCheckTableV2':
          return spec.renderCirculationCheckTableV2(tags, question['props'], answer as CirculationCheckTableV2Answer);
        case 'AccessoriesTable':
          return spec.renderAccessoriesTable(tags, question['props'], answer as AccessoriesTableAnswer);
        case 'OperativeExtremityTable':
          return spec.renderOperativeExtremityTable(tags, question['props'], answer as OperativeExtremityTableAnswer);
        default:
          return false;
      }
    }
    return false;
  };

export const isPass = provideFormStatusRenderer({
  renderDate(): boolean {
    return true;
  },
  renderText(): boolean {
    return true;
  },
  renderNotes(): boolean {
    return true;
  },
  renderCheckboxes(): boolean {
    return true;
  },
  renderCheckbox(): boolean {
    return true;
  },
  renderSelect(): boolean {
    return true;
  },
  renderDescription(): boolean {
    return true;
  },
  renderYesNo(children, tags, question: YesNoQuestion['props'], answer: Answer<YesNoQuestion> | undefined): boolean {
    if (answer === undefined) return true;
    if (isBoolean(answer) && isBoolean(question.isClear)) {
      return answer === question.isClear;
    } else if (isBoolean((answer as YesNoAnswerWithChildren)?.value) && isBoolean(question.isClear)) {
      return (answer as YesNoAnswerWithChildren)?.value === question.isClear;
    } else {
      return true;
    }
  },
  renderImages(): boolean {
    return true;
  },
  renderList(): boolean {
    return true;
  },
  renderObject(children): boolean {
    return Object.entries(children).reduce((res: boolean, e: [string, boolean]) => {
      const [, child] = e;
      return child && res;
    }, true);
  },
  renderSignature(): boolean {
    return true;
  },
  renderChecklist(): boolean {
    return true;
  },
  renderMeasurements(): boolean {
    return true;
  },
  renderMeasurementsV2(): boolean {
    return true;
  },
  renderMeasurementsV3(): boolean {
    return true;
  },
  renderAnesthesiaChart(): boolean {
    return true;
  },
  renderAnesthesiaTable(): boolean {
    return true;
  },
  renderItemCountsTable(): boolean {
    return true;
  },
  renderMatrixTable(): boolean {
    return true;
  },
  renderPostAnesthesiaRecoveryScore(): boolean {
    return true;
  },
  renderPostAnesthesiaRecoveryScoreV2(): boolean {
    return true;
  },
  renderPostAnesthesiaRecoveryScoreV3(): boolean {
    return true;
  },
  renderCirculationCheckTable(): boolean {
    return true;
  },
  renderCirculationCheckTableV2(): boolean {
    return true;
  },
  renderAccessoriesTable(): boolean {
    return true;
  },
  renderOperativeExtremityTable(): boolean {
    return true;
  },
});

export const isCompleted = provideFormStatusRenderer({
  renderDate(tags, question: DateQuestion['props'], answer: Answer<DateQuestion> | undefined): boolean {
    return question?.optional ? true : answer === '******' || isValid(parse(answer as string));
  },
  renderText(tags, question: TextQuestion['props'], answer: Answer<TextQuestion> | undefined): boolean {
    return question?.optional ? true : answer ? answer?.trim() !== '' : false;
  },
  renderNotes(): boolean {
    return true;
  },
  renderList(): boolean {
    return true;
  },
  renderCheckboxes(
    tags,
    question: CheckboxesQuestion['props'],
    answer: Answer<CheckboxesQuestion> | undefined
  ): boolean {
    return question?.optional ? true : !!answer ? Object.values(answer).some(e => !!e) : false;
  },
  renderCheckbox(tags, question: CheckboxQuestion['props'], answer: Answer<CheckboxQuestion> | undefined): boolean {
    return true;
  },
  renderSelect(tags, question: SelectQuestion['props'], answer: Answer<SelectQuestion> | undefined): boolean {
    return question?.optional ? true : !!answer;
  },
  renderDescription(tags, question: DescriptionQuestion['props']): boolean {
    return true;
  },
  renderYesNo(children, tags, question: YesNoQuestion['props'], answer: Answer<YesNoQuestion> | undefined): boolean {
    if (question?.optional) {
      return true;
    } else {
      const include = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
      if (include) {
        if (isBoolean(answer)) {
          return true;
        } else {
          if (isBoolean((answer as YesNoAnswerWithChildren)?.value)) {
            return (answer as YesNoAnswerWithChildren).value
              ? question?.ifYes
                ? !!children.child
                : true
              : question?.ifNo
              ? !!children.child
              : true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    }
  },
  renderObject(children, tags, question: ObjectQuestion['props']): boolean {
    if (question?.optional) {
      return true;
    } else {
      const answerKeys = children ? Object.keys(children) : [];
      return Object.keys(question.fields).every(e => answerKeys.includes(e)) && Object.values(children).every(e => e);
    }
  },
  renderImages<Q extends ImagesQuestion>(tags: Tags, question: Q['props'], answer?: ImagesAnswer): boolean {
    return question?.optional ? true : (answer?.length ?? 0) > 0;
  },
  renderSignature(tags: Tags, question: SignatureQuestion['props'], answer?: SignatureAnswer): boolean {
    return answer ? answer?.signature?.length > 0 : false;
  },
  renderChecklist(tags: Tags, question: ChecklistQuestion['props'], answer?: ChecklistAnswer): boolean {
    return question?.optional ? true : !!answer ? Object.values(answer).every(e => !!e) : false;
  },
  renderMeasurements(tags: Tags, question: MeasurementsQuestion['props'], answer?: MeasurementsAnswer): boolean {
    return true;
  },
  renderMeasurementsV2(tags: Tags, question: MeasurementsV2Question['props'], answer?: MeasurementsV2Answer): boolean {
    return true;
  },
  renderMeasurementsV3(tags: Tags, question: MeasurementsV3Question['props'], answer?: MeasurementsV3Answer): boolean {
    return true;
  },
  renderAnesthesiaChart<Q extends AnesthesiaChartQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>
  ): boolean {
    return true;
  },
  renderAnesthesiaTable<Q extends AnesthesiaTableQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>
  ): boolean {
    return true;
  },
  renderItemCountsTable<Q extends ItemCountsTableQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>
  ): boolean {
    return true;
  },
  renderMatrixTable(tags: Tags, question: MatrixTableQuestion['props'], answer?: MatrixTableAnswer): boolean {
    return true;
  },
  renderPostAnesthesiaRecoveryScore(
    tags: Tags,
    question: PostAnesthesiaRecoveryScoreQuestion['props'],
    answer?: PostAnesthesiaRecoveryScoreAnswer
  ): boolean {
    return true;
  },
  renderPostAnesthesiaRecoveryScoreV2(
    tags: Tags,
    question: PostAnesthesiaRecoveryScoreV2Question['props'],
    answer?: PostAnesthesiaRecoveryScoreV2Answer
  ): boolean {
    return true;
  },
  renderPostAnesthesiaRecoveryScoreV3(
    tags: Tags,
    question: PostAnesthesiaRecoveryScoreV3Question['props'],
    answer?: PostAnesthesiaRecoveryScoreV3Answer
  ): boolean {
    return true;
  },
  renderCirculationCheckTable(
    tags: Tags,
    question: CirculationCheckTableQuestion['props'],
    answer?: CirculationCheckTableAnswer
  ): boolean {
    return true;
  },
  renderCirculationCheckTableV2(
    tags: Tags,
    question: CirculationCheckTableV2Question['props'],
    answer?: CirculationCheckTableV2Answer
  ): boolean {
    return true;
  },
  renderAccessoriesTable(
    tags: Tags,
    question: AccessoriesTableQuestion['props'],
    answer?: AccessoriesTableAnswer
  ): boolean {
    return true;
  },
  renderOperativeExtremityTable(
    tags: Tags,
    question: OperativeExtremityTableQuestion['props'],
    answer?: OperativeExtremityTableAnswer
  ): boolean {
    return true;
  },
});

const getMedication = (
  medicationExtra: MeasurementExtraField,
  pacuCharts: QuestionnaireAndAnswers[]
): Medication[] | null => {
  const pacuChart = pacuCharts.find(e => e.questionnaire.id === medicationExtra.questionnaireId);

  if (!pacuChart) {
    return null;
  }

  if (!pacuChart.questionnaireExchange) {
    return null;
  }

  try {
    const answers = JSON.parse(pacuChart.questionnaireExchange.answers);
    const today = new Date().toISOString().split('T')[0] + 'T';

    return answers.medication.map(
      m =>
        ({
          timestamp: new Date(today + m.time).getTime(),
          name: String(m.medication),
        } as Medication)
    );
  } catch (e) {
    console.warn(e);
  }

  return null;
};
