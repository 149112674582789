import React, { useState } from 'react';
import styled from 'styled-components';
import { withDate } from '../../../HospitalInfo';
import { differenceInSeconds } from 'date-fns';

const Timer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10em;
  font-family: monospace;
  font-weight: 500;
`;

const Label = styled.div`
  opacity: 0.5;
  font-size: 4em;
  color: #abe3ff;
`;

const leftPad = val => (String(val).length < 2 ? `0${val}` : val);

const formatDuration = seconds => {
  const hrs = Math.floor(seconds / 3600);
  const mnt = Math.floor((seconds - hrs * 3600) / 60);
  const sec = seconds % 60;
  return [leftPad(hrs), leftPad(mnt), leftPad(sec)].join(':');
};

const withSeconds = withDate(1000);

const TurnoverClock = props => {
  const [cleaningStartedAtDefault] = useState(new Date());

  return (
    <>
      <Label>Turnover</Label>
      <Timer>
        {formatDuration(differenceInSeconds(props.date, props?.room?.cleaningStartedAt || cleaningStartedAtDefault))}
      </Timer>
    </>
  );
};

export default withSeconds(TurnoverClock);
