import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../styles';
import MedicalPassportField from '../components/MedicalPassportField';
import {
  ageFrom60to69YearsOld,
  ageFrom70to79YearsOld,
  ageFrom80YearsOld,
  toBooleanNegative,
  toBooleanPositive,
  toFemale,
  toMale,
} from '../../../../../questionnaire/fallRiskReducers';
import toSex from '../../../../../questionnaire/toSex';
import toAge from '../../../../../questionnaire/toAge';
import FallRiskCheckbox from '../components/FallRiskCheckbox';
import toSelectedCheckBox from '../../../../../questionnaire/toSelectedCheckbox';
import ChartingQuestionnairesContext, {
  useChartingAnswers,
} from '../../../../../contexts/ChartingQuestionnairesContext';
import { QuestionnaireType } from '../../../../../types/Questionnaire';
import { useValue } from '../ValueContext';

const Checkbox = ({ name, label, defaultValue, score, onUpdate }) => {
  const { value, setValue } = useValue(name);

  useEffect(() => {
    const initial = value !== undefined ? value : defaultValue;
    onUpdate(name, initial ? score : 0);
  }, []);

  return (
    <label>
      <input
        type="checkbox"
        name={name}
        checked={value !== undefined ? value : defaultValue}
        onChange={e => {
          setValue(e.target.checked);
          onUpdate(name, e.target.checked ? score : 0);
        }}
      />
      {label}
    </label>
  );
};

const fallRiskAssessment30 = ({ showQRCode, defaultValue, value }) => {
  const answers = useChartingAnswers(QuestionnaireType.PreOpChart, 'Assessments');

  const providerId = defaultValue?.procedure?.patient?.providerId;

  const [values1, setValues1] = useState({});
  const [values2, setValues2] = useState({});

  const total1 = useMemo(() => Object.values(values1).reduce((acc, value) => acc + value, 0), [values1]);
  const total2 = useMemo(() => Object.values(values2).reduce((acc, value) => acc + value, 0), [values2]);

  const add1 = (name, score) => setValues1(v => ({ ...v, [name]: score }));
  const add2 = (name, score) => setValues2(v => ({ ...v, [name]: score }));

  return (
    <div id="page7" className="page">
      <div style={styles.center}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div />
          <h2 style={styles.underlineHeading}>Fall Risk Assessment</h2>
          <div>
            {showQRCode && (
              <img
                alt="Barcoded value"
                src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
              />
            )}
          </div>
        </div>
        <div
          style={{
            marginBottom: '0.4rem',
            width: '100%',
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
            <MedicalPassportField name="procedure.patient.dob" label="DOB" path={['demographicInformation', 'dob']} />
            <MedicalPassportField name="procedure.patient.providerId" label="MRN" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
            <MedicalPassportField
              name="procedure.patient.sex"
              label="Sex"
              path={['demographicInformation', 'sex']}
              reducer={toSex}
            />
            <MedicalPassportField
              name="procedure.patient.age"
              label="Age"
              path={['demographicInformation', 'dob']}
              reducer={toAge}
            />
            <MedicalPassportField name="procedure.serviceTime" label="DOS" />
          </div>
        </div>
      </div>
      <div>
        <div style={styles.oneColumn}>Fall Risk Assessment</div>
        <div style={styles.threeNotEqualColumns3}>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '.2rem', fontSize: '1rem' }}>
            Risk Factor(s)
          </div>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '.2rem', fontSize: '1rem' }}>
            Scale
          </div>
          <div style={{ textAlign: 'center', padding: '.2rem', fontSize: '1rem' }}>Score</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
            }}
          >
            <div style={{ fontSize: '1rem' }}>Age</div>
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.ageFrom60to69YearsOld"
              label="60-69"
              defaultValue={'q1_opt1' === answers?.fallRisk_q1?.name}
              score={1}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.ageFrom70to79YearsOld"
              label="70-79"
              defaultValue={'q1_opt2' === answers?.fallRisk_q1?.name}
              score={2}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            2
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <Checkbox
              name="fallRiskAssessment.ageFrom80YearsOld"
              label="greater than or equal to 80 years"
              defaultValue={'q1_opt3' === answers?.fallRisk_q1?.name}
              score={3}
              onUpdate={add1}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>3</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 3',
            }}
          >
            Gender
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.genderMale"
              label="Male"
              defaultValue={'q2_opt1' === answers?.fallRisk_q2?.name}
              score={2}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            2
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <Checkbox
              name="fallRiskAssessment.genderFemale"
              label="Female"
              defaultValue={'q2_opt2' === answers?.fallRisk_q2?.name}
              score={1}
              onUpdate={add1}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>1</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 2',
              fontSize: '.85rem',
            }}
          >
            Fall History
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.fallHistory"
              label="One fall within past 6 months - accidental trips/falls/balances issues"
              defaultValue={true === answers?.fallRisk_q3?.fall_Y}
              score={20}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '.75rem',
            }}
          >
            20
          </div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
              fontSize: '.7rem',
            }}
          >
            Medication in last 24 hours- sedatives, hypnotics, barbiturates, phenothiazines, antidepressants,
            laxatives/diuretics, narcotics
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.medicationOne"
              label="On 1 high fall risk medication"
              defaultValue={'q4_opt1' === answers?.fallRisk_q4?.name}
              score={1}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.medicationTwo"
              label="On 2 high fall risk medication"
              defaultValue={'q4_opt2' === answers?.fallRisk_q4?.name}
              score={3}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              padding: '0.25rem',
              fontSize: '.75rem',
            }}
          >
            3
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <Checkbox
              name="fallRiskAssessment.medicationThree"
              label="On 3 or more high fall risk medication"
              defaultValue={'q4_opt3' === answers?.fallRisk_q4?.name}
              score={5}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
              fontSize: '.75rem',
            }}
          >
            Current Co-morbid Conditions-HTN, Cardiac, seizure disorder, diabetes, cancer, Parkinson’s, MS, stroke,
            dementia, vertigo, hx of vaso-vagal episodes, pacemaker/defibrillator, clotting/bleeding disorders,
            arthritis, aphasia, PTSD
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.conditionOne"
              label="1 Co-morbid condition"
              defaultValue={'q5_opt1' === answers?.fallRisk_q5?.name}
              score={5}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.conditionTwo"
              label="2 Co-morbid condition"
              defaultValue={'q5_opt2' === answers?.fallRisk_q5?.name}
              score={10}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            10
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <Checkbox
              name="fallRiskAssessment.conditionThree"
              label="3 or more Co-morbid condition"
              defaultValue={'q5_opt3' === answers?.fallRisk_q5?.name}
              score={15}
              onUpdate={add1}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>15</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 6',
            }}
          >
            Mobility (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.assistivedevice"
              label="Crutches/walker or other assistive device"
              defaultValue={true === answers?.fallRisk_q6?.q6_opt5}
              score={10}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.mobiliyAssist"
              label="Requires assistance or supervision for mobility, transfer, or ambulation"
              defaultValue={true === answers?.fallRisk_q6?.q6_opt1}
              score={10}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.unsteadyGait"
              label="Unsteady gait"
              defaultValue={true === answers?.fallRisk_q6?.q6_opt2}
              score={10}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.visualImpairment"
              label="Visual impairment affecting mobility"
              defaultValue={true === answers?.fallRisk_q6?.q6_opt3}
              score={5}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <Checkbox
              name="fallRiskAssessment.auditoryImpaired"
              label="Auditory impairment affecty mobility"
              defaultValue={true === answers?.fallRisk_q6?.q6_opt4}
              score={2}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '.75rem',
            }}
          >
            2
          </div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 3',
            }}
          >
            Home Oxygen
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.oxygenYes"
              label="Yes"
              defaultValue={true === answers?.fallRisk_q7}
              score={1}
              onUpdate={add1}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <Checkbox
              name="fallRiskAssessment.oxygenNo"
              label="No"
              defaultValue={false === answers?.fallRisk_q7}
              score={0}
              onUpdate={add1}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>0</div>
        </div>
      </div>
      <div>
        <div style={styles.twoNotEqualColumns4}>
          <div style={{ textAlign: 'right', padding: '0.5rem', borderRight: '1px solid black', fontSize: '.75rem' }}>
            Total
          </div>
          <div style={{ textAlign: 'center', padding: '.25rem', fontSize: '.75rem' }}>
            <MedicalPassportField name="fallRiskAssessment.total1" value={total1} />
          </div>
        </div>
        <div style={styles.oneColumn}>
          Pre-Procedure Assessment--completed by RN during preop phone call or upon arrival the day of service
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '.20rem', fontSize: '1rem' }}>
            Risk Factor(s)
          </div>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '.20rem', fontSize: '1rem' }}>
            Scale
          </div>
          <div style={{ textAlign: 'center', padding: '.20rem', fontSize: '1rem' }}>Score</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
            }}
          >
            Tubes & Cords (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.IV"
              label="IVs"
              defaultValue={true === answers?.preProcedure_q1?.tubes_iv}
              score={1}
              onUpdate={add2}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.O2"
              label="O2"
              defaultValue={true === answers?.preProcedure_q1?.tubes_O2}
              score={1}
              onUpdate={add2}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <Checkbox
              name="fallRiskAssessment.SCD"
              label="SCDs"
              defaultValue={true === answers?.preProcedure_q1?.tubes_scd}
              score={1}
              onUpdate={add2}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>1</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 5',
            }}
          >
            Cognitive or behavioral issues (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.agitated"
              label="Agitated"
              defaultValue={true === answers?.preProcedure_q4?.cognitive_agitated}
              score={2}
              onUpdate={add2}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            2
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.impulseControl"
              label="Impulse control"
              defaultValue={true === answers?.preProcedure_q4?.cognitive_impulse}
              score={5}
              onUpdate={add2}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <Checkbox
              name="fallRiskAssessment.noncompliance"
              label="Noncompliance"
              defaultValue={true === answers?.preProcedure_q4?.cognitive_noncompliance}
              score={5}
              onUpdate={add2}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <Checkbox
              name="fallRiskAssessment.lackUnderstandingLimit"
              label="Lack of Understanding limitations"
              defaultValue={true === answers?.preProcedure_q4?.cognitive_lack}
              score={5}
              onUpdate={add2}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>5</div>
        </div>
      </div>
      <div>
        <div style={styles.twoNotEqualColumns4}>
          <div
            style={{
              textAlign: 'right',
              padding: '0.5rem',
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            Pre-Op Assessment point Total
          </div>
          <div style={{ textAlign: 'center', padding: '0.5rem', fontSize: '.75rem', borderBottom: '1px solid black' }}>
            <MedicalPassportField name="fallRiskAssessment.preProcedureTotal" value={total2} />
          </div>
          <div style={{ textAlign: 'right', padding: '0.5rem', borderRight: '1px solid black', fontSize: '.75rem' }}>
            Combined Total PAT Pre-procedure Assessment Point Total
          </div>
          <div style={{ textAlign: 'center', padding: '0.5rem', fontSize: '.75rem' }}>
            <MedicalPassportField name="fallRiskAssessment.combinedTotal" />
          </div>
        </div>
        <div style={styles.oneColumn}>
          <div style={{ fontSize: '.75rem' }}>
            Implement High Risk Fall Precautions for any patient with a fall history in the last 6 months or an
            assessment score of &gt; 20 points.
          </div>
        </div>
        <div style={styles.oneColumn}>
          <div style={{ fontSize: '.75rem' }}>
            WHEELCHAIR REQUIRED FOR ANY PATIENT USING ASSISTIVE DEVICES OR VISIBLE DIFFICULTY WITH AMBULATION.
          </div>
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            fontSize: '.75rem',
          }}
        >
          <div style={styles.oneColumn}>
            <div style={{ fontSize: '.75rem' }}>
              REMINDER: All PACU patients are considered High Risk for falls. Additional protocols for any patients
              experiencing any of the following risks.
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: '.5rem' }}>
        <div style={styles.twoEqualColumnsNoBorder}>
          <MedicalPassportField
            name="rnName"
            label="PreOp Nurse Signature"
            path={[
              'basicscreening',
              'mobility',
              'Requires assistance or supervision for mobility, transfer, or ambulation',
            ]}
          />
          <MedicalPassportField name="rnDT" label="Date/Time" />
        </div>
      </div>
    </div>
  );
};

export default fallRiskAssessment30;
