import React, { Fragment } from 'react';
import { format, parse, addMinutes } from 'date-fns';
import styled, { css } from 'styled-components';
import responsive from '../../../../../se/utilities/responsive';
import get from 'lodash/get';
import { translateToUTC } from '../../../../../util/dateTime';
import { isSameMonth } from 'date-fns';
import { withTheme } from '../../../../../se/theme';
import Slot from '../MonthlyView/Slot';
import { HeaderCell, HeaderCellWithCount } from '../MonthlyView/Cell';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, max-content) repeat(1, 1fr);
  grid-template-rows: repeat(${props => props.numberOfRows}, min-content);
  grid-column-gap: 0.1666666667em;
  grid-auto-flow: columns;
  margin-bottom: 0.33333em;
`;

const Slots = styled.div`
  display: grid;
  grid-column: 2 / span 1;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0.1666666667em;
  margin-bottom: 0.08333333333em;
`;

const Row = styled(Slots)`
  grid-template-rows: auto;
  margin-bottom: 0;
`;

const OrCell = styled(HeaderCell)`
  ${responsive.md.andSmaller`
    writing-mode: vertical-rl;
    text-orientation: mixed;
  `}
`;

const DayNumber = styled.div`
  font-size: 1.25em;
  color: ${withTheme(theme => theme.textColor.string())};
`;

const Timeline = styled.div`
  background: rgba(255, 255, 255, 0.08);
  min-height: 4em;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  ${props =>
    props.makeRoom &&
    css`
      padding-top: 1.5em;
      min-height: 5.5em;
    `}
`;

const DateHeader = ({ date, procedureCounts = {} }) => (
  <Row>
    <Fragment key={`${date}`}>
      <HeaderCellWithCount>
        <span>{procedureCounts[format(date, 'YYYY-MM-DD')] || 0} procedures</span>
        <div className="day-wrapper">
          <DayNumber>{format(date, 'D')}</DayNumber>
        </div>
      </HeaderCellWithCount>
    </Fragment>
  </Row>
);

const VerticalTick = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > label {
    position: absolute;
    top: 0;
    left: auto;
    opacity: 0.6;
  }

  > span {
    display: block;
    height: 100%;
    width: 0.08rem;
    background-color: rgba(255, 255, 255, 0.12);
  }
`;

const Day = ({
  procedures = {},
  dateSelection,
  rooms,
  onCellClick,
  showDayNames,
  firstWeek,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  date,
}) => {
  const hours = [8, 10, 12, 14, 16];

  const dateString = format(date, 'YYYY-MM-DD');
  const procedureCounts = {
    [dateString]: Object.keys(procedures)
      .filter(key => key.includes(dateString))
      .reduce((sum, key) => sum + procedures[key].length, 0),
  };
  const dayName = format(date, 'dddd');

  return (
    <Container numberOfRows={showDayNames ? 3 : 2}>
      {showDayNames && (
        <Fragment>
          <HeaderCell style={{ marginBottom: '0.1666666667em' }} />
          <Row>
            <Fragment key={`${dayName}`}>
              <HeaderCell style={{ marginBottom: '0.1666666667em' }}>{dayName}</HeaderCell>
            </Fragment>
          </Row>
        </Fragment>
      )}
      <HeaderCell />

      <DateHeader date={date} procedureCounts={procedureCounts} />
      {rooms.map((room, i) => (
        <Fragment key={`${room.id}-${i}`}>
          <OrCell style={{ gridColumn: 1 }}>{room.name}</OrCell>
          <Slots>
            <Timeline key={`${date}`} makeRoom={firstWeek && i === 0}>
              {hours.map(h => (
                <VerticalTick key={`hour-${h}`}>
                  {firstWeek && i === 0 && <label>{h}</label>}
                  <span />
                </VerticalTick>
              ))}
              <Slot
                key={date}
                date={date}
                procedures={get(procedures, `${room.id}_${format(date, 'YYYY-MM-DD')}`, []).map(procedure => ({
                  ...procedure,
                  start: parse(procedure.startTime),
                  end: addMinutes(parse(procedure.startTime), procedure.duration),
                }))}
                setEditProcedureMonthly={setEditProcedureMonthly}
                openProcedureForm={openProcedureForm}
                canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                operationRooms={rooms}
                operationRoom={room}
              />
            </Timeline>
          </Slots>
        </Fragment>
      ))}
    </Container>
  );
};

export default Day;
