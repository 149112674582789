import { gql } from '@apollo/client';

import { fragments as hospitalFragments } from './hospitals';

export const scope = gql`
  query scope {
    scope {
      hospital {
        ...HospitalData
        groupId
        covidScreening {
          id
          question
        }
      }
    }
  }
  ${hospitalFragments.all}
`;
