import { alpha, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { format, isToday } from 'date-fns';
import isEmpty from 'lodash/fp/isEmpty';
import React from 'react';
import styled from 'styled-components';
import dateRange from '../../../../../assets/images/icons/date_range.png';
import { useScope } from '../../../../../hooks/useScope';
import responsive, { breakpoint } from '../../../../../se/utilities/responsive';
import { AirFiltrationIndicatorTimer } from '../../widgets/AirFiltrationIndicator';
import { Anesthesiologist } from '../anesthesiologist/PersonnelRoom';
import Procedures, { CondensedProcedures } from '../edit/Procedures';
import { fullNameToLastNameRest } from '../shared/procedureUtils';
import { RoomName } from '../shared/RoomName';
import MultipleSelectionCheckbox from '../staff/MultipleSelectionCheckbox';
import { StaffShiftRoomContext } from '../staff/StaffShiftContext';
import StaffShiftMenu from '../staff/StaffShiftMenu';
import StaffShiftMenuButton from '../staff/StaffShiftMenuButton';
import StaffShifts from '../staff/StaffShifts';
import { StaffSlotRoomContext } from '../staff/StaffSlotContext';

const Columns = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;
  flex-wrap: nowrap;
  overflow: ${props => (props.isKiosk ? 'initial' : 'auto')};
  padding-bottom: 0.375em;
  max-width: 100%;
  min-height: 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: rgba(255, 255, 255, 0.07);
  margin: 0 0.1875em;
  border-radius: 0.3333em;
  padding: 0.66666em 0.375em 0;
  min-height: min-content;
  overflow: hidden;

  ${props =>
    props.isKiosk
      ? `
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1 0 0;
  `
      : props.hasMoreThan6ORs
      ? `
        flex: 0 0 auto;
        width: 16.3%;
      `
      : `flex: 1 0 0;`}

  ${responsive.md.andSmaller`
    margin: 0;
    padding: 0;
    overflow: initial;
    width: 100vw;
    flex: 1 0 auto;
    min-height: auto;

    scroll-snap-align: center;

    ${props => (props.current ? null : 'display: none;')}
  `};
`;

const EmptyColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  text-align: center;
  opacity: 0.2;
  padding: 1em;
`;

const RoomNameError = styled(RoomName)`
  color: #ff8900;
  opacity: 1;
`;

const indicatorStyles = {
  margin: '0.5em',
  width: '4rem',
  height: '4rem',
  borderWidth: '2px',
};

const size = ({ lg, md }) => {
  if (lg) {
    return '1.3333em';
  } else if (md) {
    return '1em';
  } else {
    return '1.3333em';
  }
};

export const RoomHeader = styled.div`
  display: flex;
  justify-content: ${props => (props.onlyRoomName ? 'center' : 'space-between')};
  align-items: center;
  margin-bottom: 0.5em;
  color: #abe3ff;
  > span {
    opacity: 0.5;
  }
  ${responsive.md.andSmaller`
    display: none;
  `};

  font-size: ${props => size(props)};
`;

const Overview = ({
  hospitalId,
  currentOR,
  isKiosk,
  operationRooms,
  canUseScheduleViewProcedure,
  isFrontDesk,
  isPowerUser,
  isPreOPNurse,
  date,
  setEditProcedure,
  unassignedProcedures,
  editableStaff,
  showBedNumber,
  scheduleStaffList,
  openProcedureForm,
  roomId,
  condensView,
}) => {
  const scope = useScope();
  const createProcedureSchedule = scope?.hospital?.modules?.createProcedureSchedule;
  const isMobile = window.innerWidth < breakpoint.md;

  const canAddStaff = !isKiosk && !isFrontDesk && !isPreOPNurse && editableStaff;
  const editableAnesthesiologist = !isKiosk && !isFrontDesk && editableStaff;

  const hasMoreThan6ORs = isKiosk ? false : operationRooms.length + (!isEmpty(unassignedProcedures) ? 1 : 0) > 6;
  const classes = useStylesOverview();

  return (
    <>
      <Columns isKiosk={isKiosk}>
        {operationRooms.map((room, i) => {
          const { id, name, procedures, staffShifts } = room;
          const anesthesiologistName = fullNameToLastNameRest(
            room?.anesthesiologistShifts?.[0]?.anesthesiologist?.name
          );
          const anesthesiologistShiftRoomId = room?.anesthesiologistShifts?.[0]?.id;

          const proceduresInRoom = procedures?.filter(procedure => procedure?.orId === room?.id);

          return (
            <Box
              key={id || name}
              id={name}
              className={clsx(classes.column, {
                [classes.current]: currentOR === name || (!currentOR && i === 0) ? 'true' : null,
                [classes.kiosk]: isKiosk,
                [classes.hasMoreThan6ORs]: hasMoreThan6ORs,
              })}
            >
              <StaffShiftRoomContext
                hospitalId={hospitalId}
                roomId={id}
                date={format(date, 'YYYY-MM-DD')}
                staffShifts={staffShifts || []}
              >
                <StaffSlotRoomContext hospitalId={hospitalId} roomId={id} date={format(date, 'YYYY-MM-DD')}>
                  <div>
                    <Box className={clsx(classes.roomHeader)} onlyRoomName={!(canAddStaff && id !== undefined)}>
                      <Box display="flex" flex={1}>
                        {canAddStaff && <Box>{!!id && <MultipleSelectionCheckbox roomId={id} />} </Box>}
                        {!!id && canAddStaff && (
                          <StaffShiftMenu>{onClick => <StaffShiftMenuButton onClick={onClick} />}</StaffShiftMenu>
                        )}
                      </Box>
                      <Box flex={2} textAlign="center">
                        <Typography color="textSecondary" style={{ fontSize: 'inherit', fontFamily: 'inherit' }}>
                          {name}
                        </Typography>
                      </Box>
                      <Box flex={1}>
                        {canAddStaff && (
                          <Box mr={1}>
                            {!!id && (
                              <Anesthesiologist
                                date={date}
                                roomId={id}
                                condensView={condensView}
                                editableAnesthesiologist={editableAnesthesiologist}
                                anesthesiologistName={anesthesiologistName}
                                anesthesiologistShiftRoomId={anesthesiologistShiftRoomId}
                                procedureQuery={proceduresInRoom}
                              />
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>

                    {isKiosk && (
                      <Box display="flex" flex={1} flexDirection="row" justifyContent="center">
                        <AirFiltrationIndicatorTimer roomId={id} style={indicatorStyles} radius={32} />
                      </Box>
                    )}

                    <Box display={'flex'} flexDirection={'row'} mb={1} ml={isMobile ? 1 : 0}>
                      {canAddStaff && isMobile ? (
                        <Box pr={1}>
                          {!!id && (
                            <StaffShiftMenu>{onClick => <StaffShiftMenuButton onClick={onClick} />}</StaffShiftMenu>
                          )}
                        </Box>
                      ) : null}
                      {(staffShifts || []).length !== 0 && (
                        <StaffShifts staffShifts={staffShifts || []} editableStaff={editableStaff} isKiosk={isKiosk} />
                      )}
                    </Box>
                    {!!id && createProcedureSchedule && canUseScheduleViewProcedure && (
                      <Button
                        color="primary"
                        onClick={() => {
                          openProcedureForm(undefined, operationRooms, room, date);
                        }}
                      >
                        Add Procedure
                      </Button>
                    )}
                  </div>
                  {procedures.length > 0 ? (
                    isKiosk ? (
                      <CondensedProcedures
                        hospitalId={hospitalId}
                        operationRooms={operationRooms}
                        operationRoom={room}
                        procedures={procedures}
                        isUnassigned={false}
                        date={date}
                        canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                        setEditProcedure={setEditProcedure}
                        openProcedureForm={openProcedureForm}
                        isKiosk={isKiosk}
                        editableStaff={canAddStaff && editableStaff}
                        editableAnesthesiologist={editableAnesthesiologist}
                        showBedNumber={showBedNumber}
                        totalRoomNumber={operationRooms?.length}
                      />
                    ) : (
                      <Procedures
                        hospitalId={hospitalId}
                        operationRooms={operationRooms}
                        operationRoom={room}
                        procedures={procedures}
                        isUnassigned={false}
                        date={date}
                        canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                        setEditProcedure={setEditProcedure}
                        openProcedureForm={openProcedureForm}
                        isKiosk={isKiosk}
                        editableStaff={canAddStaff && editableStaff}
                        editableAnesthesiologist={editableAnesthesiologist}
                        showBedNumber={showBedNumber}
                        totalRoomNumber={operationRooms?.length}
                      />
                    )
                  ) : (
                    <EmptyColumn>
                      <img
                        src={dateRange}
                        alt={'logo'}
                        style={{
                          fontSize: '4em',
                          width: '1em',
                          height: '1em',
                          margin: '0 auto .125em',
                          opacity: '.35',
                        }}
                      />
                      <span>
                        There are no procedures in {name} {isToday(date) ? 'for today' : 'on this day'}.
                      </span>
                    </EmptyColumn>
                  )}
                </StaffSlotRoomContext>
              </StaffShiftRoomContext>
            </Box>
          );
        })}
        {!isEmpty(unassignedProcedures) && (
          <Column key={0} isUnassigned hasMoreThan6ORs={hasMoreThan6ORs}>
            <RoomNameError>OR Unassigned</RoomNameError>
            <Procedures
              hospitalId={hospitalId}
              procedures={unassignedProcedures}
              isUnassigned={true}
              date={date}
              isPowerUser={isPowerUser}
              setEditProcedure={setEditProcedure}
              openProcedureForm={openProcedureForm}
              showBedNumber={showBedNumber}
              editableAnesthesiologist={editableAnesthesiologist}
              canUseScheduleViewProcedure={canUseScheduleViewProcedure}
            />
          </Column>
        )}
        {scheduleStaffList}
      </Columns>
    </>
  );
};

export const useStylesOverview = makeStyles(theme => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: `'Roboto Condensed', sans-serif`,
    backgroundColor: alpha(theme.palette.text.primary, 0.07),
    margin: '0 0.1875em',
    borderRadius: '0.3333em',
    padding: '0.66666em 0.375em 0',
    minHeight: 'min-content',
    overflow: 'hidden',
    flex: 1,

    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 0,
      overflow: 'initial',
      width: '100vw',
      flex: '1 0 auto',
      minHeight: 'auto',
      scrollSnapAlign: 'center',
      display: 'none',
    },
  },
  kiosk: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    flex: '1 0 0',
  },
  hasMoreThan6ORs: {
    flex: '0 0 auto',
    width: '16.3%',
  },
  current: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flex: 1,
    },
  },
  roomHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5em',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default Overview;
