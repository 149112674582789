import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles';
import { toBooleanPositive, toBooleanNegative, toMale, toFemale, bmiLessThanOrEqual35, bmiGreaterThan35, ageOver50Yes, ageOver50No, neckSizeYes, neckSizeNo, neckSizeNotSure } from '../../../../../../questionnaire/fallRiskReducers';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import getAgeAndBmi from './pageUtils';
import { useValue } from '../../ValueContext';
import FallRiskCheckbox from '../../components/FallRiskCheckbox';
import { get } from 'lodash';

function initializeValues(rootRef, setChecked) {
    const root = rootRef.current;

    if (!root) {
        return;
    }

    const checkboxes = root.querySelectorAll('input[type="checkbox"');

    setChecked(
        [...checkboxes].reduce((acc, checkbox) => {
            const value = checkbox.checked ? parseInt(checkbox.value) : 0;
            return { ...acc, [checkbox.name]: isFinite(value) ? value : 0 };
        }, {})
    );
}

const Page14 = ({ showQRCode, hospitalName, value, defaultValue, lastPreOpCompletedEvent, ...props }) => {
    const providerId = defaultValue?.procedure?.patient?.providerId;
    const { questionnaireAnswers } = useValue();

    const pulmonary = get(questionnaireAnswers, 'sections.Pulmonary');
    const neckNA = get(questionnaireAnswers, 'sections.GeneralHistory.schema.neck')?false:true
    
    let snore = ['Pulmonary', 'Sleep apnea', 'no', 'snore'];
        let breathing = ['Pulmonary', 'Sleep apnea', 'no', 'breathing']
        let tired = ['Pulmonary', 'Sleep apnea', 'no', 'tired']
        const sleepApneaYN = get(questionnaireAnswers, 'sections.Pulmonary.schema.Sleep apnea.yes[0]');
        if (sleepApneaYN) {
            snore = ['Pulmonary', 'Sleep apnea']
            breathing = ['Pulmonary', 'Sleep apnea']
            tired = ['Pulmonary', 'Sleep apnea']
        }
    if (pulmonary == undefined ) {
        console.log('p_pulmonary')
        snore = ['P_Pulmonary', 'Sleep apnea', 'no', 'snore'];
        breathing = ['P_Pulmonary', 'Sleep apnea', 'no', 'breathing']
        tired = ['P_Pulmonary', 'Sleep apnea', 'no', 'tired']
        const sleepApneaYN = get(questionnaireAnswers, 'sections.P_Pulmonary.schema.Sleep apnea.yes[0]');
        if (sleepApneaYN) {
            snore = ['P_Pulmonary', 'Sleep apnea']
            breathing = ['P_Pulmonary', 'Sleep apnea']
            tired = ['P_Pulmonary', 'Sleep apnea']
        }
    }

    const [values, setValues] = useState({});

    const total = useMemo(() => Object.values(values).reduce((acc, value) => acc + value, 0), [values]);

    const rootSARef = useRef(undefined);

    useEffect(() => {
        initializeValues(rootSARef, setValues);
    }, []);

    const handleCheckboxChange = setValues => (value, name) => {
        setValues(prev => ({ ...prev, [name]: value }));
    };

    const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);


    function HighRisk(value) {
        if (value >= 3) {
            return true;
        }
        return false;
    }
    return (
        <div id="page14" className="page" style={{ height: '100%', paddingBottom: '0' }}>
            <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
                <div ref={rootSARef}>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '50% 50%',
                            gridGap: '1rem',
                            alignContent: 'start',
                            marginBottom: '1rem',
                            paddingTop: '1rem',
                            fontSize: '14px',
                        }}
                    >
                        <div style={styles.div}>
                            <b>
                                <div style={{ fontSize: '12px', paddingBottom: '.2rem' }}>{(hospitalName || '').toUpperCase()}</div>
                            </b>
                            <b>
                                <div style={{ fontSize: '20px', paddingBottom: '.5rem' }}>Screening for Risk of Obstructive Sleep Apnea</div>
                            </b>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                            }}
                        >
                            <div>
                                {showQRCode && (
                                    <img
                                        alt="Barcoded value"
                                        src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
                                    />
                                )}
                            </div>
                            <div
                                style={{
                                    fontSize: '12px',
                                    border: '1px solid black',
                                    padding: '0.2rem',
                                    lineHeight: '15px',
                                }}
                            >
                                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} nobackground />
                                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '40% 60%',
                                        alignContent: 'start',
                                    }}
                                >
                                    <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} nobackground />
                                    <MedicalPassportField
                                        name="age"
                                        label="Age"
                                        path={['demographicInformation', 'dob']}
                                        reducer={toAge}
                                        warning={age > 70}
                                        fontSize={12}
                                        nobackground
                                    />
                                    <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                                    <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12} nobackground />
                                </div>
                                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: '85% 7% 7%',
                        alignContent: 'start',
                        marginBottom: '1rem',
                        paddingTop: '1rem',
                        fontSize: '15px',
                    }}>
                        <div style={{ borderBottom: '1px solid black' }}></div>
                        <div style={{ borderBottom: '1px solid black', textAlign: 'center' }}><b>YES</b></div>
                        <div style={{ borderBottom: '1px solid black', textAlign: 'center' }}><b>NO</b></div>
                        <div style={{ padding: '1rem 0' }}><b>SNORING:</b> Do you snore loudly {'('}louder than talking or loud enough to be heard through closed doors{')'}?</div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="snoreYes"
                                value={1}
                                path={snore}
                                reducer={toBooleanPositive}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="snoreNo"
                                value={0}
                                path={snore}
                                reducer={toBooleanNegative}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem 0' }}><b>TIREDNESS/FATIGUE:</b> Do you often feel tired, fatigued or sleepy during the day?</div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="tiredYes"
                                value={1}
                                path={tired}
                                reducer={toBooleanPositive}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="tiredNo"
                                value={0}
                                path={tired}
                                reducer={toBooleanNegative}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem 0' }}><b>OBSERVED APNEA:</b> Has anyone observed you stop breathing during your sleep?</div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="breathingYes"
                                value={1}
                                path={breathing}
                                reducer={toBooleanPositive}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="breathingNo"
                                value={0}
                                path={breathing}
                                reducer={toBooleanNegative}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem 0' }}><b>BLOOD PRESSURE:</b> Do you have or are you being reated for high blood pressure?</div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="hbpYes"
                                value={1}
                                path={['Cardiac', 'HighBloodPressure']}
                                reducer={toBooleanPositive}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="hbpNo"
                                value={0}
                                path={['Cardiac', 'HighBloodPressure']}
                                reducer={toBooleanNegative}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem 0' }}><b>BODY MASS INDEX:</b> BMI of more than 35 kg/m2 ?</div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="bmiYes"
                                value={1}
                                path={['basicscreening', 'bmi']}
                                reducer={bmiGreaterThan35}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem' }}   >
                            <FallRiskCheckbox
                                name="bmiNo"
                                value={0}
                                path={['basicscreening', 'bmi']}
                                reducer={bmiLessThanOrEqual35}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem 0' }}><b>AGE:</b> Over 50 years old?</div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="ageYes"
                                value={1}
                                path={['demographicInformation', 'dob']}
                                reducer={ageOver50Yes}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="ageNo"
                                value={0}
                                path={['demographicInformation', 'dob']}
                                reducer={ageOver50No}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{
                            padding: '1rem 0',
                            display: 'grid',
                            gridTemplateColumns: '40% 10% 50%'
                        }}>
                            <div><b>NECK SIZE:</b> Greater than 15-3/4 inches?</div>
                            <div><input type='checkbox' name="necksizeNA" checked={neckNA}/> N/A</div>
                            <div><FallRiskCheckbox
                                name="necksizenotsure"
                                label="Not Sure"
                                value={0}
                                path={['GeneralHistory', 'neck']}
                                reducer={neckSizeNotSure}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            /></div>

                        </div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="necksizeyes"
                                value={1}
                                path={['GeneralHistory', 'neck']}
                                reducer={neckSizeYes}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="necksizeno"
                                value={0}
                                path={['GeneralHistory', 'neck']}
                                reducer={neckSizeNo}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem 0' }}><b>GENDER:</b> Male?</div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="malepYes"
                                value={1}
                                path={['demographicInformation', 'sex']}
                                reducer={toMale}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <FallRiskCheckbox
                                name="maleNo"
                                value={0}
                                path={['demographicInformation', 'sex']}
                                reducer={toFemale}
                                onCheckboxChange={handleCheckboxChange(setValues)}
                            />
                        </div>
                    </div>
                    <br />
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '84% 16%',
                            fontSize: '15px',
                            fontWeight: 'bold'
                        }}
                    >
                        <div
                            style={{
                                marginTop: '15px',
                                textAlign: 'right'
                            }}
                        >
                            TOTAL SCORE:
                        </div>
                        <MedicalPassportField
                            name="total"
                            style={{
                                marginTop: '15px',
                            }}
                            fontWeight={'bold'}
                            fontSize={15}
                            value={total}
                            nobackground
                        />

                    </div>
                    <br /><br /><br />
                    <div style={{ fontSize: '15px' }}><b>HIGH Risk of OSA: Answering yes to 3 or more items then:</b></div>
                    <div style={{ paddingLeft: '10%', fontSize: '15px' }}>
                        <input
                            type="checkbox"
                            name="highRiskOSA"
                            checked={HighRisk(total)}
                        />
                        <b> Initiate the HIGH RISK for OSA Protocol</b>
                    </div>
                    <br />
                    <div style={{ fontSize: '15px' }}><b>LOW Risk of OSA:</b> Answering yes to less than 3 items</div>
                </div>
            </div>
            <footer style={{ display: 'grid', gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%', fontSize: '11px', height: '20px' }}>
                <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
                <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
                <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
                <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
                <div style={{ paddingTop: '3px' }}>{'('}</div>
                <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
                <div>{')'} <span style={{ fontSize: '14px', marginLeft: '9rem' }}><b>Ospitek, Inc</b></span></div>
            </footer>
        </div>
    );
};

export default Page14;
