import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import merge from 'lodash/merge';
import update from 'lodash/update';
import LinkButton from '../../../../../se/components/LinkButton';
import Button from '../../../../../se/components/Button';
// @ts-ignore
import waiting from '../../../../../assets/images/waiting.svg';
import { STATUSES as ROOM_STATUSES } from '../../../../entities/room/enums';
import { getPatientProcedureDuration, transformORPatients } from '../../../../entities/patient/transducers';
import { mapTabletOperationRoom } from '../../../../entities/room/transducers';
import { format } from 'date-fns';

import { opreationRoomSubscription, setPatientStatus } from '../../../../../graph/rooms';
import {
  exitProcedure as exitProcedureMutation,
  setRoom as setRoomForPatientMutation,
} from '../../../../../graph/patients';
import ClientUpdater from '../../../../ClientUpdater';
import HelperAction from '../HelperAction';
import { CenteredSpinner } from '../../../../../se/components/Spinner';
import { AirFiltrationIndicatorTimer } from '../../widgets/AirFiltrationIndicator';
import { Modal, ModalAction, Subtitle, Title } from '../Modal';
import PatientSelection from '../../nursingApp/PatientSelection';
import { getProcedures as getProcedureCards } from '../../schedule/transform';
import { useMutation, useSubscription } from '@apollo/client';
import { listSubscription as proceduresSubscription } from '../../../../../graph/procedures';
import UpcomingProcedure from '../../schedule/overview/Procedure';
import Box from '@material-ui/core/Box';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { getNextScheduledProcedures } from '../procedures';
import { getRemainingScheduledProcedures } from '../proceduresRemaining';
import { Alert } from '@material-ui/lab';
import { Patient, PatientStatus } from '../../../../../types/Patient';
import { orTabletProcedureSteps } from '../../../../../graph/procedureSteps';
import { RouteComponentProps } from 'react-router';
import { Room } from '../../../../../types/Room';
import CurrentProcedure from './CurrentProcedure';
import { tabletThemeDark } from '../../../../../themes/tabletTheme';

const MessageToSend = styled.div`
  position: relative;
  padding: 1em 1.5em;
  border-radius: .25em;
  margin-bottom: 4rem;
  font-size: 1.75rem;
  line-height: 1.5;
  background-color: ${props => props.theme.textColor.alpha(0.2).string()};
  max-width: 30em;
  text-align: left;
  white-space: pre-wrap;

  :before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid ${props => props.theme.textColor.alpha(0.2).string()};
    border-top: 10px solid ${props => props.theme.textColor.alpha(0.2).string()};
    border-bottom: 10px solid transparent;
    right: 19px;
    bottom: -20px;
  }
}
`;

const Root = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

export const Idle = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  grid-column: 1 / span 2;
`;

export const Illustration = styled.img`
  max-width: 6rem;
`;

export const Supertitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1.5rem;
  opacity: 0.5;

  @media (min-width: 50rem) {
    font-size: 1.25rem;
  }
`;

const SubtleLink = styled(LinkButton)`
  color: inherit;
  opacity: 0.5;
  margin-bottom: 0.5rem;
`;

export const CustomModal =
  (message = '', yesPrimary = true, yesLabel = '', yesSecondaryLabel = '', noLabel = '') =>
  ({
    handleConfirm,
    handleSecondaryConfirm,
    handleCancel,
    working,
    sendTo,
    messageToSend,
    errorMessage,
    children,
  }: any) =>
    (
      <Modal
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ overflowY: 'unset', fontWeight: 400 }}
      >
        <Title>Are you sure?</Title>
        <Subtitle>
          {message}{' '}
          {!!sendTo && (
            <span>
              <br />
              {sendTo}
            </span>
          )}
        </Subtitle>
        {!!messageToSend && <MessageToSend>{messageToSend}</MessageToSend>}
        {children}
        <ModalAction>
          <Button xl primary={yesPrimary} onClick={handleConfirm} disabled={working}>
            <WithWorking working={working}>{yesLabel || 'Yes'}</WithWorking>
          </Button>
          {handleSecondaryConfirm && (
            <Button xl primary={yesPrimary} onClick={handleSecondaryConfirm} disabled={working}>
              <WithWorking working={working}>{yesSecondaryLabel || 'Yes'}</WithWorking>
            </Button>
          )}
          <Button xl primary={!yesPrimary} onClick={handleCancel} disabled={working}>
            {noLabel || 'No'}
          </Button>
        </ModalAction>
        {!!errorMessage && (
          <Alert severity="error" style={{ marginTop: 40 }}>
            {errorMessage}
          </Alert>
        )}
      </Modal>
    );

const ExitModal = CustomModal('Current procedure progress will be lost. This action cannot be reversed.');
const ClosingModal = CustomModal(
  'This action will start procedure closing and send SMS to the patient’s caretaker.',
  true
);

export const useStyles = makeStyles(theme => ({
  dialogContent: {
    background: theme.palette.background.default,
    fontSize: '1.5em',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export const WithWorking = ({
  working,
  children,
  size = null,
}: {
  size?: any;
  working: boolean;
  children: ReactNode;
}) => (
  <Box display="grid" alignItems="center">
    <Box gridRow={1} gridColumn={1} visibility={working ? 'visible' : 'hidden'} marginTop="-1.25em">
      <CenteredSpinner size={size} />
    </Box>
    <Box gridRow={1} gridColumn={1} visibility={working ? 'hidden' : 'visible'}>
      {children}
    </Box>
  </Box>
);

const ActionWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 2rem;
`;

const OperationRoomTabletV2 = ({ match }: RouteComponentProps<{ operationRoomId: string }>) => {
  const roomId = parseInt(match?.params?.operationRoomId, 10);

  const [modal, setModal] = useState<boolean>(false);
  const [closingModal, setClosingModal] = useState<boolean>(false);
  const [patientsModal, setPatientsModal] = useState<boolean>(false);

  const [setRoomForPatient] = useMutation(setRoomForPatientMutation);
  const [exitProcedure] = useMutation(exitProcedureMutation);

  const roomData = useSubscription(opreationRoomSubscription, {
    variables: {
      roomId,
    },
  });

  const room: Room & { status: string; patient: Patient & { duration?: string } } = useMemo(() => {
    const transformedRoom = transformORPatients(roomData?.data?.tabletApp || {});
    const update1 = update(transformedRoom, 'patient', patient =>
      merge(patient, getPatientProcedureDuration(patient, transformedRoom))
    );
    const update2 = mapTabletOperationRoom(update1);
    return {
      ...update2,
      status: !update2.patient ? null : update2.status,
    };
  }, [roomData]);

  const proceduresData = useSubscription(proceduresSubscription, {
    variables: {
      date: format(new Date(), 'YYYY-MM-DD'),
      operationRoomId: roomId,
      isCanceled: false,
    },
  });

  const [nextProcedure, nextProceduresInOperationRoom] = useMemo(
    () => getNextScheduledProcedures(getProcedureCards(proceduresData) || []),
    [proceduresData]
  );

  const [nextRemainingProcedure, nextRemainingProceduresInOperationRoom] = useMemo(
    () => getRemainingScheduledProcedures(getProcedureCards(proceduresData) || []),
    [proceduresData]
  );

  const [working, setWorking] = useState<boolean>(false);

  const withWorking = (fn: any) => async (args: any) => {
    if (fn) {
      setWorking(true);
      await fn(args);
      setWorking(false);
    }
  };

  const { patient, status } = room || {};

  const movePatientToOR = (roomId: number) => (patientId: number) => () =>
    setRoomForPatient({ variables: { id: patientId, roomId } });

  const [setStatus] = useMutation(setPatientStatus);

  const changeStatus = async (status: PatientStatus) => {
    status &&
      (await setStatus({
        variables: {
          id: patient?.id,
          status,
        },
        refetchQueries: [{ query: orTabletProcedureSteps, variables: { patientId: patient?.id } }],
      }));
  };

  const notifyPhysician = async () => {
    await changeStatus(PatientStatus.Ready);
  };

  const manuallyExitProcedure = (id?: number) => async () => {
    try {
      id && (await exitProcedure({ variables: { id } }));
    } catch (error) {
      console.error(error);
    } finally {
      setModal(false);
    }
  };

  const startProcedureClosing = async () => {
    try {
      await changeStatus(PatientStatus.Closing);
    } catch (error) {
      console.error(error);
    } finally {
      setClosingModal(false);
    }
  };

  const currentProcedureExists = [
    PatientStatus.InOr,
    PatientStatus.AnestheticStart,
    PatientStatus.ReadyForSurgery,
    PatientStatus.Ready,
    PatientStatus.TimeOut,
    PatientStatus.TimeOut2,
    PatientStatus.Ongoing,
    PatientStatus.CallNextPatient,
    PatientStatus.Closing,
    PatientStatus.SurgeonLeftOR,
    PatientStatus.DressingOn,
    PatientStatus.ProcedureEnd,
    PatientStatus.AnestheticEnd,
    ROOM_STATUSES.CLEANING,
  ].includes(status);

  const classes = useStyles();

  return (
    <ThemeProvider theme={tabletThemeDark}>
      {patientsModal ? (
        <Root>
          <PatientSelection
            handleCancel={() => setPatientsModal(false)}
            handlePatientClick={movePatientToOR(roomId)}
            style={{ overflow: 'auto' }}
            withRoom={true}
          />
        </Root>
      ) : !status ? (
        <Root>
          <Idle>
            <AirFiltrationIndicatorTimer roomId={roomId} style={{ position: 'absolute', left: '3rem', top: '2rem' }} />
            <ActionWrapper>
              <HelperAction />
            </ActionWrapper>
            <Illustration src={waiting} />
            <Supertitle>{room.name}</Supertitle>
            <Title style={{ margin: 0 }}>Waiting for patient</Title>
            <Subtitle style={{ marginBottom: '1em' }}>
              Once the patient enters the room, this screen will automatically show the controls.
            </Subtitle>

            {nextProcedure && (
              <>
                <Divider />

                <Box display="flex" flexDirection="column" justifyContent="center" p={2} width="100%">
                  <Fragment>
                    <Box mb={2}>
                      <Typography align="center" variant="h6" gutterBottom>
                        Upcoming Procedure
                      </Typography>
                    </Box>
                    <Box display="block" className={classes.dialogContent} width="100%">
                      {/* @ts-ignore */}
                      <UpcomingProcedure
                        procedure={nextProcedure}
                        isUnassigned={false}
                        date={new Date()}
                        isPatientIconVisible={true}
                      />
                    </Box>
                  </Fragment>
                </Box>
              </>
            )}

            <SubtleLink onClick={() => setPatientsModal(true)}>Patient not Detected? Tap Here.</SubtleLink>
          </Idle>
        </Root>
      ) : modal ? (
        <Root>
          <ExitModal
            handleConfirm={withWorking(manuallyExitProcedure(patient?.id))}
            handleCancel={() => setModal(false)}
            working={working}
          />
        </Root>
      ) : closingModal ? (
        <Root>
          <ClosingModal
            handleConfirm={startProcedureClosing}
            handleCancel={() => setClosingModal(false)}
            working={working}
          />
        </Root>
      ) : currentProcedureExists ? (
        <CurrentProcedure
          nextProcedure={nextProcedure}
          roomId={roomId}
          room={room}
          working={working}
          withWorking={withWorking}
          nextProceduresInOperationRoom={nextProceduresInOperationRoom}
          nextRemainingProceduresInOperationRoom={nextRemainingProceduresInOperationRoom}
          notifyPhysician={notifyPhysician}
          loading={proceduresData.loading}
        />
      ) : null}
      {!patientsModal && !status && <ClientUpdater />}
    </ThemeProvider>
  );
};

OperationRoomTabletV2.defaultProps = {
  room: {},
};

OperationRoomTabletV2.propTypes = {
  room: PropTypes.object.isRequired,
};

export default OperationRoomTabletV2;
