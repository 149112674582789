import { Subscription } from '@apollo/client/react/components';
import { Box, CssBaseline, Typography } from '@material-ui/core';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { Redirect, Route, Switch } from 'react-router-dom';
import { tabletThemeLight } from '../../../themes/tabletTheme';
import GridFader from '../../core/GridFader';
import Monitor from './Monitor';
import WaitingRoomMonitor from './WaitingRoomMonitor';
import RoomTabletAppFactory from './RoomTabletApp';
import Schedule from './SchedulePage';
import { screenConfiguration } from '../../../graph/screens';
import { getNestedValue } from '../../../se/utilities/data/object';
import ConnectionProvider from './ConnectionProvider';
import BlockSchedulePage from '../blockSchedule/BlockSchedulePage';
import ReviewsPage from './reviews/ReviewsPage';
import ExitPage from './exit/ExitPage';
import { ROOM_TYPES } from '../../entities/room/enums';
import MultipleCalendarPage from '../calendar/MultipleCalendarPage';
import ChartingProfile from './charting/screens/ChartingProfile';
import { SetChartingPinTimeout } from './charting/Charting';
import ChartingPage from './charting/ChartingPage';
import { get } from 'lodash/fp';
import { QuestionnaireType } from '../../../types/Questionnaire';
import DarkThemeProviderWrapper from '../../../DarkThemeProviderWrapper';
import WhiteThemeProviderWrapper from '../../../WhiteThemeProviderWrapper';
import ChartingTimeout from './charting/ChartingTimeout';
import OperationRoomTablet from './tablet/OperationRoomTablet';
import Vitals from '../inject/Vitals';
import VitalsSignature from '../inject/VitalsSignature';
import WaitingRoomTablet from './tablet/waitingRoom/WaitingRoomTablet';
import StaffingCostPage from './schedule/staff/StaffingCostPage';
import AnesthesiaTable from '../inject/AnesthesiaTable';
import AnesthesiaVitals from '../inject/AnesthesiaVitals';
import Dictation from './charting/screens/Dictation';
import { ScopeProvider } from '../../../contexts/ScopeContext';
import AnalyticsScreen from '../analytics/AnalyticsScreen';
import LinkButton from '../../../se/components/LinkButton';
import { muiThemeDark, muiThemeLight } from '../../../muiTheme';
import { ThemeProvider } from 'styled-components';
import { dark as darkTheme, light as lightTheme } from '../../../theme';

const defaultConfig = {
  showProcedure: true,
  dischargeSound: true,
  orReadySound: true,
  waitingReadySound: true,
  pacuReadySound: true,
  prepReadySound: true,
  readyForSurgeonSound: true,
  readyToPickupSound: true,
  holdProcedureSound: true,
  blockNerveSound: true,
  useCssGridLayout: false,
  showPostOp: true,
  chartingPinTimeout: 60,
  questionnaireSessionTimeout: 30,
  showRideHome: false,
  showPreOpWr: true,
  showIntakeWr: true,
  helpSound: true,
  closingSound: false,
};

export const PrepTabletApp = RoomTabletAppFactory(ROOM_TYPES.PRE_OP);
export const PacuTabletApp = RoomTabletAppFactory(ROOM_TYPES.PACU);
export const PostOpTabletApp = RoomTabletAppFactory(ROOM_TYPES.POST_OP);

const ScreenRouter = ({ match, location, accessToken, organizationId, onUnauthorized, path, isHuman, inject }) => {
  const queryParams = queryString.parse(location.search);
  const [unauthorized, setUnauthorized] = useState(false);

  const handleUnauthorized = error => {
    if (error === 'Unauthorized IP Address') {
      setUnauthorized(true);
    } else {
      onUnauthorized();
    }
  };

  return unauthorized ? (
    <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h4" component="h1" gutterBottom>
        Unauthorized IP address
      </Typography>
      <LinkButton onClick={() => window.location.reload(true)}>Refresh</LinkButton>
    </Box>
  ) : (
    <ConnectionProvider accessToken={accessToken} organizationId={organizationId} onUnauthorized={handleUnauthorized}>
      <ScopeProvider>
        <Route path={`${match.path}/charting`} component={ChartingTimeout}/>
        <Subscription subscription={screenConfiguration}>
          {({ data }) => {
            const screenConfiguration = getNestedValue('screenConfiguration', data);
            const configuration = isHuman ? defaultConfig : screenConfiguration;

            return (
              <>
                <SetChartingPinTimeout value={configuration?.chartingPinTimeout}/>

                {inject ? (
                  <Switch component={GridFader}>
                    <Route
                      path={`${match.path}/anesthesia-table/:patientId`}
                      render={props => {
                        const patientId = parseInt(get('params.patientId')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <AnesthesiaTable hospitalId={organizationId} patientId={patientId}/>
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/anesthesia-vitals/:patientId`}
                      render={props => {
                        const patientId = parseInt(get('params.patientId')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <AnesthesiaVitals hospitalId={organizationId} patientId={patientId}/>
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/vitals/:patientId`}
                      render={props => {
                        const patientId = parseInt(get('params.patientId')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <Vitals hospitalId={organizationId} patientId={patientId}/>
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/vitals-signature/:patientId`}
                      render={props => {
                        const patientId = parseInt(get('params.patientId')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <VitalsSignature hospitalId={organizationId} patientId={patientId}/>
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                  </Switch>
                ) : (
                  <Switch component={GridFader}>
                    <Route
                      path={`${match.path}/monitor`}
                      render={() => {
                        const isLightMode = configuration?.lightMode || false;

                        if (isLightMode) {
                          return (
                            <ThemeProvider theme={lightTheme}>
                              <MUIThemeProvider theme={muiThemeLight}>
                                <CssBaseline/>
                                <Monitor
                                  accessToken={accessToken}
                                  version={queryParams.version}
                                  configuration={configuration}
                                />
                              </MUIThemeProvider>
                            </ThemeProvider>
                          );
                        }

                        return (
                          <DarkThemeProviderWrapper>
                            <Monitor
                              accessToken={accessToken}
                              version={queryParams.version}
                              configuration={configuration}
                            />
                          </DarkThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/waiting-room`}
                      render={() => (
                        <DarkThemeProviderWrapper>
                          <WaitingRoomMonitor version={queryParams.version} configuration={configuration}/>
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/nursing/prep/:roomId`}
                      render={props => (
                        <DarkThemeProviderWrapper>
                          <PrepTabletApp {...props} />
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/nursing/pacu/:roomId`}
                      render={props => (
                        <DarkThemeProviderWrapper>
                          <PacuTabletApp {...props} />
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/nursing/post-op/:roomId`}
                      render={props => (
                        <DarkThemeProviderWrapper>
                          <PostOpTabletApp {...props} />
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/tablet/:operationRoomId`}
                      render={props => (
                        <DarkThemeProviderWrapper>
                          <OperationRoomTablet {...props} />
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/block-schedule`}
                      render={props => (
                        <DarkThemeProviderWrapper>
                          <BlockSchedulePage {...props} organizationId={organizationId}/>
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/calendar`}
                      render={props => (
                        <DarkThemeProviderWrapper>
                          <MultipleCalendarPage {...props} />
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route path={`${match.path}/schedule/staffing-cost`} component={StaffingCostPage}/>
                    <Route
                      path={`${match.path}/schedule`}
                      render={() => (
                        <DarkThemeProviderWrapper>
                          <Schedule isKiosk={!isHuman} config={screenConfiguration}/>
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/analytics`}
                      render={() => (
                        <DarkThemeProviderWrapper>
                          <AnalyticsScreen/>
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/charting/preop-chart/:id`}
                      render={props => {
                        const patientId = parseInt(get('params.id')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <ChartingProfile
                              {...props}
                              hospitalId={organizationId}
                              patientId={patientId}
                              questionnaireType={QuestionnaireType.PreOpChart}
                            />
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/charting/or-chart/:id`}
                      render={props => {
                        const patientId = parseInt(get('params.id')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <ChartingProfile
                              {...props}
                              hospitalId={organizationId}
                              patientId={patientId}
                              questionnaireType={QuestionnaireType.OrChart}
                            />
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/charting/pacu-chart/:id`}
                      render={props => {
                        const patientId = parseInt(get('params.id')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <ChartingProfile
                              {...props}
                              hospitalId={organizationId}
                              patientId={patientId}
                              questionnaireType={QuestionnaireType.PacuChart}
                            />
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/charting/physician-chart/:id`}
                      exact={true}
                      render={props => {
                        const patientId = parseInt(get('params.id')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <ChartingProfile
                              {...props}
                              hospitalId={organizationId}
                              patientId={patientId}
                              questionnaireType={QuestionnaireType.PhysicianChart}
                            />
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/charting/physician-chart/:id/dictation`}
                      render={props => {
                        const patientId = parseInt(get('params.id')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <Dictation
                              {...props}
                              hospitalId={organizationId}
                              patientId={patientId}
                              questionnaireType={QuestionnaireType.PhysicianChart}
                            />
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/charting/anesthesiologist-preop-chart/:id`}
                      render={props => {
                        const patientId = parseInt(get('params.id')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <ChartingProfile
                              {...props}
                              hospitalId={organizationId}
                              patientId={patientId}
                              questionnaireType={QuestionnaireType.AnesthesiologistPreOpChart}
                            />
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/charting/anesthesiologist-or-chart/:id`}
                      render={props => {
                        const patientId = parseInt(get('params.id')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <ChartingProfile
                              {...props}
                              hospitalId={organizationId}
                              patientId={patientId}
                              questionnaireType={QuestionnaireType.AnesthesiologistOrChart}
                            />
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/charting/anesthesiologist-pacu-chart/:id`}
                      render={props => {
                        const patientId = parseInt(get('params.id')(props.match), 10);

                        return (
                          <WhiteThemeProviderWrapper>
                            <ChartingProfile
                              {...props}
                              hospitalId={organizationId}
                              patientId={patientId}
                              questionnaireType={QuestionnaireType.AnesthesiologistPacuChart}
                            />
                          </WhiteThemeProviderWrapper>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/charting`}
                      render={props => (
                        <WhiteThemeProviderWrapper>
                          <ChartingPage {...props} organizationId={organizationId}/>
                        </WhiteThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/reviews`}
                      render={props => (
                        <DarkThemeProviderWrapper>
                          <ReviewsPage {...props} />
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/exit`}
                      render={props => (
                        <DarkThemeProviderWrapper>
                          <ExitPage {...props} />
                        </DarkThemeProviderWrapper>
                      )}
                    />
                    <Route
                      path={`${match.path}/waiting-room-tablet`}
                      render={() => (
                        <ThemeProvider theme={tabletThemeLight}>
                          <CssBaseline/>
                          <WaitingRoomTablet configuration={configuration}/>
                        </ThemeProvider>
                      )}
                    />
                    <Route render={() => <Redirect to={`${match.url}${path || '/monitor'}`}/>}/>
                  </Switch>
                )}
              </>
            );
          }}
        </Subscription>
      </ScopeProvider>
    </ConnectionProvider>
  );
};

export default withRouter(ScreenRouter);
