import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment StatisticsData on Statistics {
      id
      title
      unit
      value
      distribution {
        name
        value
        date
      }
    }
  `,
  groupStatistics: gql`
    fragment GroupStatisticsData on GroupStatistics {
      id
      title
      unit
      value
      data {
        name
        value
      }
    }
  `,
};

export const dashboardQuery = gql`
  query dashboard($filter: Filter) {
    dashboard(filter: $filter) {
      ...StatisticsData
    }
  }
  ${fragments.all}
`;

export const totalCancellationsQuery = gql`
  query totalCancellations($filter: Filter) {
    totalCancellations(filter: $filter) {
      ...GroupStatisticsData
    }
  }
  ${fragments.groupStatistics}
`;

export const patientJourneyQuery = gql`
  query patientJourney($filter: Filter) {
    patientJourney(filter: $filter) {
      ...GroupStatisticsData
    }
  }
  ${fragments.groupStatistics}
`;


export const dashboardQueryPrev = gql`
  query dashboardPrev($filter: Filter) {
    dashboardPrev(filter: $filter) {
      ...StatisticsData
    }
  }
  ${fragments.all}
`;

export const analyticsConfigQuery = gql`
  query analyticsConfiguration {
    analyticsConfiguration {
      monthlyReportRecipients
      weeklyReportRecipients
      totalPatientsThreshold
      waitingRoomTimeThreshold
      preopTimeThreshold
      orUtilThreshold
      orTimeThreshold
      pacuTimeThreshold
      cleaningTimeThreshold
      popiTimeThreshold
      postOpTimeThreshold
    }
  }
`;

export const analyticsReportQuery = gql`
  query detailedReport($filter: Filter) {
    detailedReport(filter: $filter)
  }
`;

export const staffingCostQuery = gql`
  query staffingCost($dateRange: DateRange!) {
    staffingCost(dateRange: $dateRange) {
      id
      staffCostSummary {
        id
        staffCostDateRange {
          id
          staffMemberName
          staffMemberTitle
          employmentType
          minutes
          hourlyRate
          cost
        }
        staffCostPerDate {
          id
          date
          staffCost {
            id
            staffMemberName
            staffMemberTitle
            employmentType
            in
            out
            minutes
            hourlyRate
            cost
          }
          totalMinutes
          totalCost
        }
        totalMinutes
        totalCost
      }
      procedureStaffCostSummary {
        id
        procedureStaffCostPerDate {
          id
          date
          totalMinutes
          actualTotalMinutes
          totalCost
          actualTotalCost
          staffCost {
            id
            physicianName
            procedure
            wheelsIn
            wheelsOut
            durationInMinutes
            actualWheelsIn
            actualWheelsOut
            actualDurationInMinutes
            staffingUtilization
            actualStaffingUtilization
            cost
            actualCost
          }
        }
      }
    }
  }
`;

export const ORUtilizationAnalyticsQuery = gql`
  query ORUtilizationAnalytics($dateRange: DateRange!) {
    ORUtilizationAnalytics(dateRange: $dateRange) {
      id
      room
      proceduresCnt
      surgeryDays
      procedurePerDay
      pipoAvg
      pipoUtilization
      cleaningAvg
      popiAvg
      wrAvg
      preopAvg
      pacuAvg
      postOpAvg
      preOpToDischargedAvg
      inOrAvg
      inSurgeryAvg
      closingAvg
      procedureCompleteAvg
      orReadyAvg
      canceled
    }
  }
`;

export const ORUtilizationAnalyticsTotalsQuery = gql`
  query ORUtilizationAnalyticsTotals($dateRange: DateRange!) {
    ORUtilizationAnalyticsTotals(dateRange: $dateRange) {
      id
      room
      proceduresCnt
      surgeryDays
      procedurePerDay
      pipoAvg
      pipoUtilization
      cleaningAvg
      popiAvg
      wrAvg
      preopAvg
      pacuAvg
      postOpAvg
      preOpToDischargedAvg
      inOrAvg
      inSurgeryAvg
      closingAvg
      procedureCompleteAvg
      orReadyAvg
    }
  }
`;

export const ORPatientsUtilizationAnalyticsQuery = gql`
  query ORPatientsUtilizationAnalytics($orId: Long!, $dateRange: DateRange!) {
    ORPatientsUtilizationAnalytics(orId: $orId, dateRange: $dateRange) {
      id
      patient
      patientProviderId
      surgeryTime
      procedureType
      admittedAt
      preOpIn
      preOpOut
      orIn
      orOut
      recoveryIn
      recoveryOut
      postOpIn
      postOpOut
      dischargedAt
      preOpInMinutes
      pipoInMinutes
      recoveryInMinutes
      postOpInMinutes
      preOpToExitInMinutes
    }
  }
`;

export const physicianUtilizationAnalyticsQuery = gql`
  query physicianUtilizationAnalytics($dateRange: DateRange!) {
    physicianUtilizationAnalytics(dateRange: $dateRange) {
      id
      physician
      surgeryDays
      numberOfProcedures
      firstPIToLastPOTotal
      pipoTotal
      pipoOverE2EPercentage
      startToCloseAvg
      poToCutAvg
      popiPhysicianAvg
      wrAvg
      preOpAvg
      pipoAvg
      recoveryAvg
      postOpAvg
      preOpToExitAvg
      preOpToExitTotal
      inOrAvg
      inSurgeryAvg
      closingAvg
      procedureCompleteAvg
      orReadyAvg
      fcotsDelayAvg
      fcotsDelayDist
      fcotsDelayReasons {
        reason
        count
      }
    }
  }
`;

export const physicianUtilizationAnalyticsTotalsQuery = gql`
  query physicianUtilizationAnalyticsTotals($dateRange: DateRange!) {
    physicianUtilizationAnalyticsTotals(dateRange: $dateRange) {
      id
      physician
      surgeryDays
      numberOfProcedures
      firstPIToLastPOTotal
      pipoTotal
      pipoOverE2EPercentage
      startToCloseAvg
      poToCutAvg
      popiPhysicianAvg
      wrAvg
      preOpAvg
      pipoAvg
      recoveryAvg
      postOpAvg
      preOpToExitAvg
      preOpToExitTotal
      inOrAvg
      inSurgeryAvg
      closingAvg
      procedureCompleteAvg
      orReadyAvg
      fcotsDelayAvg
      fcotsDelayDist
      fcotsDelayReasons {
        reason
        count
      }
    }
  }
`;

export const physicianPatientsAnalyticsQuery = gql`
  query physicianPatientsAnalytics($physicianId: Long!, $dateRange: DateRange!) {
    physicianPatientsAnalytics(physicianId: $physicianId, dateRange: $dateRange) {
      id
      patient
      patientProviderId
      surgeryTime
      operationRoom
      procedureType
      admittedAt
      preOpIn
      preOpOut
      orIn
      orOut
      recoveryIn
      recoveryOut
      postOpIn
      postOpOut
      dischargedAt
      preOpInMinutes
      pipoInMinutes
      recoveryInMinutes
      postOpInMinutes
      preOpToExitInMinutes
    }
  }
`;

export const sendAnalyticsReport = gql`
  mutation sendDetailedReport($monthYear: String!) {
    sendDetailedReport(monthYear: $monthYear)
  }
`;

export const analyticsConfigMutation = gql`
  mutation updateAnalyticsConfiguration(
    $monthlyReportRecipients: [Long!]!
    $weeklyReportRecipients: [Long!]!
    $totalPatientsThreshold: Long
    $waitingRoomTimeThreshold: Long
    $preopTimeThreshold: Long
    $orUtilThreshold: Long
    $orTimeThreshold: Long
    $pacuTimeThreshold: Long
    $cleaningTimeThreshold: Long
    $popiTimeThreshold: Long
    $postOpTimeThreshold: Long
  ) {
    updateAnalyticsConfiguration(
      monthlyReportRecipients: $monthlyReportRecipients
      weeklyReportRecipients: $weeklyReportRecipients
      totalPatientsThreshold: $totalPatientsThreshold
      waitingRoomTimeThreshold: $waitingRoomTimeThreshold
      preopTimeThreshold: $preopTimeThreshold
      orUtilThreshold: $orUtilThreshold
      orTimeThreshold: $orTimeThreshold
      pacuTimeThreshold: $pacuTimeThreshold
      cleaningTimeThreshold: $cleaningTimeThreshold
      popiTimeThreshold: $popiTimeThreshold
      postOpTimeThreshold: $postOpTimeThreshold
    )
  }
`;
