import React, { CSSProperties, FC } from 'react';
import styled, { css } from 'styled-components';
import { formatMinutes } from '../shared/procedureUtils';

const Wrapper = styled.div<any>`
  font-size: 0.75em;
  display: flex;
  justify-content: space-between;
  background-color: ${props => (props.hasError ? 'rgba(255, 137, 0, 0.25)' : 'rgba(255, 255, 255, 0.1)')};
  padding: 0.375em;
  margin-top: 1px;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const Delay = styled.div<any>`
  font-weight: 600;
  color: ${props => props.theme.backgroundColor.string()};
  background-color: ${props => props.theme.warningColor.string()};
  border-radius: 0.125em;
  padding: 0.15em 0.25em 0.125em;
  margin-right: 0.5em;
  ${props =>
    props.subtle &&
    css`
      opacity: 0.6;
      background-color: transparent;
      color: #abe3ff;
      font-weight: 500;
      padding-left: 0;
      padding-right: 0;
    `}
`;

const ReadyTag = styled.div<any>`
  font-weight: 600;
  color: #002707;
  background-color: ${props => props.theme.successColor.string()};
  border-radius: 0.125em;
  padding: 0.15em 0.25em 0.125em;
  margin-left: 0.5em;
`;

export const HelpTag = styled.div<any>`
  font-weight: 600;
  color: white;
  background-color: red;
  border-radius: 0.125em;
  padding: 0.25em 0.5em 0.25em;
  margin-right: 0.75em;
  font-size: 1.1em;
  ${props =>
    props.subtle &&
    css`
      opacity: 0.6;
      background-color: transparent;
      color: #abe3ff;
      font-weight: 500;
      padding-left: 0;
      padding-right: 0;
      font-size: 1em;
    `}
`;

const Appendix: FC<{
  isDPU?: boolean;
  isDimmed?: boolean;
  isDelayed?: boolean;
  isWaiting?: boolean;
  late?: number | null;
  delay?: number;
  readyForOr?: boolean;
  readyForSurgeon?: boolean;
  readyForSurgery?: boolean;
  isHoldProcedure?: boolean;
  isBlockNerve?: boolean;
  style?: CSSProperties;
  isHelp?: boolean;
}> = ({
  isDPU,
  isDimmed,
  isDelayed,
  isWaiting,
  late,
  delay,
  readyForOr,
  readyForSurgeon,
  readyForSurgery,
  isHoldProcedure,
  isBlockNerve,
  style,
  isHelp,
}) => {
  const visible = [
    readyForOr && !isDPU,
    readyForSurgeon && !isDPU,
    readyForSurgery,
    isDelayed,
    isHoldProcedure,
    isBlockNerve,
    isHelp,
    (late || 0) > 0,
  ].includes(true);
  return visible ? (
    <Wrapper style={style}>
      <div>
        {isHelp && <HelpTag>HELP</HelpTag>}
        {isDelayed && <Delay subtle={isDimmed}>OR OUT late {formatMinutes(delay)}</Delay>}
        {isDimmed && <Delay subtle>•</Delay>}
        {late && <Delay subtle={!isWaiting || isDimmed}>OR IN late {formatMinutes(late)}</Delay>}
      </div>
      {!isDimmed && (
        <div>
          {readyForOr && !isDPU && <ReadyTag>Ready For OR</ReadyTag>}
          {readyForSurgeon && !readyForOr && !isDPU && <ReadyTag>Ready For Surgeon</ReadyTag>}
          {!isDimmed && <div>{readyForSurgery && <ReadyTag>Ready For Surgery</ReadyTag>}</div>}
          {isHoldProcedure && <Delay>On Hold</Delay>}
          {isBlockNerve && <Delay>Block Nerve</Delay>}
        </div>
      )}
    </Wrapper>
  ) : null;
};

export default Appendix;
