import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles';
import { smoker, bmiGreaterorEqual35, age40AndOlderYes } from '../../../../../../questionnaire/fallRiskReducers';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import BiggerMedicalPassportCheckbox from '../../components/BiggerMedicalPassportCheckbox';
import getAgeAndBmi from './pageUtils';
import FallRiskCheckbox from '../../components/FallRiskCheckbox';

function initializeValues(rootRef, setChecked) {
  const root = rootRef.current;

  if (!root) {
    return;
  }

  const checkboxes = root.querySelectorAll('input[type="checkbox"');

  setChecked(
    [...checkboxes].reduce((acc, checkbox) => {
      const value = checkbox.checked ? parseInt(checkbox.value) : 0;
      return { ...acc, [checkbox.name]: isFinite(value) ? value : 1 };
    }, {})
  );
}

const Page10 = ({ showQRCode, hospitalName, value, defaultValue, lastPreOpCompletedEvent, ...props }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;

  const [values1, setValues1] = useState({});
  const [, setValues2] = useState({});

  const total1 = useMemo(() => Object.values(values1).reduce((acc, value) => acc + value, 0), [values1]);

  const root1Ref = useRef(undefined);
  const root2Ref = useRef(undefined);

  useEffect(() => {
    initializeValues(root1Ref, setValues1);
    initializeValues(root2Ref, setValues2);
  }, []);

  const handleCheckboxChange = setValues => (value, name) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  return (
    <div id="page10" className="page" style={{ height: '100%', paddingBottom: '0' }}>
      <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
        <div ref={root1Ref}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              gridGap: '1rem',
              alignContent: 'start',
              marginBottom: '1rem',
              paddingTop: '1rem',
              fontSize: '14px',
            }}
          >
            <div style={styles.div}>
              <b>
                <div style={{ fontSize: '20px' }}>DVT Assessment</div>
              </b>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <div>
                {showQRCode && (
                  <img
                    alt="Barcoded value"
                    src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
                  />
                )}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  border: '1px solid black',
                  padding: '0.2rem',
                  lineHeight: '15px',
                }}
              >
                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} nobackground />
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '40% 60%',
                    alignContent: 'start',
                  }}
                >
                  <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} nobackground />
                  <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    fontSize={12}
                    nobackground
                  />
                  <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                  <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12} nobackground />
                </div>
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#e0e0e0',
              padding: '5px',
              width: '100%',
              fontSize: '14px',
            }}
          >
            <p>1. Patients are assessed for VTE risk at the time of admission</p>
            <p>2. The following patients are excluded from this policy:</p>
            <ul style={{ margin: 0 }}>
              <li>Patients who have local/MAC anesthesia planned</li>
              <li>Patients under the age of 18 years</li>
              <li>Patients with planned surgery/procedure time under 45 minutes</li>
            </ul>
          </div>
          <div
            style={{
              borderBottom: '1px solid black',
              width: '100%',
              marginTop: '10px',
              fontSize: '14px',
            }}
          >
            <b>INCREASED RISK FOR DVT</b>
          </div>
          <BiggerMedicalPassportCheckbox
            name="veins"
            label="Varicose veins or venous stasis"
            path={['Vascular', 'varicose']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="ageOver40"
            label="Age greater than 40 years"
            path={['demographicInformation', 'dob']}
            value={1}
            reducer={age40AndOlderYes}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="cancer"
            label="Cancer"
            path={['Endocrine', 'Cancer']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="congestiveheart"
            label="Congestive heart failure"
            path={['Cardiac', 'CHF']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="obesity"
            label="Obesity (BMI >= 35)"
            path={['basicscreening', 'bmi']}
            reducer={bmiGreaterorEqual35}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="smoker"
            label="Smoker"
            path={['basicscreening', 'smokingStatus']}
            reducer={smoker}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="baby"
            label="Had a baby within 30 days of upcoming procedure"
            path={['gynecology', 'baby']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="confined"
            label="Confined to a bed for the last 72 hours or longer"
            path={['GeneralHistory', 'confine']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="longTrip"
            label="Long car trip or long airplane flight (more than 4 hours) planned within 7 days of procedure"
            path={['priorSurgeries', 'recent3']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="stroke"
            label="Stroke (including “ministrokes” or TIAs)"
            path={['Neurologic', 'Stroke']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="dvt"
            label="Had a DVT (blood clot) in leg after a surgical procedure"
            path={['Vascular', 'DVT']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="trauma"
            label="Had a recent trauma to an extremity or major trauma to body"
            path={['GeneralHistory', 'trauma']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="bleeding"
            label="Bleeding or blood clotting disorders"
            path={['Vascular', 'Bleeding1']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="oral"
            label="Oral contraceptives"
            path={['gynecology', 'birthControl']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="hormone"
            label="Hormone replacement therapy"
            path={['gynecology', 'hormoneReplacement']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <BiggerMedicalPassportCheckbox
            name="paralysis"
            label="Paralysis"
            path={['Musculoskeletal', 'paralysis']}
            value={1}
            onCheckboxChange={handleCheckboxChange(setValues1)}
          />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '5% 95%',
              fontSize: '14px',
            }}
          >
            <MedicalPassportField
              name="total"
              style={{
                marginTop: '15px',
              }}
              value={total1}
            />
            <div
              style={{
                marginTop: '15px',
                marginLeft: '10px',
              }}
            >
              Total indicators
            </div>
          </div>

          <div style={{ fontSize: '14px' }}>
            <FallRiskCheckbox
              name="surgery60"
              label="Surgery / General anesthesia lasting longer than 60 minutes"
              value={1}
              style={{ marginTop: '15px' }}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
            <MedicalPassportCheckbox
              name="SCD"
              label="SCD"
              style={{
                marginTop: '3px',
              }}
            />
            <MedicalPassportCheckbox
              name="heparin"
              label="Heparin 5000 u sq."
              style={{
                marginTop: '3px',
              }}
            />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '8% 50%',
              }}
            >
              <MedicalPassportCheckbox
                name="otherBox"
                label="Other"
                style={{
                  marginTop: '3px',
                }}
              />
              <MedicalPassportField
                name="otherfield"
                style={{
                  marginTop: '3px',
                }}
                nobackground
                underline
              />
            </div>

            <MedicalPassportCheckbox
              name="noInterventions"
              label="No Interventions"
              style={{
                marginTop: '3px',
              }}
            />
          </div>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '25% 75%',
              marginTop: '31px',
              fontSize: '14px',
            }}
          >
            <div>Date: ___________________</div>
            <div>Pre-Op Nurse Signature: ________________________________________</div>
          </div>
        </div>
      </div>
      <footer style={{ display: 'grid', gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%', fontSize: '11px', height: '20px' }}>
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>{')'} <span style={{ fontSize: '14px', marginLeft: '9rem' }}><b>Ospitek, Inc</b></span></div>
      </footer>
    </div>
  );
};

export default Page10;
