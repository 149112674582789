import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import {
  ageFrom60to69YearsOld,
  ageFrom70to79YearsOld,
  ageFrom80YearsOld,
  toBooleanPositive,
  toFemale,
  toMale,
} from '../../../../../../questionnaire/fallRiskReducers';
import toSex from '../../../../../../questionnaire/toSex';
import toAge from '../../../../../../questionnaire/toAge';
import FallRiskCheckbox from '../../components/FallRiskCheckbox';

function initializeValues(rootRef, setChecked) {
  const root = rootRef.current;

  if (!root) {
    return;
  }

  const checkboxes = root.querySelectorAll('input[type="checkbox"]');

  setChecked(
    [...checkboxes].reduce((acc, checkbox) => {
      const value = checkbox.checked ? parseInt(checkbox.value) : 0;
      return { ...acc, [checkbox.name]: isFinite(value) ? value : 1 };
    }, {})
  );
}

const Page7 = ({ showQRCode, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;

  const [values1, setValues1] = useState({});
  const [, setValues2] = useState({});

  const total1 = useMemo(() => Object.values(values1).reduce((acc, value) => acc + value, 0), [values1]);

  const root1Ref = useRef(undefined);
  const root2Ref = useRef(undefined);

  useEffect(() => {
    initializeValues(root1Ref, setValues1);
    initializeValues(root2Ref, setValues2);
  }, []);

  const handleCheckboxChange = setValues => (value, name) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div id="page7" className="page">
      <div style={styles.center}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div />
          <h2 style={styles.underlineHeading}>Fall Risk Assessment</h2>
          <div>
            {showQRCode && (
              <img
                alt="Barcoded value"
                src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
              />
            )}
          </div>
        </div>
        <div
          style={{
            marginBottom: '0.4rem',
            width: '100%',
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
            <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
            <MedicalPassportField name="procedure.patient.providerId" label="MRN" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
            <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} />
            <MedicalPassportField name="age" label="Age" path={['demographicInformation', 'dob']} reducer={toAge} />
            <MedicalPassportField name="procedure.serviceTime" label="DOS" />
          </div>
        </div>
      </div>
      <div ref={root1Ref}>
        <div style={styles.oneColumn}>Fall Risk Assessment</div>
        <div style={styles.threeNotEqualColumns3}>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '.2rem', fontSize: '1rem' }}>
            Risk Factor(s)
          </div>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '.2rem', fontSize: '1rem' }}>
            Scale
          </div>
          <div style={{ textAlign: 'center', padding: '.2rem', fontSize: '1rem' }}>Score</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
            }}
          >
            <div style={{ fontSize: '1rem' }}>Age</div>
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.ageFrom60to69YearsOld"
              value={1}
              label="60-69"
              path={['demographicInformation', 'dob']}
              reducer={ageFrom60to69YearsOld}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.ageFrom70to79YearsOld"
              value={2}
              label="70-79"
              path={['demographicInformation', 'dob']}
              reducer={ageFrom70to79YearsOld}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            2
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.ageFrom80YearsOld"
              value={3}
              label="greater than or equal to 80 years"
              path={['demographicInformation', 'dob']}
              reducer={ageFrom80YearsOld}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>3</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 3',
            }}
          >
            Gender
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.genderMale"
              value={2}
              label="Male"
              path={['demographicInformation', 'sex']}
              reducer={toMale}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            2
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.genderFemale"
              value={1}
              label="Female"
              path={['demographicInformation', 'sex']}
              reducer={toFemale}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>1</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 2',
              fontSize: '.85rem',
            }}
          >
            Fall History
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.fallHistory"
              value={20}
              label="One fall within past 6 months - accidental trips/falls/balances
              issues"
              path={['basicscreening', 'q1']}
              reducer={toBooleanPositive}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '.75rem',
            }}
          >
            20
          </div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
              fontSize: '.7rem',
            }}
          >
            Medication in last 24 hours- sedatives, hypnotics, barbiturates, phenothiazines, antidepressants,
            laxatives/diuretics, narcotics
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.medicationOne"
              value={1}
              label="On 1 high fall risk medication"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.medicationTwo"
              value={3}
              label="On 2 high fall risk medication"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              padding: '0.25rem',
              fontSize: '.75rem',
            }}
          >
            3
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.medicationThree"
              value={5}
              label="On 3 or more high fall risk medication"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
              fontSize: '.75rem',
            }}
          >
            Current Co-morbid Conditions-HTN, Cardiac, seizure disorder, diabetes, cancer, Parkinson’s, MS, stroke,
            dementia, vertigo, hx of vaso-vagal episodes, pacemaker/defibrillator, clotting/bleeding disorders,
            arthritis, aphasia, PTSD
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.conditionOne"
              value={5}
              label="1 Co-morbid condition"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.conditionTwo"
              value={10}
              label="2 Co-morbid condition"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            10
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.conditionThree"
              value={15}
              label="3 or more Co-morbid condition"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>15</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 5',
            }}
          >
            Mobility (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.mobiliyAssist"
              value={10}
              label="Requires assistance or supervision for mobility, transfer, or ambulation"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.unsteadyGait"
              value={10}
              label="Unsteady gait"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.visualImpairment"
              value={5}
              label="Visual impairment affecting mobility "
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.auditoryImpaired"
              value={2}
              label="Auditory impairment affecty mobility"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '.75rem',
            }}
          >
            2
          </div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 3',
            }}
          >
            Home Oxygen
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.oxygenYes"
              value={1}
              label="Yes"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.oxygenNo"
              value={0}
              label="No"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>0</div>
        </div>
      </div>
      <div ref={root2Ref}>
        <div style={styles.twoNotEqualColumns4}>
          <div style={{ textAlign: 'right', padding: '0.5rem', borderRight: '1px solid black', fontSize: '.75rem' }}>
            Total
          </div>
          <div style={{ textAlign: 'center', padding: '.25rem', fontSize: '.75rem' }}>
            <MedicalPassportField name="fallRiskAssessment.total1" />
          </div>
        </div>
        <div style={styles.oneColumn}>
          Pre-Procedure Assessment--completed by RN during preop phone call or upon arrival the day of service
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '.20rem', fontSize: '1rem' }}>
            Risk Factor(s)
          </div>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '.20rem', fontSize: '1rem' }}>
            Scale
          </div>
          <div style={{ textAlign: 'center', padding: '.20rem', fontSize: '1rem' }}>Score</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
            }}
          >
            Tubes & Cords (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.IV"
              value={1}
              label="IVs"
              onCheckboxChange={handleCheckboxChange(setValues2)}
              path={['demographicInformation', 'dob']}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.O2"
              value={1}
              label="O2"
              onCheckboxChange={handleCheckboxChange(setValues2)}
              path={['demographicInformation', 'dob']}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            1
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.SCD"
              value={1}
              label="SCDs"
              onCheckboxChange={handleCheckboxChange(setValues2)}
              path={['demographicInformation', 'dob']}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>1</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 3',
            }}
          >
            Blocks / Sedation
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.regionalBlock"
              value={5}
              label="Regional Block"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.sedation"
              value={5}
              label="Pre-Procedure Sedation"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>5</div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 8',
            }}
          >
            Type of Procedure
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.pain"
              value={15}
              label="Pain"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            15
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.kneeHipReplace"
              value={10}
              label="Total Knee or Hip Joint Replacement"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.colonoscopy"
              value={5}
              label="Colonoscopy"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
        </div>
        <div style={styles.threeNotEqualColumns3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 5',
            }}
          >
            Cognitive or behavioral issues (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.agitated"
              value={2}
              label="Agitated"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            2
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.impulseControl"
              value={5}
              label="Impulse control"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.noncompliance"
              value={5}
              label="Noncompliance"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            5
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.lackUnderstandingLimit"
              value={5}
              label="Lack of Understanding limitations"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '.75rem' }}>5</div>
        </div>
      </div>
      <div>
        <div style={styles.twoNotEqualColumns4}>
          <div
            style={{
              textAlign: 'right',
              padding: '0.5rem',
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '.75rem',
            }}
          >
            Pre-Op Assessment point Total
          </div>
          <div style={{ textAlign: 'center', padding: '0.5rem', fontSize: '.75rem', borderBottom: '1px solid black' }}>
            <MedicalPassportField name="fallRiskAssessment.preProcedureTotal" />
          </div>
          <div style={{ textAlign: 'right', padding: '0.5rem', borderRight: '1px solid black', fontSize: '.75rem' }}>
            Combined Total PAT Pre-procedure Assessment Point Total
          </div>
          <div style={{ textAlign: 'center', padding: '0.5rem', fontSize: '.75rem' }}>
            <MedicalPassportField name="fallRiskAssessment.combinedTotal" />
          </div>
        </div>
        <div style={styles.oneColumn}>
          <div style={{ fontSize: '.75rem' }}>
            Implement High Risk Fall Precautions for any patient with a fall history in the last 6 months or an
            assessment score of &gt; 20 points.
          </div>
        </div>
        <div style={styles.oneColumn}>
          <div style={{ fontSize: '.75rem' }}>
            WHEELCHAIR REQUIRED FOR ANY PATIENT USING ASSISTIVE DEVICES OR VISIBLE DIFFICULTY WITH AMBULATION.
          </div>
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            fontSize: '.75rem',
          }}
        >
          <div style={styles.oneColumn}>
            <div style={{ fontSize: '.75rem' }}>
              REMINDER: All PACU patients are considered High Risk for falls. Additional protocols for any patients
              experiencing any of the following risks.
            </div>
          </div>
        </div>
      </div>
      <div style={styles.signatureBox1}>
        <div style={{ paddingTop: '1.5rem' }}>PreOp Nurse Signature: ______________________________</div>
        <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>Date/Time: _______________________</div>
      </div>
    </div>
  );
};

export default Page7;
