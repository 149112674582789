import React, { FC, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../unpackSessionObject';
import useHasScheduleAccessAllRight from './schedule/useHasScheduleAccessAllRight';
import { ScheduleUserType } from '../../../types/ScheduleUser';
import UserSchedule from './UserSchedule';
import HospitalSchedule from './HospitalSchedule';
import Session from '../../../types/Session';
import styled from 'styled-components';
import { ScheduleUserProvider } from './schedule/vendor/context/ScheduleUserContext';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { muiThemeDark, muiThemeLight } from '../../../../src/muiTheme';
import { withScope } from '../../../contexts/ScopeContext';
import { getNestedValue } from '../../../se/utilities/data/object';

const IconWrapper = styled.i`
  font-size: 1em;
  margin-right: 0.33333em;
`;

export const Icon = ({ children, ...rest }) => (
  <IconWrapper className="material-icons" {...rest}>
    {children}
  </IconWrapper>
);

const SchedulePage: FC<
  {
    isScheduleUser?: boolean;
    scheduleUserId?: number;
    scheduleUserType?: ScheduleUserType;
    scope: any;
    session: Session;
  } & RouteComponentProps
> = props => {
  const { isScheduleUser, scheduleUserId, scheduleUserType, history } = props;
  const [theme, setTheme] = useState(muiThemeDark);

  const hasScheduleAccessAllRight = useHasScheduleAccessAllRight(scheduleUserId, scheduleUserType);
  const [myProceduresChecked, setMyProceduresChecked] = useState(isScheduleUser || false);

  const toggleTheme = () => {
    setTheme(theme === muiThemeLight ? muiThemeDark : muiThemeLight);
  };

  const selectedTheme = props?.isKiosk ? (getNestedValue('lightMode', props?.config) ?  muiThemeLight : muiThemeDark) : theme;

  return (
    <MUIThemeProvider theme={selectedTheme}>
      {myProceduresChecked ? (
        <ScheduleUserProvider scheduleUserId={scheduleUserId} scheduleUserType={scheduleUserType}>
          <UserSchedule
            {...props}
            hasScheduleAccessAllRight={hasScheduleAccessAllRight}
            myProceduresChecked={myProceduresChecked}
            setMyProceduresChecked={setMyProceduresChecked}
          />
        </ScheduleUserProvider>
      ) : (
        <HospitalSchedule
          {...props}
          toggleTheme={toggleTheme}
          selectedTheme={selectedTheme}
          hasScheduleAccessAllRight={hasScheduleAccessAllRight}
          myProceduresChecked={myProceduresChecked}
          setMyProceduresChecked={setMyProceduresChecked}
        />
      )}
    </MUIThemeProvider>
  );
};

// @ts-ignore
export default compose(withRouter, withScope, withSession(unpackSessionObject))(SchedulePage);
