import React from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { ScopeProvider } from '../../../contexts/ScopeContext';
import LegacyPredefinedForm from './print/LegacyPredefinedForm';
import ErrorBoundary from '../../../se/components/ErrorBoundary';

const PrintRouter = ({ match, location }) => (
  <ErrorBoundary>
    <ScopeProvider>
      <Route path={`${match.path}/legacy-predefined-form`} component={LegacyPredefinedForm} />
    </ScopeProvider>
  </ErrorBoundary>
);

export default withRouter(PrintRouter);
