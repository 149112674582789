import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import queryString from 'query-string';

import { unpackSessionObject } from './unpackSessionObject';
import SuperAdministratorHomepage from './user/super-admin/Homepage';
import MonitorEngineerHomepage from './user/monitoring/Homepage';
import AdministratorHomepage from './user/admin/Homepage';
import FrontDeskHomepage from './user/front/Homepage';
import SOAdminHomepage from './user/surgeon/admin/Homepage';
import SONursingHomepage from './user/surgeon/nursing/Homepage';
import NursingHomepage from './user/nursing/Homepage';
import ManagerHomepage from './user/businessManager/Homepage';
import SchedulerHomepage from './user/scheduler/Homepage';
import PreOPNurseHomepage from './user/preop-nurse/Homepage';
import SensorSupervisorHomepage from './user/sensor-supervisor/Homepage';
import OperationManagerHomepage from './user/operationManager/Homepage';
import PhysicianHomepage from './user/physician/Homepage';
import AnesthesiologistHomepage from './user/anesthesiologist/Homepage';
import StaffMemberHomepage from './user/staff-member/Homepage';
import NoPermissions from './user/NoPermissions';
import Login from './user/Login';
import get from 'lodash/get';

import {
  ADMIN,
  BUSINESS_MANAGER,
  FRONT_DESK,
  GROUP_ADMIN,
  MONITORING,
  NURSE,
  NURSING,
  OPERATIONS_MANAGER,
  PREOP_NURSE,
  SCHEDULER,
  SENSOR_SUPERVISOR,
  SUPER_ADMIN,
  PHYSICIAN,
  ANESTHESIOLOGIST,
  STAFF_MEMBER,
} from '../entities/role/enums';
import { withSession } from '../../state/Session';
import ExpireableConnectionProvider from '../../components/ExpireableConnectionProvider';
import { CrispContextProvider, withCrispCtx } from '../../vendor/CrispProvider';
import { OrganizationType } from '../entities/hospitals/enums';
import Initialization from './user/Initialization';
import ResetPasswordPage from './user/ResetPasswordPage';
import SetPasswordPage from './user/SetPasswordPage';
import { Scope, ScopeProvider } from '../../contexts/ScopeContext';

/**
 * This component expect session to be either false or an object having a boolean valid value.
 *
 * Until we get to the async rendering, parent component should wait for session to be ready.
 */

const SCRoleHomepages = {
  [SUPER_ADMIN]: SuperAdministratorHomepage,
  [GROUP_ADMIN]: SuperAdministratorHomepage,
  [MONITORING]: MonitorEngineerHomepage,
  [ADMIN]: AdministratorHomepage,
  [FRONT_DESK]: FrontDeskHomepage,
  [SENSOR_SUPERVISOR]: SensorSupervisorHomepage,
  [NURSING]: NursingHomepage,
  [OPERATIONS_MANAGER]: OperationManagerHomepage,
  [BUSINESS_MANAGER]: ManagerHomepage,
  [PREOP_NURSE]: PreOPNurseHomepage,
  [SCHEDULER]: SchedulerHomepage,
  [PHYSICIAN]: PhysicianHomepage,
  [ANESTHESIOLOGIST]: AnesthesiologistHomepage,
  [STAFF_MEMBER]: StaffMemberHomepage,
};

const SORoleHomepages = {
  [ADMIN]: SOAdminHomepage,
  [NURSE]: SONursingHomepage,
};

const HomepageSwitch = ({ role, scope, ...rest }) => {
  if (scope === undefined) {
    // Loading organization scope data
    return <Initialization />;
  }

  const routesForType = () => {
    if (get(scope, 'hospital.type') === OrganizationType.SurgeonOffice) {
      return SORoleHomepages[role];
    } else {
      return SCRoleHomepages[role];
    }
  };
  const Homepage = routesForType() || NoPermissions;

  return <Homepage {...rest} />;
};

const User = ({ location, session, role, crisp, isSuperAdmin }) => {
  const loggedIn = session && session.valid;
  const nextQueryString = () =>
    queryString.stringify({
      ...queryString.parse(location.search),
      next: location.pathname,
    });

  useEffect(() => {
    if (crisp) {
      if (loggedIn && !isSuperAdmin) {
        crisp.showChat();
      } else {
        crisp.hideChat();
      }
    }
  }, [crisp, loggedIn, isSuperAdmin]);

  const onLogout = () => {
    if (crisp) {
      crisp.clearSession();
    }
  };

  return (
    <Switch>
      <Route exact path={`/login`} component={Login} />
      <Route exact path={`/set-password`} component={SetPasswordPage} />
      <Route exact path={`/reset-password`} component={ResetPasswordPage} />
      <Route
        render={props =>
          loggedIn ? (
            <ExpireableConnectionProvider session={session} onLogout={onLogout}>
              <ScopeProvider>
                <Scope.Consumer>{scope => <HomepageSwitch role={role} scope={scope} {...props} />}</Scope.Consumer>
              </ScopeProvider>
            </ExpireableConnectionProvider>
          ) : (
            <Redirect to={`/login?${nextQueryString()}`} />
          )
        }
      />
    </Switch>
  );
};

const withSupport = Component => props =>
  (
    <CrispContextProvider>
      <Component {...props} />
    </CrispContextProvider>
  );

export default compose(withSupport, withRouter, withSession(unpackSessionObject), withCrispCtx)(User);
