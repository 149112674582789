import React, { FC } from 'react';

export const getBarcodeText = (
  providerId: number | string,
  serviceTime: string,
  formName: string,
  pageNumber: number
): string => {
  return `EXT-${providerId}|${serviceTime}|${formName}|${pageNumber}`;
};

export const getBarcodeTextSrc = (text: string): string =>
  `http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${text}&scale=1`.replace(/ /g, '-');

const Barcode: FC<{
  providerId: number | string;
  serviceTime: string;
  formName: string;
  pageNumber: number;
}> = ({ providerId, serviceTime, formName, pageNumber }) => {
  const show = providerId && serviceTime && formName && pageNumber;

  if (!show) return null;

  const src = getBarcodeTextSrc(getBarcodeText(providerId, serviceTime, formName, pageNumber));

  return <img alt="Barcoded value" src={src} />;
};

export default Barcode;
