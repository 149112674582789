import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  font-size: ${props => (props.large ? '2vw' : '1em')};
`;

const Image = styled.img`
  max-width: 20rem;
  width: 45%;
`;

const Text = styled.h3`
  font-size: 1.125em;
  color: ${props => props.theme.button.primary.backgroundColor.string()};
  margin-top: 1em;
  opacity: 0.35;
`;

const PanelEmpty = forwardRef(({ image, text, ...props }, ref) => (
  <Container {...props} ref={ref}>
    <Image src={image} />
    <Text>{text}</Text>
  </Container>
));

export default PanelEmpty;
