import React from 'react';
import styles from '../../styles';
import MedicalPassportRiskFactorRow from '../../components/MedicalPassportRiskFactorRow';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import MedicalPassportField from '../../components/MedicalPassportField';
import toHeight from '../../../../../../questionnaire/toHeight';
import toWeight from '../../../../../../questionnaire/toWeight';
import toBMI from '../../../../../../questionnaire/toBMI';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import {
  ageFrom41to60YearsOld,
  ageFrom61to74YearsOld,
  ageFrom75YearsOld,
  bmiGreaterThan30,
} from '../../../../../../questionnaire/fallRiskReducers';
import { CommonPartP5P6 } from './Page5';
import getAgeAndBmi from './pageUtils';

const riskFactorOptions1 = () => (
  <div style={{ display: 'flex', gap: '5px' }}>
    <MedicalPassportCheckbox name="riskFactor.copd" label="COPD" path={['Pulmonary', 'Emphysema']} />
    <div>/</div>
    <MedicalPassportCheckbox name="riskFactor.chf" label="CHF" path={['Cardiac', 'Congestive heart failure']} />
    <div>/</div>
    <MedicalPassportCheckbox name="riskFactor.pneumonia" label="Pneumonia" path={['Infectious', 'Pneumonia']} />
    <div>/</div>
    <MedicalPassportCheckbox
      name="riskFactor.afib"
      label="AFIB"
      path={['Cardiac', 'Diagnosed Abnormal Heart Rhythm']}
    />
  </div>
);

const riskFactorOption2 = () => (
  <div>
    <div>Scheduled major surgery with additional medical risk factors:</div>
    <div style={{ display: 'flex', gap: '5px' }}>
      <div>
        <MedicalPassportCheckbox name="mi" label="MI" />
      </div>
      <div>
        <MedicalPassportCheckbox name="chf" label="CHF" path={['Cardiac', 'Congestive heart failure']} />
      </div>
      <div>
        <MedicalPassportCheckbox name="sepsis" label="Sepsis or" />
      </div>
      <div>
        <MedicalPassportCheckbox name="lungDisease" label="serious lung disease" />
      </div>
    </div>
  </div>
);

const CommonPartOne = () => (
  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.5rem' }}>
    <div>
      <div style={styles.greyBackground}>Each Risk Factor Represents (1) Point</div>
      <MedicalPassportRiskFactorRow
        name="riskFactor.from41to60YearsOld"
        text="Patient is 41-60 years old"
        path={['demographicInformation', 'dob']}
        reducer={ageFrom41to60YearsOld}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.surgeryWithin30days"
        text="Has had surgery in an operating room within 30 days of the upcoming procedure"
        path={['anesthesia', 'upcoming']}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.varicoseVeins"
        text="Varicose Veins"
        path={['medicalHistory', 'Varicose veins or venous stasis']}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.inflammatoryBowelDisease"
        text="Inflammatory Bowel Disease"
        path={['medicalHistory', 'IBD']}
      />
      <MedicalPassportRiskFactorRow name="riskFactor.swollenLegs" text="Swollen legs (present on admission)" />
      <MedicalPassportRiskFactorRow
        name="riskFactor.obesity"
        text="Obesity (BMI > 30)"
        path={['demographicInformation', 'bmi']}
        reducer={bmiGreaterThan30}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.smoking"
        text="Smoking/Chewing Tobacco use"
        path={['basicscreening', 'smoking']}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.birthControl"
        text="Birth Control or Hormone replacement therapy"
      />
      <MedicalPassportRiskFactorRow name="riskFactor.riskFactorOptions" path={['', '']} text={riskFactorOptions1()} />
      <MedicalPassportRiskFactorRow name="riskFactor.pregnancy" text="Pregnancy or less than 30 days Post Partum" />
      <MedicalPassportRiskFactorRow name="riskFactor.immobilization" text="Immobilization > 3 days pre-op" />
      <MedicalPassportRiskFactorRow name="riskFactor.scheduledSurgery" text="Scheduled surgery time 46 to 60 minutes" />
      <MedicalPassportRiskFactorRow />
      <MedicalPassportRiskFactorRow />
      <div style={styles.greyBackground}>Total score this column (1)</div>
    </div>
    <div>
      <div style={styles.greyBackground}>Each Risk Factor Represents (2) Point</div>
      <MedicalPassportRiskFactorRow
        name="riskFactor.from61to74YearsOld"
        text="Patient is 61-74 years old"
        path={['demographicInformation', 'dob']}
        reducer={ageFrom61to74YearsOld}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.skin"
        text="Malignancy-except skin (present or previous history)"
      />
      <MedicalPassportRiskFactorRow name="riskFactor.surgery2" text="Scheduled major surgery 61 min or greater" />
      <MedicalPassportRiskFactorRow
        name="riskFactor.immobility"
        text="Lower extremely immobilizing cast within last 30 days"
        path={['medicalHistory', 'Lower extremity immobilizing cast']}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.centralVenousLine"
        text="Currently has a control venous line such as chemo and/or antibiotic port, PICC line, or dialysis catheter"
        path={['anesthesia', 'central venous line']}
      />
      <div style={styles.greyBackground}>Each Risk Factor Represents (3) Points</div>
      <MedicalPassportRiskFactorRow
        name="riskFactor.dvt"
        text="Hx of DVT/PE (any time)"
        path={['Vascular', 'History of blood clots']}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.from75YearsOld"
        text="Patient is 75 yrs old or greater"
        path={['demographicInformation', 'dob']}
        reducer={ageFrom75YearsOld}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.bleedingDisorder"
        text="History of any type blood dyscrasia or bleeding disorder"
        path={['Vascular', 'Bleeding or blood clotting disorders']}
      />
      <MedicalPassportRiskFactorRow name="riskFactor.riskFactor2" text={riskFactorOption2()} />
      <div style={styles.greyBackground}>Each Risk Factor Represents (5) Points</div>
      <MedicalPassportRiskFactorRow
        name="riskFactor.joint"
        text="Total joint replacement or hip, pelvis or leg fracture within the last 30 days"
        path={['generalhistory', 'artificial joint']}
      />
      <MedicalPassportRiskFactorRow
        name="riskFactor.strokeAndTIA"
        text="History of stroke and/or TIA"
        path={['Neurologic', 'Stroke']}
      />
      <div style={styles.greyBackground}>Total score this column (2)</div>
    </div>
  </div>
);

const LineWithLabel = ({ label }) => (
  <div>
    ______
    <span>{label}</span>
  </div>
);

const Page6 = ({ showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { bmi } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  return (
    <div id="page6" className="page" style={{ fontSize: '0.8em' }}>
      <div style={{ marginBottom: '5px', display: 'flex', justifyContent: 'space-between', marginRight: '2px' }}>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{hospitalName || ''}</div>
          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Venous Thromboembolism (VTE) Risk Assessment</div>
        </div>
        <div>
          {showQRCode && (
            <img
              alt="Barcoded value"
              src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
            />
          )}
        </div>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div>
          <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
            <div style={{ minHeight: '70px', padding: '3px' }}>
              <MedicalPassportTextArea name="procedure.name" label="Planned Procedure" />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
                lineHeight: '1.6em',
              }}
            >
              <MedicalPassportField name="ht" label="Ht" path={['demographicInformation', 'bmi']} reducer={toHeight} />
              <MedicalPassportField name="wt" label="Wt" path={['demographicInformation', 'bmi']} reducer={toWeight} />
              <MedicalPassportField
                name="bmi"
                label="BMI"
                path={['demographicInformation', 'bmi']}
                reducer={toBMI}
                warning={bmi !== '-' ? bmi > 40 : false}
              />
            </div>
          </div>
        </div>
        <CommonPartP5P6 providerId={providerId} />
      </div>
      <div
        style={{
          border: '1px solid black',
          borderTop: 0,
          borderBottom: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '4px',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              borderBottom: '1px solid black',
              marginRight: '20px',
            }}
          >
            Exclusion Factors
          </span>
        </div>
        <div
          style={{
            marginLeft: '0.25em',
          }}
        >
          <LineWithLabel label={'Local/Moderate sedation planned'} />
          <LineWithLabel label={'Pediatric patient'} />
        </div>
        <h4
          style={{
            marginBottom: '-1em',
            marginTop: '0.5em',
          }}
        >
          NO FURTHER ASSESSMENT REQUIRED ON EXCLUDED PATIENTS
        </h4>
        <h4
          style={{
            marginBottom: '2px',
          }}
        >
          STEP1: Identify Clinical Risk Factors (give each item identified the appropriate score)
        </h4>
      </div>

      <CommonPartOne />
      <div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <h4
            style={{
              marginBottom: '0px',
            }}
          >
            STEP 2: Total Risk Factor Points (Total of column 1 and 2)
            ______________________________________________________________
          </h4>
        </div>
      </div>

      <h5
        style={{
          marginTop: '2px',
        }}
      >
        *Contradiction for SCD therapy may include fix of severe peripheral arterial disease, current hx of CHF,
        existing DVT
      </h5>

      <div style={{ display: 'flex', gap: '10px', marginTop: '15px', fontWeight: 'bold' }}>
        <div>Ted Hose and Applied</div>
        <div>
          <MedicalPassportCheckbox name="yes" label="Yes" />
        </div>
        <div>
          <MedicalPassportCheckbox name="contraindicated" label="Contraindicated" />
        </div>
        <div>
          <MedicalPassportCheckbox name="na" label="N/A" />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'left', marginTop: '30%' }}>
        <div>PreOp Nurse Signature:______________________</div>
        &nbsp; &nbsp;
        <div>Date/Time:______________________</div>
      </div>
    </div>
  );
};

export default Page6;
