import { useApolloClient, useSubscription } from '@apollo/client';
import { IconButton, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowBack, WbSunnyRounded } from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import clsx from 'clsx';
import { addDays, addMinutes, format, isAfter, parse, startOfDay } from 'date-fns';
import { sortBy } from 'lodash';
import first from 'lodash/first';
import eq from 'lodash/fp/eq';
import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import last from 'lodash/last';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { listWithStaffShiftSubscription as listProceduresSubscription } from '../../../graph/procedures';
import { roomsListWithStaffShiftSubscription } from '../../../graph/rooms';
import { hasAssigned } from '../../../graph/staff';
import LinkButton from '../../../se/components/LinkButton';
import RouterLink from '../../../se/components/RouterLink';
import { getNestedValue } from '../../../se/utilities/data/object';
import responsive, { breakpoint } from '../../../se/utilities/responsive';
import { ScheduleUserType } from '../../../types/ScheduleUser';
import ClientUpdater, { ClientUpdaterPresetTvSchedule } from '../../ClientUpdater';
import ToastBar from '../../core/ToastBar';
import HospitalInfo from '../../HospitalInfo';
import CheckboxInput from '../../inputs/CheckboxInput';
import { Formats } from '../../inputs/upload/formats';
import { ScheduleUploadButton } from '../../inputs/upload/UploadButton';
import EmptySchedule from './EmptySchedule';
import ProcedureEditor from './schedule/edit/ProcedureEditor';

import Filters from './schedule/Filters';

import MonthlySchedule from './schedule/MonthlyView/MonthlySchedule';
import ORNavigation from './schedule/ORNavigation';
import Overview from './schedule/overview/Overview';
import PDFScheduleButton from './schedule/pdf/PDFScheduleButton';
import { getRoomWithStaff, scheduleStaff } from './schedule/pdfGeneratorUtils';
import { ScheduleProcedureForm } from './schedule/procedure/ScheduleProcedureForm';
import ScheduleFooter from './schedule/ScheduleFooter';
import SendNotificationDropdown from './schedule/SendNotificationDropdown';
import MultipleSelectionBox from './schedule/staff/MultipleSelectionBox';
import { MultipleStaffShiftSelectionContextProvider } from './schedule/staff/MultipleStaffShiftSelection';
import StaffingCostButton from './schedule/staff/StaffingCostButton';

import StaffShiftCopyButton from './schedule/staff/StaffShiftCopyButton';
import Timeline from './schedule/timeline/Timeline';
import ToggleOverlay from './schedule/ToggleOverlay';
import { getOperationRooms, getProcedures, transformScheduleOperationRooms } from './schedule/transform';
import WeeklySchedule, { padWeek } from './schedule/WeekView/WeekSchedule';

import ScheduleNotificationDialog from './schedule/ScheduleNotificationDialog';

export const Page = styled.div`
  flex: 1;
  background-color: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  display: flex;
  flex-direction: column;
  font-size: 1.075rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  // Adjusts the UI for HDTV and down
  @media (max-width: 1920px) {
    font-size: 1rem;
  }

  // Adjusts the UI tv-s with lower browser resolution
  ${props =>
    props.isKiosk &&
    css`
      @media (max-width: 1600px) {
        font-size: 0.8125rem;
      }
      @media (max-width: 1400px) {
        font-size: 0.6875rem;
      }
    `} // Adjusts the UI for tablet and mobile devices
  ${responsive.md.andSmaller`
    font-size: .875rem;
    -webkit-overflow-scrolling: touch;
  `};
`;

const Header = styled.div`
  flex: 0 0 3em;

  ${responsive.md.andSmaller`
    flex: 0 0 auto;
  `};
`;

export const EditingHeader = styled(Header)`
  padding: 0.875em 1em;
  display: flex;
  justify-content: stretch;
  align-items: center;

  ${responsive.md.andSmaller`
    overflow: hidden;
    flex-wrap: wrap;
    flex-flow: column;
  `};

  > div {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const FilterBox = styled.div`
  > div {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const ActionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${responsive.md.andSmaller`
    overflow: hidden;
    flex-wrap: wrap;
    flex-flow: column;
  `};
`;

const MainActions = styled.div`
  ${responsive.md.andSmaller`
    margin-bottom: .5em;
    .vanishing {
      display: none;
    }
  `};
`;

export const ActionSeparator = styled.div`
  width: 0.125rem;
  height: 1rem;
  background-color: white;
  opacity: 0.25;
  margin: 0 1rem;

  ${responsive.md.andSmaller`
    display: none;
  `};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div:first-of-type {
    margin-top: 0.75em;
    margin-bottom: 0;

    > label {
      align-items: center;
    }
  }
`;

export const HospitalName = styled.h3`
  opacity: 0.35;
  font-size: 1.125em;
  font-weight: 500;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  width: 100%;
`;

const MonthContainer = styled.div`
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.125em;
  font-weight: 500;
  background-color: ${props => props.theme.backgroundColor.string()};
`;

export const formats = [Formats.CSV, Formats.XLS, Formats.XLSX, Formats.TXT];

const ScrollableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const shouldFilterOrWithoutId = hospitalId =>
  (window.location.hostname === 'view2.ospitek.com' && hospitalId === 24) ||
  (window.location.hostname === 'testing.ospitek.com' && hospitalId === 77);

const Schedule = props => {
  const {
    scope,
    isSuperAdmin,
    isGroupAdmin,
    isMonitoringEngineer,
    isPreOPNurse,
    isAdmin,
    isFrontDesk,
    isOperationsManager,
    isScheduleUser,
    location,
    history,
    match,
    isKiosk,
    date: forcedDate,
    config,
    hasScheduleAccessAllRight,
    myProceduresChecked,
    setMyProceduresChecked,
    scheduleUserId,
    scheduleUserType,
    toggleTheme,
    selectedTheme,
  } = props;

  const showBedNumber = isKiosk ? getNestedValue('showBedNumber', config) : true;
  const showStaffList = getNestedValue('showStaffListOnSchedule', config);
  const showOrRooms = getNestedValue('showOrRooms', config);

  const theme = useTheme();
  const hospitalId = props?.scope?.hospital?.id;
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint.md);
  const [isTimeline, setIsTimeline] = useState(queryString.parse(location.search).timeline === 'true');

  const [currentDate, setCurrentDate] = useState(new Date());
  const [showOverlay, setShowOverlay] = useState(false);
  const useForcedDate = forcedDate && forcedDate.getTime && !isNaN(forcedDate.getTime());
  const dateOverride = parse(queryString.parse(location.search).date + 'T00:00:00');
  const useDateOverride = !isNaN(dateOverride.getTime());
  const date = useForcedDate ? forcedDate : useDateOverride ? dateOverride : currentDate;
  const physician = queryString.parse(location.search).physician;
  const currentOR = decodeURIComponent(location.hash).replace('#', '');

  const [view, setView] = useState('day');

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < breakpoint.md);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsMobile]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [setCurrentDate]);

  const roomsQuery = useSubscription(roomsListWithStaffShiftSubscription, {
    variables: {
      date: format(date, 'YYYY-MM-DD'),
    },
    shouldResubscribe: true,
  });
  const proceduresQuery = useSubscription(listProceduresSubscription, {
    variables: {
      date: format(date, 'YYYY-MM-DD'),
      physician: physician ? parseInt(physician, 10) : undefined,
      isCanceled: false,
    },
    shouldResubscribe: true,
  });

  const toggleView = () => {
    setIsTimeline(!isTimeline);
    const old = queryString.parse(location.search) || {};
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({ ...old, timeline: !isTimeline }),
      hash: location.hash,
    });
  };

  const operationRooms = useMemo(() => {
    const rooms = getOperationRooms(roomsQuery);
    const procedures = getProcedures(proceduresQuery);

    const transformed = transformScheduleOperationRooms({
      rooms,
      procedures,
    });

    const configured = showOrRooms ? transformed.filter(room => showOrRooms.includes(room.id)) : transformed;

    const filtered = sortBy(
      ((shouldFilterOrWithoutId(hospitalId) ? configured.filter(room => !!room.id) : configured) || []).filter(
        e => !e?.isHideScheduleScreen
      ),
      ['name']
    );

    const roomsWithOrder = filtered.filter(room => room?.order !== null).sort((a, b) => a.order - b.order);
    const roomsWithoutOrder = filtered.filter(room => room?.order === null);

    const ordered = [
      ...roomsWithOrder,
      ...roomsWithoutOrder.filter(room => room?.name?.toUpperCase()?.startsWith('OR')),
      ...roomsWithoutOrder.filter(room => !room?.name?.toUpperCase()?.startsWith('OR')),
    ];

    return ordered;
  }, [roomsQuery, proceduresQuery, hospitalId]);

  const client = useApolloClient();
  useEffect(() => {
    date &&
      client.refetchQueries({
        include: [{ query: hasAssigned, variables: { date: format(date, 'YYYY-MM-DD') } }],
      });
  }, [operationRooms, date, client]);

  const unassignedProcedures = useMemo(() => {
    if (!proceduresQuery.data) {
      return [];
    }

    return filter(procedure => !procedure.orId && !procedure.or)(getProcedures(proceduresQuery));
  }, [proceduresQuery]);

  const isPowerUser = isSuperAdmin || isGroupAdmin || isAdmin || isOperationsManager;

  const isAdminUser = isSuperAdmin || isGroupAdmin || isAdmin;

  const canUseScheduleViewProcedure = isPowerUser || isPreOPNurse || isFrontDesk;

  const [editProcedure, setEditProcedure] = useState(null);
  const [editProcedureMonthly, setEditProcedureMonthly] = useState(null);

  const onFilterChange = value => {
    const old = queryString.parse(location.search) || {};
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({ ...old, ...value }),
      hash: location.hash,
    });
  };

  const noProcedures = unassignedProcedures.length === 0 && every(flow(get('procedures'), size, eq(0)))(operationRooms);
  const noShifts = roomsQuery.data ? roomsQuery.data.rooms.every(r => r.staffShifts.length === 0) : true;

  const timelineView = isKiosk ? getNestedValue('isTimeline', config) : isTimeline;
  const ScheduleView = timelineView ? Timeline : Overview;

  const hasMoreThan6ORs = isKiosk ? false : operationRooms.length + (!isEmpty(unassignedProcedures) ? 1 : 0) > 6;
  const [hasMoreThan6ORsView, setHasMoreThan6ORsView] = useState(true);

  const toggle6ORViewMode = () => {
    setHasMoreThan6ORsView(prevState => !prevState);
  };

  const classes = hospitalScheduleStyles();

  const scheduleFooter = useMemo(
    () => (
      <ScheduleFooter
        isFrontDesk={isFrontDesk}
        isPowerUser={isPowerUser}
        isScheduleUser={isScheduleUser}
        hospitalId={hospitalId}
        date={format(date, 'YYYY-MM-DD')}
        rooms={(roomsQuery?.data?.rooms || []).filter(e => !e?.isHideScheduleScreen)}
        staffId={scheduleUserType === ScheduleUserType.StaffMember ? scheduleUserId : null}
        myProceduresChecked={myProceduresChecked}
        editableStaff={isPowerUser}
        isKiosk={isKiosk}
        isFooter={!showStaffList}
      />
    ),
    [
      isFrontDesk,
      isPowerUser,
      isPreOPNurse,
      hospitalId,
      date,
      roomsQuery?.data?.rooms,
      scheduleUserId,
      scheduleUserType,
      myProceduresChecked,
      isKiosk,
      showStaffList,
    ]
  );

  const staffPerRoom = useMemo(
    () =>
      scheduleStaff(
        roomsQuery?.data?.rooms?.map(room => ({
          ...room,
          staffShifts: room.staffShifts.filter(staffShift => !!staffShift.staff?.name),
        })),
        getRoomWithStaff(proceduresQuery?.data?.procedures)
      ),
    [roomsQuery?.data?.rooms, proceduresQuery?.data?.procedures]
  );

  const openProcedureForm = (procedure, operationRooms, operationRoom, date, timeRange) => {
    history.push({
      pathname: procedure?.id ? `${match.url}/procedure/${procedure?.id}` : `${match.url}/procedure`,
      search: location.search,
      state: {
        procedure,
        operationRooms,
        operationRoom,
        date,
        timeRange,
      },
    });
  };

  const createProcedureSchedule = scope?.hospital?.modules?.createProcedureSchedule;
  const showStaffCost = scope?.hospital?.modules?.showStaffCost;
  const showCSVUpload = scope?.hospital?.modules?.showCSVUpload;
  const showWeekends = scope?.hospital?.modules?.showWeekends;

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const formattedMonth = format(date, 'MMMM YYYY');

  const week = useMemo(() => padWeek(date, showWeekends), [date, showWeekends]);

  const formattedWeekStart = format(first(week), 'MMMM DD');
  const formattedWeekEnd = format(last(week), 'MMMM DD');

  const isMaxWidth1920 = useMediaQuery('(max-width: 1920px)');
  const isMaxWidth1600 = useMediaQuery('(max-width: 1600px)');
  const isMaxWidth1400 = useMediaQuery('(max-width: 1400px)');

  return (
    <>
      {isKiosk && <ClientUpdater {...ClientUpdaterPresetTvSchedule} />}
      {useForcedDate && (
        <ToastBar id="forcedDate" variant="warning">
          Note that this screen displays schedule for {format(forcedDate, 'dddd')}.
        </ToastBar>
      )}
      {isKiosk && (
        <Helmet>
          <meta name="viewport" content="width=1920, initial-scale=1" />
        </Helmet>
      )}
      <Box
        className={clsx(classes.page, {
          [classes.kiosk]: isKiosk,
          [classes.hdtv]: isMaxWidth1920,
          [classes.kioskMaxWidth1600]: isKiosk && isMaxWidth1600,
          [classes.kioskMaxWidth1400]: isKiosk && isMaxWidth1400,
        })}
      >
        {isPowerUser || isPreOPNurse || isFrontDesk || isMonitoringEngineer ? (
          <>
            <EditingHeader>
              <MainActions style={{ justifyContent: 'start' }}>
                <RouterLink to={isAdmin ? '/' : `/su/${get('hospital.id')(scope)}`}>
                  <ArrowBack fontSize={'small'} />
                  {` Back${isOperationsManager ? '' : isPowerUser ? ' to admin' : ''}`}
                </RouterLink>
                <ActionSeparator />
                {!createProcedureSchedule && !isMonitoringEngineer && showCSVUpload && (
                  <ScheduleUploadButton
                    history={history}
                    location={location}
                    formats={formats}
                    className={'vanishing'}
                  />
                )}
                <LinkButton onClick={toggleView} style={{ marginLeft: '1em' }}>
                  {isTimeline ? 'List View' : 'Timeline View'}
                </LinkButton>
                {hasMoreThan6ORs && isTimeline && (
                  <Button onClick={toggle6ORViewMode} style={{ marginLeft: '1em' }}>
                    {hasMoreThan6ORsView ? 'Scroll View' : 'Full View'}
                  </Button>
                )}
              </MainActions>

              <h3>{scope?.hospital?.name}</h3>

              <ActionBox>
                {createProcedureSchedule && isTimeline && (
                  <ToggleOverlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} />
                )}

                <ToggleButtonGroup value={view} exclusive onChange={handleViewChange}>
                  <ToggleButton value="day">Day</ToggleButton>
                  <ToggleButton value="week">Week</ToggleButton>
                  <ToggleButton value="month">Month</ToggleButton>
                </ToggleButtonGroup>
                {roomsQuery.data && <StaffShiftCopyButton date={date} rooms={roomsQuery.data.rooms} />}
                {showStaffCost && isAdminUser && <StaffingCostButton match={match} date={format(date, 'YYYY-MM-DD')} />}
                {isPowerUser && (
                  <PDFScheduleButton date={date} staffPerRoom={staffPerRoom} operationRooms={operationRooms} />
                )}
                {!isFrontDesk && !isMobile && !isPreOPNurse && !isMonitoringEngineer && (
                  <Box mr={2}>
                    <SendNotificationDropdown date={dateOverride} />
                  </Box>
                )}
                {/*<IconButton onClick={toggleTheme} color="primary">*/}
                {/*  <WbSunnyRounded />*/}
                {/*</IconButton>*/}
                <ScheduleNotificationDialog />
                <FilterBox>
                  <Filters date={date} physician={physician} onChange={onFilterChange} />
                </FilterBox>
              </ActionBox>
            </EditingHeader>

            {!isFrontDesk && <MultipleSelectionBox />}

            {editProcedure && (
              <ProcedureEditor
                date={date}
                operationRooms={map(get('name'))(operationRooms)}
                procedure={editProcedure}
                onDone={() => setEditProcedure(null)}
                onClose={() => setEditProcedure(null)}
                fullWindow={isMobile}
              />
            )}
            {editProcedureMonthly && (
              <ProcedureEditor
                date={editProcedureMonthly.start}
                operationRooms={map(get('name'))(operationRooms)}
                procedure={editProcedureMonthly}
                onDone={() => setEditProcedureMonthly(null)}
                onClose={() => setEditProcedureMonthly(null)}
                fullWindow={isMobile}
              />
            )}
          </>
        ) : isScheduleUser ? (
          <EditingHeader>
            <LinkButton onClick={toggleView} style={{ marginLeft: '1em' }}>
              {isTimeline ? 'List View' : 'Timeline View'}
            </LinkButton>
            <HospitalName>{scope?.hospital?.name}</HospitalName>
            <Row>
              {hasScheduleAccessAllRight && (
                <CheckboxInput
                  label="My procedures"
                  name="myProcedure"
                  value={myProceduresChecked}
                  onChange={setMyProceduresChecked}
                  layoutProps={{ style: { marginRight: theme.spacing(1) } }}
                />
              )}
              <Filters date={date} withoutPhysician onChange={onFilterChange} />
            </Row>
          </EditingHeader>
        ) : (
          <Header>
            <HospitalInfo />
          </Header>
        )}

        {roomsQuery.loading || proceduresQuery.loading ? (
          <Refresh inSeconds={60} />
        ) : roomsQuery.error || proceduresQuery.error ? (
          <Refresh inSeconds={15}>Error</Refresh>
        ) : (
          <>
            {noProcedures && noShifts && view === 'day' ? (
              <EmptySchedule
                date={date}
                timelineView={timelineView}
                physician={physician}
                isKiosk={isKiosk}
                isScheduleUser={isScheduleUser}
                myProceduresOnly={myProceduresChecked}
                hospitalId={hospitalId}
                currentOR={currentOR}
                isFrontDesk={isFrontDesk}
                isPowerUser={isPowerUser}
                editableStaff={isPowerUser}
                operationRooms={operationRooms}
                setEditProcedure={setEditProcedure}
                unassignedProcedures={unassignedProcedures}
                showBedNumber={showBedNumber}
                scheduleFooter={showStaffList ? scheduleFooter : null}
                history={history}
                match={match}
                canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                openProcedureForm={openProcedureForm}
                showOverlay={showOverlay}
              />
            ) : (
              <>
                {isMobile && <ORNavigation rooms={operationRooms} />}
                {view === 'day' ? (
                  <ScheduleView
                    date={date}
                    hospitalId={hospitalId}
                    isKiosk={isKiosk}
                    currentOR={currentOR}
                    canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                    isFrontDesk={isFrontDesk}
                    isPowerUser={isPowerUser}
                    editableStaff={isPowerUser}
                    operationRooms={operationRooms}
                    setEditProcedure={setEditProcedure}
                    unassignedProcedures={unassignedProcedures}
                    showBedNumber={showBedNumber}
                    scheduleStaffList={showStaffList ? scheduleFooter : null}
                    history={history}
                    match={match}
                    openProcedureForm={openProcedureForm}
                    showOverlay={showOverlay}
                    isPreOPNurse={isPreOPNurse}
                    hasMoreThan6ORsView={hasMoreThan6ORsView}
                  />
                ) : view === 'month' ? (
                  <ScrollableContainer>
                    <MonthContainer>
                      <h2>{formattedMonth}</h2>
                    </MonthContainer>
                    <MonthlySchedule
                      date={date}
                      physician={physician}
                      setEditProcedureMonthly={setEditProcedureMonthly}
                      openProcedureForm={openProcedureForm}
                      canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                      showWeekends={showWeekends}
                    />
                  </ScrollableContainer>
                ) : (
                  <ScrollableContainer>
                    <MonthContainer>
                      <h2>
                        {formattedWeekStart} - {formattedWeekEnd}
                      </h2>
                    </MonthContainer>
                    <WeeklySchedule
                      date={date}
                      physician={physician}
                      setEditProcedureMonthly={setEditProcedureMonthly}
                      openProcedureForm={openProcedureForm}
                      canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                      showWeekends={showWeekends}
                    />
                  </ScrollableContainer>
                )}
                {!showStaffList && view === 'day' && scheduleFooter}
              </>
            )}
          </>
        )}
      </Box>
      <Route
        path={`${props.match.url}/procedure`}
        render={routeProps => {
          const isCorrectPath = props?.location?.pathname === `${props.match.url}/procedure`;
          return (
            isCorrectPath && (
              <ScheduleProcedureForm
                operationRooms={routeProps?.location?.state?.operationRooms}
                date={routeProps?.location?.state?.date}
                operationRoom={routeProps?.location?.state?.operationRoom}
                timeRange={routeProps?.location?.state?.timeRange}
                handleClose={() => routeProps.history.goBack()}
              />
            )
          );
        }}
      />
      <Route
        path={`${props.match.url}/procedure/:procedureId`}
        render={props => {
          const procedureId = parseInt(props?.match?.params?.procedureId, 10);
          const isCorrectPath = props?.location?.pathname === `${props.match.url}`;
          return (
            isCorrectPath && (
              <ScheduleProcedureForm
                procedureId={procedureId}
                procedure={props?.location?.state?.procedure}
                operationRooms={props?.location?.state?.operationRooms}
                date={props?.location?.state?.date}
                operationRoom={props?.location?.state?.operationRoom}
                timeRange={props?.location?.state?.timeRange}
                handleClose={() => props.history.goBack()}
              />
            )
          );
        }}
      />
    </>
  );
};

const HospitalSchedule = props => {
  const {
    location,
    isSuperAdmin,
    isGroupAdmin,
    isMonitoringEngineer,
    isAdmin,
    isFrontDesk,
    isOperationsManager,
    isScheduleUser,
    isPreOPNurse,
    date: forcedDate,
  } = props;

  const [currentDate, setCurrentDate] = useState(new Date());
  const useForcedDate = forcedDate && forcedDate.getTime && !isNaN(forcedDate.getTime());
  const dateOverride = parse(queryString.parse(location.search).date + 'T00:00:00');
  const useDateOverride = !isNaN(dateOverride.getTime());
  const date = useForcedDate ? forcedDate : useDateOverride ? dateOverride : currentDate;
  const physician = queryString.parse(location.search).physician;

  const isPowerUser =
    isSuperAdmin || isGroupAdmin || isMonitoringEngineer || isAdmin || isFrontDesk || isOperationsManager;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [setCurrentDate]);

  const proceduresQuery = useSubscription(listProceduresSubscription, {
    variables: {
      date: format(date, 'YYYY-MM-DD'),
      physician: physician ? parseInt(physician, 10) : undefined,
    },
  });

  const proceduresAreOver = () =>
    flow(
      get('data.procedures'),
      every(
        procedure =>
          procedure.isCanceled ||
          (!procedure.patient && isAfter(new Date(), addMinutes(procedure.startTime, procedure.duration))) ||
          (procedure.patient &&
            procedure.patient.events &&
            (procedure.patient.events.recoveryAt || procedure.patient.events.dischargedAt))
      )
    )(proceduresQuery);

  const dayIsOver =
    !isPowerUser &&
    !isScheduleUser &&
    !useDateOverride &&
    proceduresQuery.data &&
    !isPreOPNurse &&
    !isEmpty(proceduresQuery.data.procedures) &&
    proceduresAreOver();

  return (
    <MultipleStaffShiftSelectionContextProvider date={format(date, 'YYYY-MM-DD')}>
      <Schedule {...props} date={dayIsOver ? startOfDay(addDays(date, 1)) : null} />
    </MultipleStaffShiftSelectionContextProvider>
  );
};

const Refresh = ({ inSeconds, children }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, inSeconds * 1000);

    return () => clearTimeout(timeout);
  });

  return children || null;
};

export const hospitalScheduleStyles = makeStyles(theme => ({
  page: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.075rem',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      WebkitOverflowScrolling: 'touch',
    },
  },
  hdtv: {
    fontSize: '1rem',
  },
  kiosk: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
  },
  kioskMaxWidth1600: {
    fontSize: '0.8125rem',
  },
  kioskMaxWidth1400: {
    fontSize: '0.6875rem',
  },
}));

export default HospitalSchedule;
