import React, { FC } from 'react';
import { ResponsiveContainer } from 'recharts';
import { useQuery } from '@apollo/client';
import { ORUtilizationAnalyticsTotalsQuery } from '../../../../graph/dashboard';
import { ORUtilizationTableTotalsData } from '../../../../types/Analytics';
import { PieChartData } from './PieChart';
import ORPerformancePieChart from './charts/ORPerformancePieChart';

const toPieChartData = (data: ORUtilizationTableTotalsData) => [
  {
    name: 'Patient Prepping',
    value: parseFloat(data?.inOrAvg.toFixed(1)),
  },
  {
    name: 'In Surgery',
    value: parseFloat(data?.inSurgeryAvg.toFixed(1)),
  },
  {
    name: 'Closing',
    value: parseFloat(data?.closingAvg.toFixed(1)),
  },
  {
    name: 'Post Closing',
    value: parseFloat(data?.procedureCompleteAvg.toFixed(1)),
  },
  {
    name: 'Cleaning',
    value: parseFloat(data?.cleaningAvg.toFixed(1)),
  },
  {
    name: 'OR Prepping',
    value: parseFloat(data?.orReadyAvg.toFixed(1)),
  },
];

const toPieChartData2 = (totals: ORUtilizationTableTotalsData): PieChartData[] => {
  return [
    {
      title: 'Total',
      subtitle: isFinite(totals.proceduresCnt)
        ? totals.proceduresCnt === 1
          ? '1 procedure'
          : `${totals.proceduresCnt} procedures`
        : '-',
      inOr: totals.inOrAvg,
      inSurgery: totals.inSurgeryAvg,
      closing: totals.closingAvg,
      procedureComplete: totals.procedureCompleteAvg,
      cleaning: totals.cleaningAvg,
      orReady: totals.orReadyAvg,
      turnover: totals.popiAvg,
      pipoUtilization: totals.pipoUtilization,
    },
    {
      title: 'Total',
      subtitle: isFinite(totals.proceduresCnt)
        ? totals.proceduresCnt === 1
          ? '1 procedure'
          : `${totals.proceduresCnt} procedures`
        : '-',
      inOr: totals.inOrAvg,
      inSurgery: totals.inSurgeryAvg,
      closing: totals.closingAvg,
      procedureComplete: totals.procedureCompleteAvg,
      cleaning: totals.cleaningAvg,
      orReady: totals.orReadyAvg,
      turnover: totals.popiAvg,
      pipoUtilization: totals.pipoUtilization,
    },
  ];
};

const ORPerformanceChart: FC<{ filter: any }> = ({ filter }) => {
  const { data: fetchedData } = useQuery(ORUtilizationAnalyticsTotalsQuery, {
    variables: { dateRange: filter.dateRange },
    fetchPolicy: 'network-only',
  });

  const data = toPieChartData(fetchedData?.ORUtilizationAnalyticsTotals);
  const ORUtilData = toPieChartData2(fetchedData?.ORUtilizationAnalyticsTotals || []);

  const total = Math.round(data.reduce((acc, cur) => acc + cur.value, 0));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ORPerformancePieChart
        data={ORUtilData}
        activeIndex={0}
        name={total}
        subtitle={`${total === 1 ? 'minute' : 'minutes'}`}
        minWidth={0}
        aspect={0}
        isAnimationActive={true}
        innerRadius={'40%'}
        outerRadius={'70%'}
        outerRingInnerRadius={'75%'}
        outerRingOuterRadius={'80%'}
        orTurnoverInnerRadius={'85%'}
        orTurnoverOuterRadius={'90%'}
      />
    </ResponsiveContainer>
  );
};

export default ORPerformanceChart;
