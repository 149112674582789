import { PatientType } from '../../../../types/Patient';

export const mapGraphQLData =
  (key, resKey = undefined) =>
  data => ({
    [resKey || key]: data && data[key] ? data[key] : [],
    error: data.error,
    loading: data.loading,
  });

export const mapPatientData = patients =>
  patients.map(p => ({
    ...p,
    physician: p.physician ? p.physician.name : '',
    anesthesiologist: p.anesthesiologist ? p.anesthesiologist.name : '',
    physicianData: p.physician,
    anesthesiologistData: p.anesthesiologist,
    patient: p.name,
    patientType: p.type ? p.type : PatientType.Out,
    procedure: p.procedureType ? p.procedureType.name : '',
    timeEntered: new Date(p.changedRoomAt),
  }));

export const getPatient = (patients, id) => patients.find(_ => _.id === id);
