import React from 'react';
import styled from 'styled-components';
import { H2 } from '../../se/components/typography';
import responsive from '../../se/utilities/responsive';
import { Box } from '@material-ui/core';
import Section from './Section';

export const Title = styled(H2)`
  margin: 0.5em 0;
  ${responsive.md.andSmaller`
    font-size: 1.25rem;
  `}
`;

interface DashboardProps {}

const Dashboard = (props: DashboardProps) => (
  <Box my={2}>
    <Title>Dashboard</Title>
    <Box my={2}></Box>
    <Section />
  </Box>
);

export default Dashboard;
