import { ROOM_TYPES } from '../room/enums';

export const INTERNAL_MONITOR = 'InternalMonitor';
export const SCHEDULE_MONITOR = 'ScheduleMonitor';
export const WAITING_ROOM_MONITOR = 'WaitingRoom';
export const OPERATION_ROOM_MONITOR = 'OperationRoom';
export const PACU_TABLET = 'PacuTablet';
export const PREP_TABLET = 'PrepTablet';
export const POST_OP_TABLET = 'PostOpTablet';
export const CHARTING_TABLET = 'ChartingTablet';
export const REVIEW_DISPLAY = 'ReviewDisplay';
export const EXIT_DISPLAY = 'ExitDisplay';
export const WAITING_ROOM_TABLET = 'WaitingRoomTablet';
export const ANALYTICS = 'Analytics';

export const INTERNAL_MONITOR_LABELS = {
  [INTERNAL_MONITOR]: 'Internal',
  [SCHEDULE_MONITOR]: 'Schedule',
  [WAITING_ROOM_MONITOR]: 'Waiting Room',
  [OPERATION_ROOM_MONITOR]: 'Operation Room',
  [PACU_TABLET]: 'Recovery Room',
  [PREP_TABLET]: 'PRE-OP Room',
  [POST_OP_TABLET]: 'POST-OP Room',
  [CHARTING_TABLET]: 'Charting Tablet',
  [REVIEW_DISPLAY]: 'Review Display',
  [EXIT_DISPLAY]: 'Exit Display',
  [WAITING_ROOM_TABLET]: 'Waiting Room Tablet',
  [ANALYTICS]: 'Analytics ',
};

export const getScreenType = roomType => {
  switch (roomType) {
    case ROOM_TYPES.WAITING_ROOM:
      return WAITING_ROOM_TABLET;
    case ROOM_TYPES.PACU:
      return PACU_TABLET;
    case ROOM_TYPES.PRE_OP:
      return PREP_TABLET;
    case ROOM_TYPES.POST_OP:
      return POST_OP_TABLET;
    default:
      return null;
  }
};
