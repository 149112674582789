import {
  generateFullPdf,
  generateFullPdf1,
  generateFullPdfHospital17,
  generateFullPdfViewHospital2,
  generateFullPdfHospital3,
  generateFullPdf28,
} from './FullPdfGenerator';
import { generateFullPdfMedicalPassport } from './MedicalPassportPdf';
import {
  generateMedicationPdf,
  generateMedicationPdf3,
  generateMedicationPdf68,
  generateMedicationPdfHospital17,
  generateMedicationPdfHospital_31_1_2,
  generateMedicationPdfView2Hospital28,
  generateMedicationPdfView2Hospital25,
} from './PdfGenerator';

export const getFullPdfGenerator = (hospitalId: number) =>
  hospitalId === 3
    ? generateFullPdfHospital3
    : (window.location.hostname === 'testing.ospitek.com' && hospitalId === 4) ||
      (window.location.hostname === 'view.ospitek.com' && hospitalId === 17)
    ? generateFullPdfHospital17
    : (window.location.hostname === 'testing.ospitek.com' && hospitalId === 59) ||
      (window.location.hostname === 'view.ospitek.com' && hospitalId === 31) ||
      (window.location.hostname === 'view1.ospitek.com' && hospitalId === 10) ||
      (window.location.hostname === 'localhost' && hospitalId === 59) ||
      (window.location.hostname === 'localhost' && hospitalId === 7)
    ? generateFullPdfMedicalPassport
    : (window.location.hostname === 'view.ospitek.com' && hospitalId === 2) ||
      (window.location.hostname === 'testing.ospitek.com' && hospitalId === 67) ||
      (window.location.hostname === 'localhost' && hospitalId === 1)
    ? generateFullPdfViewHospital2
    : (window.location.hostname === 'view.ospitek.com' && hospitalId === 1) ||
      (window.location.hostname === 'testing.ospitek.com' && hospitalId === 68)
    ? generateFullPdf1
    : (window.location.hostname === 'view2.ospitek.com' && hospitalId === 28) ||
      (window.location.hostname === 'testing.ospitek.com' && hospitalId === 96) ||
      (window.location.hostname === 'localhost' && hospitalId === 1)
    ? generateFullPdf28
    : generateFullPdf;

export const getMedPdfGenerator = (hospitalId: number) =>
  (window.location.hostname === 'testing.ospitek.com' && hospitalId === 4) ||
  (window.location.hostname === 'view.ospitek.com' && hospitalId === 17)
    ? generateMedicationPdfHospital17
    : (window.location.hostname === 'testing.ospitek.com' && hospitalId === 93) ||
      (window.location.hostname === 'testing.ospitek.com' && hospitalId === 59) ||
      (window.location.hostname === 'view.ospitek.com' && hospitalId === 2) ||
      (window.location.hostname === 'view.ospitek.com' && hospitalId === 31)
    ? generateMedicationPdfHospital_31_1_2
    : (window.location.hostname === 'testing.ospitek.com' && hospitalId === 78) ||
      (window.location.hostname === 'view.ospitek.com' && hospitalId === 1)
    ? generateMedicationPdf68
    : (window.location.hostname === 'testing.ospitek.com' && hospitalId === 65) ||
      (window.location.hostname === 'view.ospitek.com' && hospitalId === 3)
    ? generateMedicationPdf3
    : (window.location.hostname === 'view2.ospitek.com' && hospitalId === 28) ||
      (window.location.hostname === 'testing.ospitek.com' && hospitalId === 96)
    ? generateMedicationPdfView2Hospital28
    : (window.location.hostname === 'view2.ospitek.com' && hospitalId === 25) ||
      (window.location.hostname === 'testing.ospitek.com' && hospitalId === 120) ||
      (window.location.hostname === 'localhost' && hospitalId === 1)
        ? generateMedicationPdfView2Hospital25
    : generateMedicationPdf;
