import React, { Fragment, useMemo } from 'react';
import Cell from './Cell';
import { setHours, differenceInMinutes } from 'date-fns';
import get from 'lodash/get';
import { orderBy } from 'lodash';
import { getMinutesOfDay } from '../../../../../util/dateTime';
import { useScope } from '../../../../../hooks/useScope';
import { translateToTz } from '../../../../../util/dateTime';

export const ProcedureSlot = ({
  physician,
  organization,
  onClick,
  grayed,
  physicianSelection,
  startTime,
  endTime,
  utilization,
  startTimeText,
  endTimeText,
  patientInitials,
  patientAge,
  patientSex,
  procedureType,
}) => {
  const startPosition = ((getMinutesOfDay(startTime) - 360) / 720) * 100;
  const endPosition = ((getMinutesOfDay(endTime) - 360) / 720) * 100;

  return (
    <Cell
      color={get(physician, 'color')}
      physician={get(physician, 'name')}
      organization={get(organization, 'name')}
      category={get(physician, 'category')}
      speciality={get(physician, 'speciality')}
      onClick={onClick}
      grayed={grayed}
      highlighted={physicianSelection === get(physician, 'id')}
      start={startPosition}
      end={endPosition}
      utilization={utilization}
      startTimeText={endTimeText}
      endTimeText={startTimeText}
      patientInitials={patientInitials}
      patientAge={patientAge}
      patientSex={patientSex}
      procedureType={procedureType}
    />
  );
};

export default ({
  procedures = [],
  onClick,
  physicianSelection,
  date,
  operationRooms,
  operationRoom,
  canUseScheduleViewProcedure,
  setEditProcedureMonthly,
  openProcedureForm,
  grayed
}) => {
  const sorted = useMemo(() => orderBy(procedures, 'start'), [procedures]);
  const scope = useScope();
  const createProcedureScheduleModule = scope?.hospital?.modules?.createProcedureSchedule;

  return (
    <Fragment>
      {procedures.map((procedure, i) => (
        <ProcedureSlot
          key={i}
          physician={procedure.physician}
          organization={procedure.organization}
          grayed={grayed}
          physicianSelection={physicianSelection}
          startTime={translateToTz(get(scope, 'hospital.timezone.id'))(procedure.start)}
          endTime={translateToTz(get(scope, 'hospital.timezone.id'))(procedure.end)}
          startTimeText={procedure.endTimeText}
          endTimeText={procedure.startTimeText}
          patientInitials={procedure.patientInitials}
          patientAge={procedure.patientAge}
          patientSex={procedure.patientSex}
          procedureType={procedure.procedureType}
          onClick={
            canUseScheduleViewProcedure
              ? () => {
                  createProcedureScheduleModule
                    ? openProcedureForm(procedure, operationRooms, operationRoom, procedure.start)
                    : setEditProcedureMonthly(procedure);
                }
              : undefined
          }
        />
      ))}
    </Fragment>
  );
};
