import React, { useState } from 'react';
// @ts-ignore
import logoWhite from '../assets/images/logo_white.svg';
// @ts-ignore
import logoDark from '../assets/images/logo_dark.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    // maxWidth: '100%',
  },
});

const Logo = ({ type, width, height }: { type?: 'light' | 'dark'; width?: number; height?: number }) => {
  const classes = useStyles();
  const theme = useTheme();
  const defaultType = theme.palette.type === 'light' ? logoDark : logoWhite;
  let logo: string | undefined;

  switch (type) {
    case 'light':
      logo = logoDark;
      break;
    case 'dark':
      logo = logoWhite;
      break;
    default:
      logo = defaultType;
  }

  return <img src={logo} alt="logo" className={classes.root} width={width || 112} height={height || 65} />;
};

export default Logo;
