import React, { useMemo, useRef, useState, useEffect } from 'react';
import styles from '../../styles';
import { useValue } from '../../ValueContext';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import getAgeAndBmi from './pageUtils';
import get from 'lodash/get';
import FallRiskCheckbox from '../../components/FallRiskCheckbox';
import FallRiskCheckbox2 from '../../components/FallRiskCheckbox2';
import { morbid, morbidlist } from '../paths';
import {
  ageFrom60to69YearsOld,
  ageFrom70to79YearsOld,
  ageFrom80YearsOld,
  toBooleanNegative,
  toBooleanPositive,
  toFemale,
  toMale,
} from '../../../../../../questionnaire/fallRiskReducers';
import onlylabel from '../../../../../../questionnaire/onlylabel';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';

function initializeValues(rootRef, setChecked) {
  const root = rootRef.current;

  if (!root) {
    return;
  }

  const checkboxes = root.querySelectorAll('input[type="checkbox"');

  setChecked(
    [...checkboxes].reduce((acc, checkbox) => {
      const value = checkbox.checked ? parseInt(checkbox.value) : 0;
      return {
        ...acc,
        [checkbox.name]: isFinite(value) ? value : 1,
      };
    }, {})
  );
}
export let fallriskTotal;

const Page12 = ({ showQRCode, hospitalName, value, defaultValue, lastPreOpCompletedEvent, ...props }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaireAnswers } = useValue();

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  let morbidCount = 0;

  for (let i = 0; i < 30; i++) {
    if (get(questionnaireAnswers, morbidlist[i], '')) {
      morbidCount += 1;
    }
  }

  let unsteadyGait = false;
  if (
    get(questionnaireAnswers, 'sections.basicscreening.schema.problemBalance.yes') ||
    get(questionnaireAnswers, 'sections.basicscreening.schema.device.yes[0]')
  ) {
    unsteadyGait = true;
  }

  let vision = false;
  if (
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.vision1.yes') ||
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.vision2.yes') ||
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.vision6.yes') ||
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.vision7.yes')
  ) {
    vision = true;
  }

  let auditory = false;
  if (
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.hearing1.yes[0]') ||
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.hearing2.yes[0]')
  ) {
    auditory = true;
  }

  const [valueAge, setValueAge] = useState({});
  const [valueGender, setValueGender] = useState({});
  const [valueFall, setValueFall] = useState({});
  const [valueMedication, setValueMedication] = useState({});
  const [valueMorbid, setValueMorbid] = useState({});
  const [valueMobility, setValueMobility] = useState({});
  const [valueOxygen, setValueOxygen] = useState({});
  const [valueTubes, setValueTubes] = useState({});
  const [valueBlocks, setValueBlocks] = useState({});
  const [valueProcedure, setValueProcedure] = useState({});
  const [valueIssue, setValueIssue] = useState({});

  const pointAge = useMemo(() => Object.values(valueAge).reduce((acc, value) => acc + value, 0), [valueAge]);
  const pointGender = useMemo(() => Object.values(valueGender).reduce((acc, value) => acc + value, 0), [valueGender]);
  const pointFall = useMemo(() => Object.values(valueFall).reduce((acc, value) => acc + value, 0), [valueFall]);
  const pointMedication = useMemo(
    () => Object.values(valueMedication).reduce((acc, value) => acc + value, 0),
    [valueMedication]
  );
  const pointMorbid = useMemo(() => Object.values(valueMorbid).reduce((acc, value) => acc + value, 0), [valueMorbid]);
  const pointMobility = useMemo(
    () => Object.values(valueMobility).reduce((acc, value) => acc + value, 0),
    [valueMobility]
  );
  const pointOxygen = useMemo(() => Object.values(valueOxygen).reduce((acc, value) => acc + value, 0), [valueOxygen]);
  const pointTube = useMemo(() => Object.values(valueTubes).reduce((acc, value) => acc + value, 0), [valueTubes]);
  const pointBlocks = useMemo(() => Object.values(valueBlocks).reduce((acc, value) => acc + value, 0), [valueBlocks]);
  const pointProcedure = useMemo(
    () => Object.values(valueProcedure).reduce((acc, value) => acc + value, 0),
    [valueProcedure]
  );
  const pointIssue = useMemo(() => Object.values(valueIssue).reduce((acc, value) => acc + value, 0), [valueIssue]);

  const AgeRef = useRef(undefined);
  const GenderRef = useRef(undefined);
  const FallRef = useRef(undefined);
  const MedicationRef = useRef(undefined);
  const MorbidRef = useRef(undefined);
  const MobilityRef = useRef(undefined);
  const OxygenRef = useRef(undefined);
  const TubeRef = useRef(undefined);
  const BlockRef = useRef(undefined);
  const ProcedureRef = useRef(undefined);
  const IssueRef = useRef(undefined);

  useEffect(() => {
    initializeValues(AgeRef, setValueAge);
    initializeValues(GenderRef, setValueGender);
    initializeValues(FallRef, setValueFall);
    initializeValues(MedicationRef, setValueMedication);
    initializeValues(MorbidRef, setValueMorbid);
    initializeValues(MobilityRef, setValueMobility);
    initializeValues(OxygenRef, setValueOxygen);
    initializeValues(TubeRef, setValueTubes);
    initializeValues(BlockRef, setValueBlocks);
    initializeValues(ProcedureRef, setValueProcedure);
    initializeValues(IssueRef, setValueIssue);
  }, [AgeRef, GenderRef, FallRef, MedicationRef, MorbidRef, MobilityRef, OxygenRef, TubeRef, BlockRef, ProcedureRef, IssueRef]);


  const total1 = pointAge + pointGender + pointFall + pointMedication + pointMorbid + pointMobility + pointOxygen;
  const total2 = pointTube + pointBlocks + pointProcedure + pointIssue;
  const total = total1 + total2;

  useEffect(() => {
    fallriskTotal = total1;
  }, [total1]);
  
  const handleCheckboxChange = setValues => (value, name) => {
    setValues(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div id="page12" className="page" style={{ height: '100%', paddingBottom: '0' }}>
      <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
        <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              gridGap: '1rem',
              alignContent: 'start',
              marginBottom: '1rem',
              paddingTop: '1rem',
              fontSize: '14px',
            }}
          >
            <div style={styles.div}>
              <b>
                <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '14px' }}>{(hospitalName || '').toUpperCase()}</div>
                <div style={{ fontSize: '20px', paddingBottom: '0' }}>SCA Health <br />
                  Fall Risk Assessment Tool</div>
              </b>
              <div style={{ fontSize: '12px' }}>
                <MedicalPassportTextArea2 name="procedure.name" label="Procedure" style={{ display: 'flex' }} fontSize={12} nobackground />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <div>
                {showQRCode && (
                  <img
                    alt="Barcoded value"
                    src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
                  />
                )}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  border: '1px solid black',
                  padding: '0.2rem',
                  lineHeight: '15px',
                }}
              >
                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} nobackground />
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '40% 60%',
                    alignContent: 'start',
                  }}
                >
                  <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} nobackground />
                  <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    fontSize={12}
                    nobackground
                  />
                  <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                  <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12} nobackground />
                </div>
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
              </div>
            </div>
          </div>
          <div style={styles.fallriskgrid}>
            <div
              style={{
                backgroundColor: '#36454F',
                padding: '3px',
                width: '100%',
                fontSize: '14px',
                color: 'white',
              }}
            >
              <b>PATIENT PRE-ADMISSION ASSESSMENT - MEDICAL PASSPORT</b>
            </div>
            <div
              style={{
                textAlign: 'center',
                backgroundColor: '#36454F',
                padding: '3px',
                width: '100%',
                fontSize: '14px',
                color: 'white',
              }}
            >
              <b>POINTS</b>
            </div>
          </div>
          <div ref={AgeRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>AGE:</b>
              </div>
              <div style={styles.fallriskPoint}>{pointAge}</div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.ageFrom60to69YearsOld"
                  value={1}
                  label="60-69"
                  path={['demographicInformation', 'dob']}
                  reducer={ageFrom60to69YearsOld}
                  onCheckboxChange={handleCheckboxChange(setValueAge)}
                />
                <FallRiskCheckbox
                  name="fallRiskAssessment.ageFrom70to79YearsOld"
                  value={2}
                  label="70-79"
                  path={['demographicInformation', 'dob']}
                  reducer={ageFrom70to79YearsOld}
                  onCheckboxChange={handleCheckboxChange(setValueAge)}
                />
                <FallRiskCheckbox
                  name="fallRiskAssessment.ageFrom80YearsOld"
                  value={3}
                  label="greater than or equal to 80 years"
                  path={['demographicInformation', 'dob']}
                  reducer={ageFrom80YearsOld}
                  onCheckboxChange={handleCheckboxChange(setValueAge)}
                />
              </div>
            </div>
          </div>
          <div ref={GenderRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>GENDER:</b>
              </div>
              <div style={styles.fallriskPoint}>{pointGender}</div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.genderMale"
                  value={2}
                  label="Male"
                  path={['demographicInformation', 'sex']}
                  reducer={toMale}
                  onCheckboxChange={handleCheckboxChange(setValueGender)}
                />
                <FallRiskCheckbox
                  name="fallRiskAssessment.genderFemale"
                  value={1}
                  label="Female"
                  path={['demographicInformation', 'sex']}
                  reducer={toFemale}
                  onCheckboxChange={handleCheckboxChange(setValueGender)}
                />
              </div>
            </div>
          </div>
          <div ref={FallRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>FALL HISTORY:</b>
              </div>
              <div style={styles.fallriskPoint}>{pointFall}</div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.fallHistory"
                  value={20}
                  label="Fall within past 6 months"
                  path={['basicscreening', 'hxOfFalls']}
                  reducer={toBooleanPositive}
                  onCheckboxChange={handleCheckboxChange(setValueFall)}
                />
              </div>
            </div>
          </div>
          <div ref={MedicationRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>MEDICATIONS:</b>
              </div>
              <div style={styles.fallriskPoint}>{pointMedication}</div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.medicationOne"
                  value={1}
                  label="On 1 high fall risk medication"
                  onCheckboxChange={handleCheckboxChange(setValueMedication)}
                />
                &emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox
                  name="fallRiskAssessment.medicationTwo"
                  value={3}
                  label="On 2 high fall risk medication"
                  onCheckboxChange={handleCheckboxChange(setValueMedication)}
                />
                &emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox
                  name="fallRiskAssessment.medicationThree"
                  value={5}
                  label="On 3 or more high fall risk medication"
                  onCheckboxChange={handleCheckboxChange(setValueMedication)}
                />
              </div>
            </div>
          </div>
          <div ref={MorbidRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>CURRENT CO-MORBID CONDITIONS:</b>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  border: '1px solid gray',
                  borderBottom: '0',
                  padding: '2px',
                  width: '100%',
                  fontSize: '14px',
                  gridColumn: '2/3',
                  gridRow: '1/4',
                  paddingTop: '13px',
                }}
              >
                {pointMorbid}
              </div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox2
                  type="checkbox"
                  name="fallRiskAssessment.conditionOne"
                  value={5}
                  label="1 Co-morbid condition"
                  onCheckboxChange={handleCheckboxChange(setValueMorbid)}
                  checked={morbidCount == 1}
                />
                &emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox2
                  type="checkbox"
                  name="fallRiskAssessment.conditionTwo"
                  value={10}
                  label="2 Co-morbid condition"
                  onCheckboxChange={handleCheckboxChange(setValueMorbid)}
                  checked={morbidCount == 2}
                />
                &emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox2
                  type="checkbox"
                  name="fallRiskAssessment.conditionThree"
                  value={15}
                  label="3 or more Co-morbid condition"
                  onCheckboxChange={handleCheckboxChange(setValueMorbid)}
                  checked={morbidCount >= 3}
                />
              </div>
              <div>
                <MedicalPassportTextArea2
                  name="morbid"
                  paths={morbid}
                  reducer={onlylabel}
                  style={{
                    width: '100%',
                  }}
                  fontSize={'0.75rem'}
                  nobackground
                />
              </div>
            </div>
          </div>
          <div ref={MobilityRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>MOBILITY:</b>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  border: '1px solid gray',
                  borderBottom: '0',
                  padding: '2px',
                  width: '100%',
                  fontSize: '14px',
                  gridColumn: '2/3',
                  gridRow: '1/5',
                  paddingTop: '13px',
                }}
              >
                {pointMobility}
              </div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.mobiliyAssist"
                  value={10}
                  path={['basicscreening', 'assistance1']}
                  label="Requires assistance or supervision for mobility, transfer, or ambulation"
                  onCheckboxChange={handleCheckboxChange(setValueMobility)}
                />
                <FallRiskCheckbox2
                  name="fallRiskAssessment.unsteadyGait"
                  value={10}
                  checked={unsteadyGait}
                  label="Unsteady gait"
                  onCheckboxChange={handleCheckboxChange(setValueMobility)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox2
                  name="fallRiskAssessment.visualImpairment"
                  value={5}
                  checked={vision}
                  label="Visual impairment affecting mobility "
                  onCheckboxChange={handleCheckboxChange(setValueMobility)}
                />
                <FallRiskCheckbox2
                  name="fallRiskAssessment.auditoryImpaired"
                  value={2}
                  checked={auditory}
                  label="Auditory impairment affecty mobility"
                  onCheckboxChange={handleCheckboxChange(setValueMobility)}
                />
              </div>

              <div>
                <MedicalPassportTextArea2
                  name="VisionHearing"
                  paths={[
                    ['basicscreening', 'assistance1'],
                    ['basicscreening', 'problemBalance'],
                    ['basicscreening', 'device'],
                    ['GeneralHistory', 'vision1'],
                    ['GeneralHistory', 'vision2'],
                    ['GeneralHistory', 'vision6'],
                    ['GeneralHistory', 'vision7'],
                    ['GeneralHistory', 'hearing1'],
                    ['GeneralHistory', 'hearing2'],
                  ]}
                  reducer={onlylabel}
                  fontSize={'0.75rem'}
                  style={{
                    width: '100%',
                  }}
                  nobackground
                />
              </div>
            </div>
          </div>
          <div ref={OxygenRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>HOME OXYGEN:</b>
              </div>
              <div style={styles.fallriskPoint}>{pointOxygen}</div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.oxygenYes"
                  value={1}
                  label="Yes"
                  path={['Pulmonary', 'oxygen']}
                  onCheckboxChange={handleCheckboxChange(setValueOxygen)}
                />
                <FallRiskCheckbox
                  name="fallRiskAssessment.oxygenNo"
                  value={0}
                  label="No"
                  path={['Pulmonary', 'oxygen']}
                  reducer={toBooleanNegative}
                  onCheckboxChange={handleCheckboxChange(setValueOxygen)}
                />
              </div>
            </div>
          </div>
          <div style={styles.fallriskgrid}>
            <div
              style={{
                borderTop: '1px solid gray',
                padding: '2px',
                width: '100%',
                textAlign: 'right',
                fontSize: '14px',
              }}
            >
              <b>Pre-Admission Assessment Total &nbsp;</b>
            </div>
            <div
              style={{
                textAlign: 'center',
                border: '2px solid gray',
                borderBottom: '0',
                padding: '1px',
                width: '100%',
                fontSize: '14px',
                gridColumn: '2/3',
              }}
            >
              {total1}
            </div>
          </div>
          <div style={styles.fallriskgrid}>
            <div
              style={{
                backgroundColor: '#36454F',
                padding: '3px',
                width: '100%',
                fontSize: '14px',
                color: 'white',
              }}
            >
              <b>PATIENT PRE-ADMISSION ASSESSMENT - MEDICAL PASSPORT</b>
            </div>
            <div
              style={{
                textAlign: 'center',
                backgroundColor: '#36454F',
                padding: '3px',
                width: '100%',
                fontSize: '14px',
                color: 'white',
              }}
            >
              <b>POINTS</b>
            </div>
          </div>
          <div ref={TubeRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>TUBES & CORDS (MULTI-SELECT):</b>
              </div>
              <div style={styles.fallriskPoint}></div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.IV"
                  value={1}
                  label="IVs (1 points)"
                  onCheckboxChange={handleCheckboxChange(setValueTubes)}
                  path={['demographicInformation', 'dob']}
                /> &emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox
                  name="fallRiskAssessment.O2"
                  value={1}
                  label="O2 (1 points)"
                  onCheckboxChange={handleCheckboxChange(setValueTubes)}
                  path={['demographicInformation', 'dob']}
                />&emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox
                  name="fallRiskAssessment.SCD"
                  value={1}
                  label="SCDs (1 points)"
                  onCheckboxChange={handleCheckboxChange(setValueTubes)}
                  path={['demographicInformation', 'dob']}
                />
              </div>
            </div>
          </div>
          <div ref={BlockRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>BLOCKS / SEDATION:</b>
              </div>
              <div style={styles.fallriskPoint}></div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.regionalBlock"
                  value={5}
                  label="Regional Block (5 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueBlocks)}
                />&emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox
                  name="fallRiskAssessment.sedation"
                  value={5}
                  label="Pre-Procedure Sedation (5 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueBlocks)}
                />
              </div>
            </div>
          </div>
          <div ref={ProcedureRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>TYPE OF PROCEDURE (ONLY CHOOSE 1):</b>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  border: '1px solid gray',
                  borderBottom: '0',
                  padding: '2px',
                  width: '100%',
                  fontSize: '14px',
                  gridColumn: '2/3',
                  gridRow: '1/4',
                  paddingTop: '12px',
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.pain"
                  value={15}
                  label="Pain (15 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueBlocks)}
                />&emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox
                  name="fallRiskAssessment.kneeHipReplace"
                  value={10}
                  label="Total Knee or Hip Joint Replacement (10 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueBlocks)}
                />&emsp;&emsp;&emsp;&emsp;
                <FallRiskCheckbox
                  name="fallRiskAssessment.colonoscopy"
                  value={5}
                  label="Colonoscopy (5 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueBlocks)}
                />
              </div>
            </div>
          </div>
          <div ref={IssueRef}>
            <div style={styles.fallriskgrid}>
              <div style={styles.fallriskTitle}>
                <b>COGNITIVE OR BEHAVIORAL ISSUES (MULTI-SELECT):</b>
              </div>
              <div style={styles.fallriskPoint}></div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '13px',
                }}
              >
                <FallRiskCheckbox
                  name="fallRiskAssessment.agitated"
                  value={2}
                  label="Agitated (2 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueIssue)}
                />
                <FallRiskCheckbox
                  name="fallRiskAssessment.impulseControl"
                  value={5}
                  label="Impulse control (5 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueIssue)}
                />
                <FallRiskCheckbox
                  name="fallRiskAssessment.noncompliance"
                  value={5}
                  label="Noncompliance (5 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueIssue)}
                />
                <FallRiskCheckbox
                  name="fallRiskAssessment.lackUnderstandingLimit"
                  value={5}
                  label="Lack of Understanding limitations (5 points)"
                  path={['demographicInformation', 'dob']}
                  onCheckboxChange={handleCheckboxChange(setValueIssue)}
                />
              </div>
            </div>
            <div style={styles.fallriskgrid}>
              <div
                style={{
                  padding: '3px',
                  width: '100%',
                  fontSize: '14px',
                  textAlign: 'right',
                }}
              >
                <b>Pre-Procedure Assessment Total&nbsp;</b>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  border: '2px solid gray',
                  padding: '1px',
                  width: '100%',
                  fontSize: '14px',
                  gridColumn: '2/3',
                  gridRow: '1/3',
                }}
              ></div>
            </div>
            <div style={styles.fallriskgrid}>
              <div
                style={{
                  padding: '3px',
                  width: '100%',
                  fontSize: '14px',
                  textAlign: 'right',
                }}
              >
                <b>Combined Total &nbsp;</b>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  border: '2px solid gray',
                  borderTop: '0',
                  padding: '1px',
                  width: '100%',
                  fontSize: '14px',
                  gridColumn: '2/3',
                  gridRow: '1/3',
                }}
              ></div>
            </div>
          </div>
          <div style={{ fontSize: '14px' }}>
            <b>Patient meets criteria to implement High-Risk Fall Precautions:</b>
            <br />
            <div style={{ display: 'grid', gridTemplateColumns: '25% 30% 45%' }}>
              <div>
                <input type="checkbox" name="greater20" checked={total >= 20} /> Score of 20 points or greater
              </div>
              <div>
                <MedicalPassportCheckbox
                  name="fallRiskAssessment.fallHistory2"
                  label="Fall History within last 6 months"
                  path={['basicscreening', 'hxOfFalls']}
                  reducer={toBooleanPositive}
                />
              </div>
            </div>
            <br />
            <b>
              Preop Nurse Signature: ___________________________________________ Date: ________________ Time: _____________
            </b>
          </div>
        </div>
      </div>
      <footer style={{ display: 'grid', gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%', fontSize: '11px', height: '20px' }}>
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>{')'} <span style={{ fontSize: '14px', marginLeft: '9rem' }}><b>Ospitek, Inc</b></span></div>
      </footer>
    </div>
  );
};

export default Page12;
