import { Room } from '../../../../../../types/Room';
import { ProcedureType } from '../../../../../../types/Procedure';
import { AnesthesiaTypes } from '../../anesthesiologist/AnesthesiaType';
import { PatientType } from '../../../../../../types/Patient';
import Physician from '../../../../../../types/Physician';
import Anesthesiologist from '../../../../../../types/Anesthesiologist';
import Vendor, { Representative } from '../../../../../../types/Vendor';

export type FormParams<T extends FormDetails> = {
  value: T;
  setValue: (value: T) => void;
};

export type FormDetails =
  | PatientDetails
  | ProcedureDetails
  | StaffDetails
  | VendorDetails
  | EquipmentDetails
  | InsuranceDetails;

export const sexTypes = ['Female', 'Male', 'Unknown'];
export const bilaterallyTypes = ['None', 'Left', 'Right', 'Bilaterality', 'Upper', 'Lower'];
//@ts-ignore
const bilaterallyTypesConst = bilaterallyTypes as const;
export type Bilaterally = typeof bilaterallyTypesConst[number];
export const patientTypes = Object.values(PatientType);

type Optional<T> = T | undefined | null;

export type PatientDetails = {
  basicInfo: {
    firstName: {
      value: string;
      error: MaybeValid<RequiredFieldValidation>;
    };
    lastName: {
      value: string;
      error: MaybeValid<RequiredFieldValidation>;
    };
    medicalRecord: {
      value: string;
      error: MaybeValid<RequiredFieldValidation>;
    };
    dateOfBirth: {
      value: Date | null;
      error: MaybeValid<RequiredFieldValidation>;
    };
    sex: {
      value: string | null;
    };
    diagnosis: {
      value: string | null;
    };
    allergies: {
      value: string | null;
    };
    note: {
      value: string | null;
    };
    patientAddress: {
      value: string | null;
    };
  };
  contactInfo: {
    familyPhoneNumber: {
      value: string | null;
    };
    patientPhoneNumber: {
      value: string | null;
    };
    careTakerPhoneNumber: {
      value: string | null;
    };
    homePhoneNumber: {
      value: string | null;
    };
    patientEmail: {
      value: string | null;
    };
  };
};

export type ProcedureTypesPicked = Partial<Pick<ProcedureType, 'name' | 'id'>>;
export type RoomPicked = Partial<Pick<Room, 'name' | 'id' | 'type'>>;
export type PhysicianPicked = Partial<Pick<Physician, 'id' | 'name' | 'email' | 'notificationNumbers'>>;
export type AnesthesiologistPicked = Partial<Pick<Anesthesiologist, 'id' | 'name' | 'email' | 'phoneNumber'>>;
export type VendorPicked = Partial<Pick<Vendor, 'id' | 'name' | 'representatives'>>;
export type RepresentativePicked = Partial<Pick<Representative, 'id' | 'name' | 'email' | 'phoneNumber'>>;

export type ProcedureDetails = {
  room: {
    id: {
      value: RoomPicked['id'];
    };
    name: {
      value: RoomPicked['name'];
      error: MaybeValid<RequiredFieldValidation>;
    };
    type: {
      value: RoomPicked['type'];
    };
  };
  procedureType: {
    name: {
      value: ProcedureTypesPicked['name'];
      error: MaybeValid<RequiredFieldValidation>;
    };
    id: {
      value: ProcedureTypesPicked['id'];
    };
  };
  anesthesiaType: {
    value: AnesthesiaTypes;
    error: MaybeValid<RequiredFieldValidation>;
  };
  cptCodes: {
    value: string | null;
  };
  bilaterally: {
    value: Bilaterally | null;
  };
  timeInfo: {
    dateOfService: { value: string; error: MaybeValid<RequiredFieldValidation> };
    timeRange: {
      startTime: { value: string; error: MaybeValid<RequiredFieldValidation> };
      duration: { value: number; error: MaybeValid<RequiredFieldValidation> };
    };
  };
  visit: {
    visitType: {
      value: Optional<PatientType>;
    };
    room?: {
      // is this Room?
      value: Optional<string>;
    };
  };
  procedurePriority:{
    value: number | null;
  }
};

export type StaffDetails = {
  physician: {
    name: {
      value: PhysicianPicked['name'];
      error: MaybeValid<RequiredFieldValidation>;
    };
    notificationNumbers: {
      value: PhysicianPicked['notificationNumbers'];
    };
    email: {
      value: PhysicianPicked['email'];
      error: MaybeValid<EmailPatternValidation>;
    };
    id: {
      value: PhysicianPicked['id'];
    };
  };
  anesthesiologist: {
    name: {
      value: AnesthesiologistPicked['name'];
    };
    phoneNumber: {
      value: AnesthesiologistPicked['phoneNumber'];
    };
    email: {
      value: AnesthesiologistPicked['email'];
    };
    id: {
      value: AnesthesiologistPicked['id'];
    };
  };
};

export type VendorDetails = {
  vendorInfo: {
    name: {
      value: VendorPicked['name'];
      error: MaybeValid<RequiredFieldValidation>;
    };
    id: {
      value: VendorPicked['id'];
    };
    representatives: {
      value: VendorPicked['representatives'];
    };
  };
  implant: {
    value?: string | null;
  };
  price: {
    value?: string | null;
  };
  representative: {
    name: {
      value: RepresentativePicked['name'];
      error: MaybeValid<RequiredFieldValidation>;
    };
    email: {
      value: RepresentativePicked['email'];
      error: MaybeValid<RequiredFieldValidation>;
    };
    phoneNumber: {
      value: RepresentativePicked['phoneNumber'];
    };
    id: {
      value: RepresentativePicked['id'];
    };
  };
};

export type EquipmentDetails = string[];

export type InsuranceDetails = {
  insuranceName: {
    value: string;
  };
  policyName: {
    value: string;
  };
  // upload missing
};

export type ProcedureForm = {
  patient: Optional<PatientDetails>;
  procedure: Optional<ProcedureDetails>;
  staff: Optional<StaffDetails>;
  vendor: Optional<VendorDetails>;
  equipment: Optional<EquipmentDetails>;
  insurance: Optional<InsuranceDetails>;
};

type MaybeValid<T> = T | undefined;
type RequiredFieldValidation = 'This field is required.';
type EmailPatternValidation = 'Not valid email pattern.';

export type ScheduleProcedureVars = {
  operationRoomId: number;
  date: string;
  startTime: string;
  duration: number;
  physicianId: number;
  anesthesiologistId: Optional<number>;
  anesthesiaType: string;
  patientProviderId: string;
  patientName: string;
  patientDOB: Optional<Date>;
  patientSex: Optional<string>;
  procedureTypeId: number;
  procedureType: string;
  diagnosis: Optional<string>;
  equipment: Optional<string>;
  cptCodes: Optional<string>;
  bilaterally: Optional<string>;
  visitType: Optional<string>;
  room: Optional<string>;
  insuranceName: Optional<string>;
  policyName: Optional<string>;
  notes: Optional<string>;
  allergy: Optional<string>;
  patientHomePhone: Optional<string>;
  patientCellPhone: Optional<string>;
  caretakerCellPhone: Optional<string>;
  familyCellPhone: Optional<string>;
  vendorId: Optional<number>;
  implant: Optional<string>;
  price: Optional<string>;
  representative: Optional<Representative>;
  patientEmailAddress: Optional<string>;
  patientAddress: Optional<string>;
  procedurePriority: Optional<number>;
};
