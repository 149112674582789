import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';
import ChangePassword from '../ChangePassword';
import Header from '../admin/Header';
import Footer from '../admin/Footer';
import { light as lightTheme } from '../../../../theme';
import AssignmentPatients from '../../../entities/patient/AssignmentPatients';

import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import { withSession } from '../../../../state/Session';
import get from 'lodash/fp/get';
import Training from '../../training/Training';
import { muiThemeLight } from '../../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import { AccessibilityNew, Theaters, VideoLabel } from '@material-ui/icons';
import { useScope } from '../../../../hooks/useScope';

const getRoutes = (organizationId, hasPostOp) => [
  {
    path: `/kiosk/${organizationId}/monitor`,
    to: `/kiosk/${organizationId}/monitor`,
    target: '_blank',
    label: 'Operations Screen',
    icon: <VideoLabel />,
  },
  {
    path: `/patients`,
    to: `/patients`,
    label: 'Patients',
    icon: <AccessibilityNew />,
    component: AssignmentPatients(hasPostOp),
  },
  {
    path: `/training`,
    to: `/training`,
    label: 'Video Tutorials',
    icon: <Theaters />,
    component: Training,
  },
];

const FrontSidebar = () => {
  const scope = useScope();
  const organizationId = scope?.hospital?.id;
  const hasPostOp = scope?.hospital?.modules?.hasPostop;
  const links = getRoutes(organizationId, hasPostOp);
  return <Sidebar Header={Header} Footer={Footer} links={links} />;
};

const Homepage = ({ patientNameFormat }) => {
  const scope = useScope();
  const organizationId = scope?.hospital?.id;
  const hasPostOp = scope?.hospital?.modules?.hasPostop;
  return (
    <ThemeProvider theme={lightTheme}>
      <MUIThemeProvider theme={muiThemeLight}>
        <style>
          {`body {
          background: ${muiThemeLight.palette.background.default};
        }`}
        </style>
        <CssBaseline />
        <PatientNameFormatContext.Provider value={patientNameFormat}>
          <OmniSearchProvider>
            <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
            <Page Sidebar={FrontSidebar}>
              <MainContainer switchControl>
                <Switch>
                  {getRoutes(organizationId, hasPostOp).map(({ path, component }, index) => (
                    <Route key={path || index} path={path} component={component} />
                  ))}
                  <Route path="/change-password" component={ChangePassword} />
                  <Route render={() => <Redirect to="/patients" />} />
                </Switch>
              </MainContainer>
            </Page>
          </OmniSearchProvider>
        </PatientNameFormatContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default withSession(get('session.account.metadata'))(Homepage);
