import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import MedicalPassportField from '../../components/MedicalPassportField';
import { age65AndOlderNo, age65AndOlderYes, toBooleanNegative } from '../../../../../../questionnaire/fallRiskReducers';
import toSex from '../../../../../../questionnaire/toSex';
import toAge from '../../../../../../questionnaire/toAge';
import FallRiskCheckbox from '../../components/FallRiskCheckbox';

function initializeValues(rootRef, setChecked) {
  const root = rootRef.current;

  if (!root) {
    return;
  }

  const checkboxes = root.querySelectorAll('input[type="checkbox"]');

  setChecked(
    [...checkboxes].reduce((acc, checkbox) => {
      const value = checkbox.checked ? parseInt(checkbox.value) : 0;
      return { ...acc, [checkbox.name]: isFinite(value) ? value : 1 };
    }, {})
  );
}

const Page7 = ({ showQRCode, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;

  const [values1, setValues1] = useState({});

  const total1 = useMemo(() => Object.values(values1).reduce((acc, value) => acc + value, 0), [values1]);

  const root1Ref = useRef(undefined);

  useEffect(() => {
    initializeValues(root1Ref, setValues1);
  }, []);

  const handleCheckboxChange = setValues => (value, name) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div id="page7" className="page">
      <div style={styles.center}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div />
          <h2 style={styles.underlineHeading}>Fall Risk Assessment</h2>
          <div>
            {showQRCode && (
              <img
                alt="Barcoded value"
                src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
              />
            )}
          </div>
        </div>
        <div
          style={{
            marginBottom: '0.4rem',
            width: '100%',
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
            <MedicalPassportField name="dob" label="DOB" path={['demographicinformation', 'dob']} />
            <MedicalPassportField name="procedure.patient.providerId" label="MRN" />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
            <MedicalPassportField name="sex" label="Sex" path={['demographicinformation', 'sex']} reducer={toSex} />
            <MedicalPassportField name="age" label="Age" path={['demographicinformation', 'dob']} reducer={toAge} />
            <MedicalPassportField name="procedure.serviceTime" label="DOS" />
          </div>
        </div>
      </div>
      <div>
        <div ref={root1Ref}>
          <div style={styles.oneColumn}>Fall Risk Assessment</div>
          <div style={styles.threeNotEqualColumns3}>
            <div style={{ textAlign: 'center', padding: '0.5rem', borderRight: '1px solid black' }}>Risk Factor(s)</div>
            <div style={{ textAlign: 'center', padding: '0.5rem', borderRight: '1px solid black' }}>Scale</div>
            <div style={{ textAlign: 'center', padding: '0.5rem' }}>Score</div>
          </div>
          <div style={styles.threeNotEqualColumns3}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Age: 65 and older
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.age65AndOlderYes"
                label="Yes"
                value={1}
                path={['demographicinformation', 'dob']}
                reducer={age65AndOlderYes}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              1
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="fallRiskAssessment.age65AndOlderNo"
                label="No"
                value={0}
                path={['demographicInformation', 'dob']}
                reducer={age65AndOlderNo}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
          <div style={styles.threeNotEqualColumns3}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              <div style={{ textAlign: 'center' }}>History of Falls* (within 6 month)</div>
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.historyOfFallsYes"
                label="Yes"
                value={7}
                path={['basicscreening', 'q1']}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
                color: value?.historyOfFallsYes ? 'red' : 'black',
              }}
            >
              7
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <MedicalPassportCheckbox
                name="fallRiskAssessment.historyOfFallsNo"
                label="No"
                value={0}
                path={['basicscreening', 'q1']}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
          <div style={styles.threeNotEqualColumns3}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Ambulatory Aid**
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.ambulatoryAidYes"
                label="Yes : Crutches / Cane / walker"
                value={7}
                path={['basicscreening', 'device']}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              7
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="fallRiskAssessment.ambulatoryAidNo"
                label="No : None"
                value={0}
                path={['basicscreening', 'device']}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
          <div style={styles.threeNotEqualColumns3}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Secondary Diagnosis
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.secondaryDiagnosisYes"
                label="Yes : IF MORE than one medical diagnosis is listed"
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              1
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="fallRiskAssessment.secondaryDiagnosisNo"
                label="No : IF NO more than one medical diagnosis listed"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '0.25rem',
              }}
            >
              0
            </div>
          </div>
          <div style={styles.threeNotEqualColumns3}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Gait / Transferring***
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.gaitTransferringYes"
                label="Yes: Weak or Unsteady/sensory Impairment (i.e.) Neuropathy, Visual, Auditory, etc))/Immobile, assistive device"
                value={7}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              7
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="fallRiskAssessment.gaitTransferringNo"
                label="No: Normal"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
          <div style={styles.threeNotEqualColumns3}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Medications (Incl. Opiates, anticonvulsants, hypnotics, sedatives and psychotropics) Check Med Rec
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.fallRiskMedicstionsYes"
                label="Yes"
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              1
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="fallRiskAssessment.fallRiskMedicstionsNo"
                label="No"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '0.25rem',
              }}
            >
              0
            </div>
          </div>
          <div style={styles.threeNotEqualColumns3}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Mental Status
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.mentalStatusYes"
                label="Yes: Lack of understanding of physical and cognitive limitations"
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              1
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="fallRiskAssessment.mentalStatusNo"
                label="No: Oriented to Own Ability"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
        </div>
        <div style={styles.twoNotEqualColumns4}>
          <div style={{ textAlign: 'right', padding: '0.5rem', borderRight: '1px solid black' }}>Total Score:</div>
          <div style={{ textAlign: 'center', padding: '0.5rem' }}>
            <MedicalPassportField name="fallRiskAssessment.totalScore" value={total1} />
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', padding: '0.5rem' }}>
        To obtain the Fall Risk Score, add the score from each category:
      </div>
      <div>
        <div style={{ ...styles.oneColumn, fontWeight: 'bold' }}>Fall Risk Score Interpretation</div>
        <div style={styles.twoEqualColumns3}>
          <div style={{ padding: '0.5rem', borderRight: '1px solid black' }}>High Risk</div>
          <div style={{ padding: '0.5rem' }}>7 or more</div>
        </div>
        <div style={{ ...styles.twoEqualColumns3, borderBottom: '1px solid black' }}>
          <div style={{ padding: '0.5rem', borderRight: '1px solid black' }}>Universal Fall Risk</div>
          <div style={{ padding: '0.5rem' }}>0-6</div>
        </div>
      </div>
      <div style={styles.signatureBox1}>
        <div style={{ paddingTop: '1.5rem' }}>PreOp Nurse Signature: ______________________________</div>
        <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>Date/Time: _______________________</div>
      </div>
      <h2 style={{ textAlign: 'center', marginTop: 160, fontSize: 18, fontWeight: 'normal' }}>Universal Fall Risk</h2>
    </div>
  );
};

export default Page7;
