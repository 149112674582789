import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from '@apollo/client';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

export const AutocompleteInputSetter = <T extends { name: string }>({
  list,
  onEnterCreateOption,
  onBlurCreateOption,
  value,
  filter,
  onChange,
  clearInput,
  clearAfterSelect,
  clearOnClick,
  ...rest
}: {
  list: any;
  onEnterCreateOption?: (name: string) => Promise<any>;
  onBlurCreateOption?: (name: string) => Promise<any>;
  filter?: (value: T) => void;
  clearInput?: () => void;
  clearAfterSelect?: boolean;
  clearOnClick?: boolean;
} & TextFieldProps) => {
  const { data } = useQuery(list);

  const [input, setInput] = useState('');

  useEffect(() => {
    //@ts-ignore
    setInput(value);
  }, [value]);

  const unpackedData = useMemo(() => (!!data ? Object.values(data)[0] : []) as T[], [data]).filter(value =>
    filter ? filter(value) : true
  );

  return (
    <Autocomplete
      options={unpackedData}
      getOptionLabel={(option: any) => option.name || ''}
      renderInput={(params: any) => (
        <TextField
          onChange={event => setInput(event.target.value)}
          variant="filled"
          margin="dense"
          fullWidth
          onClick={() => clearOnClick && setInput('')}
          {...params}
          {...rest}
        />
      )}
      onChange={(event, value) => {
        //@ts-ignore
        onChange?.(value);
      }}
      inputValue={input?.replace('\n', '') ?? ''}
      value={value ?? ''}
      onBlur={async () => {
        const shouldCreate = input && onBlurCreateOption && !unpackedData?.find(e => e?.name === input);
        if (shouldCreate) {
          const option = await onBlurCreateOption?.(input);
          //@ts-ignore
          onChange?.(option);
        } else {
          if (!!clearInput && !unpackedData.find((date: any) => date?.name === value)) clearInput();
        }
      }}
      onKeyPress={async event => {
        if (event.key === 'Enter') {
          const shouldCreate = input && onEnterCreateOption && !unpackedData?.find(e => e?.name === input);
          if (shouldCreate) {
            const option = await onEnterCreateOption?.(input);
            //@ts-ignore
            onChange?.(option);
          }
          if (clearAfterSelect) setInput('');
        }
      }}
    />
  );
};
