export const procedureFragment = `
  fragment ProcedureData on Procedure {
    id
    status
    startTime
    startTimeText
    endTimeText
    duration
    orId
    or
    isCanceled
    physicianId
    physicianHstId
    physicianName
    physicianNameHST
    physicianColor
    patientId
    patientHstId
    patientName
    patientNameUnformatted
    patientInitials
    patientDateOfBirth
    patientDateOfBirthISO
    patientAge
    patientSex
    patientHomePhone
    patientCellPhone
    caretakerCellPhone
    familyCellPhone
    patientEmailAddress
    patientAddress
    procedureTypeId
    procedureType
    procedureMod
    diagnosis
    equipment
    cptCodes
    bilaterally
    visitType
    room
    insuranceName
    policyName
    anesthesiologistId
    anesthesiologistHstId
    anesthesiologistName
    anesthesiaType
    allergy
    notes
    visit
    preferredLanguage
    caretakerThread {
      id
      hasUnreadMessages
    }
    patientThread {
      id
      hasUnreadMessages
    }
    vendorRepresentative {
      id
      vendor {
        name
      }
      implant
      price
      representative {
        name
        email
        phoneNumber
      }
    }
    physician {
      id
      name
      color
      notificationNumbers
      email
    }
    patient {
      id
      name
      nameUnformatted
      type
      initials
      status
      room {
        id
        type
        name
      }
      bed
    }
    anesthesiologist {
      id
      name
      phoneNumber
      email
    }
    assignedVendors {
      id
      procedureId
      vendor {
        id
        name
      }
      shiftState
    }
  }
`;
