import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import toBMI, { calculateBMI } from '../../../../../../questionnaire/toBMI';
import toWeight from '../../../../../../questionnaire/toWeight';
import toHeight from '../../../../../../questionnaire/toHeight';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toStringWithoutQuestions from '../../../../../../questionnaire/toStringWithoutQuestions';
import getAgeAndBmi from './pageUtils';
import { getcontent } from './contentlimit';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  vascular,
  hepatic,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
  drugs,
} from '../paths';
import toHighlightsString3 from '../../../../../../questionnaire/toHighlightsString3';
import toHighlightsString5 from '../../../../../../questionnaire/toHighlightsString5';
import toStringWithoutQuestions3 from '../../../../../../questionnaire/toStringWithoutQuestions3';
import toStringWithoutQuestions2 from '../../../../../../questionnaire/toStringWithoutQuestions2';
import { useValue } from '../../ValueContext';
import { get } from 'lodash';


const Page6 = ({ showQRCode, hospitalName, value, defaultValue, lastPreOpCompletedEvent, ...props }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaireAnswers } = useValue();

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);
  const bmi = calculateBMI(
    parseInt(defaultValue?.demographicInformation?.bmi?.weightLb, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightFt, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightIn, 10)
  );


  const surgery = getcontent(toHighlightsString5, [['priorSurgeries']], 285);
  const medication = getcontent(toHighlightsString5, [['medications']], 215);
  const drug = getcontent(toStringWithoutQuestions, drugs, 30)?getcontent(toStringWithoutQuestions, drugs, 30):'N/A';
  const allergy = getcontent(toHighlightsString5, [['allergies']], 200);

  const cardiaclist = getcontent(toStringWithoutQuestions3, cardiovascular, 150);
  const pulmonarylist = getcontent(toStringWithoutQuestions3, pulmonary, 150);
  const neurologicallist = getcontent(toStringWithoutQuestions3, neurological, 150);
  const hematologicallist = getcontent(toStringWithoutQuestions3, vascular, 150);
  const endocrinelist = getcontent(toStringWithoutQuestions3, endocrine, 150);
  const musculolist = getcontent(toStringWithoutQuestions3, musculoskeletal, 150);
  const otherlist = getcontent(toStringWithoutQuestions3, other, 150);

  const rideEmgergencySame = get(questionnaireAnswers, 'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[0]');
  let riderName = ''
  let riderRelationship = ''
  let riderPhone = ''
  let riderOtherPhone = ''
  if (rideEmgergencySame == false) {
    riderName = get(questionnaireAnswers, 'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_name.text');
    riderRelationship = get(questionnaireAnswers, 'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_relationship.option');
    riderPhone = get(questionnaireAnswers, 'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_phone.text');
    riderOtherPhone = get(questionnaireAnswers, 'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_otherphone.text');
  } else {
    riderName = get(questionnaireAnswers, 'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_name.text');
    riderRelationship = get(questionnaireAnswers, 'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_relationship.option');
    riderPhone = get(questionnaireAnswers, 'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_MobilePhone.text');
    riderOtherPhone = get(questionnaireAnswers, 'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_HomePhone.text');
  }

  return (
    <div id="page3" className="page" style={{ height: '100%', paddingBottom: '0' }}>
      <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridGap: '1rem',
            alignContent: 'start',
            paddingTop: '1rem',
            fontSize: '14px',
          }}
        >
          <div style={styles.div}>
            <b>
              <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '14px' }}>{(hospitalName || '').toUpperCase()}</div>
              <div style={{ fontSize: '20px', paddingBottom: '0' }}>Nursing Assessment</div>
            </b>
            <div style={{ fontSize: '12px' }}>
              <MedicalPassportTextArea2 name="procedure.name" label="Procedure" style={{ display: 'flex' }} fontSize={12} nobackground />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              {showQRCode && (
                <img
                  alt="Barcoded value"
                  src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
                />
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                border: '1px solid black',
                padding: '0.2rem',
                lineHeight: '15px',
              }}
            >
              <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} nobackground />
              <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '40% 60%',
                  alignContent: 'start',
                }}
              >
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} nobackground />
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                  fontSize={12}
                  nobackground
                />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12} nobackground />
              </div>
              <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
            </div>
          </div>
        </div>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '35% 65%',
          borderBottom: 0,
        }}>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '12px',
              }}
            >
              <MedicalPassportField
                name="ht"
                label="Ht"
                path={['basicscreening', 'bmi']}
                reducer={toHeight}
                fontSize={12}
                nobackground
              />
              <MedicalPassportField
                name="wt"
                label="Wt"
                path={['basicscreening', 'bmi']}
                reducer={toWeight}
                fontSize={12}
                nobackground
              />
              <MedicalPassportField
                name="bmi"
                label="BMI"
                path={['basicscreening', 'bmi']}
                reducer={toBMI}
                warning={bmi !== '-' ? bmi > 40 : false}
                fontSize={12}
                nobackground
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '12px',
              }}
            >
              <MedicalPassportField name="drugHxPage4" label="Drug Hx" value={drug} fontSize={12} nobackground />
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '10% 90%',
                fontSize: '12px',
              }}
            >
              <div>Smoking:</div>
              <MedicalPassportTextArea2
                name="smokingPage3"
                paths={[
                  ['basicscreening', 'smokingStatus'],
                  ['basicscreening', 'smoking', 'yes'],
                ]}
                reducer={toHighlightsString3}
                fontSize={12}
                placeholder={'N/A'}
                nobackground
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '10% 90%',
                fontSize: '12px',
              }}
            >
              <div>ETOH:</div>
              <div>
                <MedicalPassportTextArea2
                  name="etohpagae3"
                  paths={[
                    ['basicscreening', 'drinking'],
                    ['basicscreening', 'alcholAbuse'],
                  ]}
                  reducer={toStringWithoutQuestions2}
                  fontSize={12}
                  placeholder={'N/A'}
                  nobackground
                />
              </div>
            </div>
          </div>
        </div>
        <div style={styles.threeEqualColumns3}>
          <div
            style={{
              borderBottom: '1px solid black',
              marginBottom: '5px',
              fontSize: '14px',
              marginRight: '.3rem',
            }}
          >
            <b>SURGICAL HISTORY:</b>
          </div>
          <div
            style={{
              borderBottom: '1px solid black',
              marginBottom: '5px',
              fontSize: '14px',
              marginRight: '.3rem',
            }}
          >
            <b>CURRENT MEDICATIONS:</b>
          </div>
          <div
            style={{
              borderBottom: '1px solid black',
              marginBottom: '5px',
              fontSize: '14px',
              marginRight: '.3rem',
            }}
          >
            <b>ALLERGIES/SENSITIVITIES:</b>
          </div>
          <div
            style={{
              fontSize: '12px',
              marginRight: '.3rem',
              borderRight: '1px solid lightgray',
            }}
          >
            <MedicalPassportTextArea2 name="surgeries1" value={surgery} fontSize={12} nobackground style={{ minHeight: '160px' }} />
          </div>
          <div
            style={{
              fontSize: '12px',
              marginRight: '.3rem',
              borderRight: '1px solid lightgray',
            }}
          >
            <MedicalPassportTextArea2 name="medications1" value={medication} fontSize={12} nobackground style={{ minHeight: '160px' }} />
          </div>
          <div
            style={{
              fontSize: '12px',
              marginRight: '.3rem',
              borderRight: '1px solid lightgray',
            }}
          >
            <MedicalPassportTextArea2 name="allergies1" value={allergy} fontSize={12} nobackground style={{ minHeight: '160px' }} />
          </div>
        </div>
        <div style={{ fontSize: '14px', borderBottom: '1px solid black', marginBottom: '2px' }}>
          <b>Hx of Anesthesia Complications</b>
        </div>
        <MedicalPassportTextArea2
          name="page4.hxAnesthesiaComplications"
          path={['anesthesia complications']}
          reducer={toStringWithoutQuestions2}
          fontSize={'12px'}
          style={{ minHeight: '35px' }}
          nobackground
        />
        <div style={{ fontSize: '14px' }}>
          <b>REVIEW OF SYSTEMS</b>
        </div>
        <section
          style={{
            breakInside: 'avoid',
          }}
        >
          <div
            style={{
              border: '1px solid black',
              lineHeight: '.8em',
              borderLeft: 0,
              borderRight: 0,
              borderTop: 0,
            }}
          >
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Cardiovascular
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.cardiovascular"
                  value={cardiaclist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={'12px'}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Pulmonary
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.pulmonary"
                  value={pulmonarylist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={'12px'}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Renal
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.renal"
                  paths={renal}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Hepatic
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.hepatic"
                  paths={hepatic}
                  style={{ width: '100%', minHeight: '14px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Neurological
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.neurological"
                  value={neurologicallist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Gastrointestinal
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.gastrointestinal"
                  paths={gastrointestinal}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Hematological
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.hematological"
                  value={hematologicallist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Endocrine/Metabolic
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.endocrine2"
                  value={endocrinelist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Musculoskeletal
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.musculoskeletal"
                  value={musculolist}
                  background="none"
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Psychiatric
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.psychiatric"
                  paths={psychiatric}
                  background="none"
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                OB/Gyn
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.gyn"
                  paths={gynaecological}
                  reducer={toStringWithoutQuestions3}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '.5em',
                  paddingTop: '.2rem',
                  fontSize: '14px',
                }}
              >
                Other
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0.1rem',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.other"
                  value={otherlist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={12}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
          </div>
        </section>

        <div style={{ fontSize: '14px' }}>
          <div style={styles.darkGreyBackground}>PRE-OP INSTRUCTIONS / CHECKLIST</div>
        </div>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '54% 46%',
          borderBottom: 0,
        }}>
          <div
            style={{
              fontSize: '0.75rem',
              marginTop: '.1rem',
              marginRight: '.5rem',
            }}
          >
            <div style={styles.twoEqualColumnsNoBorder}>
              <MedicalPassportField
                name="page4.arrivalTime"
                label="Arrival Time"
                fontSize={'.75rem'}
                nobackground
                underline
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '16% 16% 16% 15% 14% 23%',
                borderBottom: 0,
                marginTop: '.15rem',
                fontSize: '.75rem',
              }}
            >
              <div>Spoke to:</div>
              <MedicalPassportCheckbox name="page4.patientBox" label="Patient" />
              <MedicalPassportCheckbox name="page4.SpouseBox" label="Spouse" />
              <MedicalPassportCheckbox name="page4.parentBox" label="Parent" />
              <MedicalPassportCheckbox name="page4.otherBox" label="Other" />
              <MedicalPassportField name="page4.otherField" fontSize={'.75rem'} nobackground underline />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '40% 60%',
                borderBottom: 0,
                marginTop: '.15rem',
                fontSize: '.75rem',
              }}
            >
              <MedicalPassportCheckbox name="page4.verified" label="Procedure/Site Verified" />
              <MedicalPassportCheckbox name="page4.directionsProvided" label="Directions Provided" />
            </div>
            <MedicalPassportCheckbox name="page4.NPOmidnight" label="NPO past midnight." />
            <MedicalPassportCheckbox name="page4.gumMint" label="NO gum or mints after midnight." />
            <MedicalPassportCheckbox
              name="page4.medAM"
              label="Take all seizure, asthma and heart and BP meds except Ace inhibitors with a sip of water in the am."
            />
            <MedicalPassportCheckbox
              name="page4.bloodThinner"
              label="Blood thinners - patient to check with Dr. for instructions"
            />
            <MedicalPassportCheckbox
              name="page4.mustRide"
              label="Patient must have a ride home - CANNOT DRIVE OR GO IN A CAB ALONE."
            />
          </div>
          <div
            style={{
              fontSize: '.75rem',
              marginTop: '.15rem',
              marginLeft: '.3rem',
            }}
          >
            <MedicalPassportCheckbox
              name="page4.valueables"
              label="Leave all valuables including watches, rings, earrings, piercings, bracelets, contact lens at home. No makeup"
            />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '40% 60%',
                borderBottom: 0,
                marginTop: '.15rem',
              }}
            >
              <MedicalPassportCheckbox name="page4.photoID" label="Bring photo ID and insurance cards" />
              <MedicalPassportCheckbox name="page4.Cataracts" label="Cataracts: Bring eye drops" />
            </div>
            <div
              style={{
                marginTop: '.15rem',
              }}
            >
              Special Patient Needs:
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '23% 13% 24% 40%',
                borderBottom: 0,
                marginTop: '.15rem',
                marginLeft: '1rem',
              }}
            >
              <MedicalPassportCheckbox name="page4.interpreter" label="Interpreter" />
              <MedicalPassportCheckbox name="page4.ASL" label="ASL" />
              <MedicalPassportCheckbox name="page4.wheelchair" label="Wheelchair" />
              <MedicalPassportCheckbox name="page4.caretaker" label="Caretaker/POA" />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '4% 96%',
                borderBottom: 0,
                marginTop: '.15rem',
                marginLeft: '1rem',
              }}
            >
              <MedicalPassportCheckbox name="page4.instructionsBox" />
              <MedicalPassportTextArea2
                name="page4.instructions"
                label="Instructions"
                fontSize={'.75rem'}
                style={{ borderBottom: '1px solid black' }}
                nobackground
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '22% 18% 22% 20% 18%',
            borderBottom: 0,
            fontSize: '0.75rem',
            marginTop: '1rem',
            marginBottom: '0.5rem',
          }}
        >
          <MedicalPassportField
            name="page4.rideHome"
            label="Ride Home"
            value={riderName}
            fontSize={12}
            nobackground
            underline
          />
          <MedicalPassportField
            name="page4.rideHomePhone"
            label="Phone"
            value={riderPhone}
            fontSize={12}
            nobackground
            underline
          />
          <MedicalPassportField
            name="page4.rideHomePhone"
            label="Other Phone"
            value={riderOtherPhone}
            fontSize={12}
            nobackground
            underline
          />
          <MedicalPassportField
            name="page4.rideHomeCI"
            label="Caller Initials"
            fontSize={'.75rem'}
            nobackground
            underline
          />
          <MedicalPassportField name="page4.rideHomeDate" label="Date/Time" fontSize={'.75rem'} nobackground underline />
        </div>
      </div>
      <footer style={{ display: 'grid', gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%', fontSize: '11px', height: '20px' }}>
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>{')'} <span style={{ fontSize: '14px', marginLeft: '9rem' }}><b>Ospitek, Inc</b></span></div>
      </footer>
    </div>
  );
};

export default Page6;
