import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import toStringWithoutQuestions3 from '../../../../../../questionnaire/toStringWithoutQuestions3';
import getAgeAndBmi from './pageUtils';
import { cardiovascular, vascular, endocrine, musculoskeletal, neurological, pulmonary, drugs } from '../paths';
import { additionalcontent } from './contentlimit';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';

const Page5 = ({ showQRCode, hospitalName, value, defaultValue, lastPreOpCompletedEvent, ...props }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  const cardiaclist = additionalcontent(toStringWithoutQuestions3, cardiovascular, 150);
  const pulmonarylist = additionalcontent(toStringWithoutQuestions3, pulmonary, 150);
  const neurologicallist = additionalcontent(toStringWithoutQuestions3, neurological, 150);
  const hematologicallist = additionalcontent(toStringWithoutQuestions3, vascular, 150);
  const endocrinelist = additionalcontent(toStringWithoutQuestions3, endocrine, 150);
  const musculolist = additionalcontent(toStringWithoutQuestions3, musculoskeletal, 150);
  const drug = additionalcontent(toStringWithoutQuestions3, drugs, 20);

  return (
    <div id="page5" className="page" style={{ height: '100%', paddingBottom: '0' }}>
      <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridGap: '1rem',
            alignContent: 'start',
            marginBottom: '1rem',
            paddingTop: '1rem',
            fontSize: '14px',
          }}
        >
          <div style={styles.div}>
            <b>
              <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '14px' }}>{(hospitalName || '').toUpperCase()}</div>
              <div style={{ fontSize: '20px', paddingBottom: '0' }}>Additional Past Medical History Information</div>
            </b>
            <div style={{ fontSize: '12px' }}>
              <MedicalPassportTextArea2 name="procedure.name" label="Procedure" style={{ display: 'flex' }} fontSize={12} nobackground />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              {showQRCode && (
                <img
                  alt="Barcoded value"
                  src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
                />
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                border: '1px solid black',
                padding: '0.2rem',
                lineHeight: '15px',
              }}
            >
              <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} nobackground />
              <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '40% 60%',
                  alignContent: 'start',
                }}
              >
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} nobackground />
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                  fontSize={12}
                  nobackground
                />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12} nobackground />
              </div>
              <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
            </div>
          </div>
        </div>

        <div style={{ fontSize: '20px' }}>
          <b>Anesthesia Assessment - Additional Past Medical History</b>
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>CARDIOVASCULAR</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="addCardiovascular" value={cardiaclist} fontSize={'0.8rem'} nobackground />
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>PULMONARY</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="addPulmonary" value={pulmonarylist} fontSize={'0.8rem'} nobackground />
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>HEMATOLOGIC</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="addVascular" value={hematologicallist} fontSize={'0.8rem'} nobackground />
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>NEUROLOGICAL</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="addNeurological" value={neurologicallist} fontSize={'0.8rem'} nobackground />
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>ENDOCRINE/METABOLIC</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="addEndocrine" value={endocrinelist} fontSize={'0.8rem'} nobackground />
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>MUSCULOSKELETAL</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="addMusculoskeletal" value={musculolist} fontSize={'0.8rem'} nobackground />
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>DRUG HX</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="addDrug" value={drug} fontSize={'0.8rem'} nobackground />
        </div>
      </div>
      <footer style={{ display: 'grid', gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%', fontSize: '11px', height: '20px' }}>
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>{')'} <span style={{ fontSize: '14px', marginLeft: '9rem' }}><b>Ospitek, Inc</b></span></div>
      </footer>
    </div>
  );
};

export default Page5;
