import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import EntityView from '../../../se/components/entity/EntityView';
import { getNestedValue } from '../../../se/utilities/data/object';
import { TruncatedText } from '../../../se/components/typography';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import NumberInput from '../../../se/components/inputs/NumberInput';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import EntityEdit from '../../../se/components/entity/EntityEdit';
import queryString from 'query-string';
import CheckboxInput from '../../inputs/CheckboxInput';
import gql from 'graphql-tag';

const scheme = {
  item: gql`
    query signalProcessingConfig {
      signalProcessingConfig {
        dischargeTimeout
        unDischargeTimeout
        dischargeThreshold
        treatPACUasPREP
        treatPREPasPostop
      }
    }
  `,
  update: gql`
    mutation updateConfiguration(
      $swPeriod: Long
      $dischargeTimeout: Long
      $unDischargeTimeout: Long
      $dischargeThreshold: Long
      $treatPACUasPREP: Boolean
      $treatPREPasPostop: Boolean
    ) {
      updateConfiguration(
        swPeriod: $swPeriod
        dischargeTimeout: $dischargeTimeout
        unDischargeTimeout: $unDischargeTimeout
        dischargeThreshold: $dischargeThreshold
        treatPACUasPREP: $treatPACUasPREP
        treatPREPasPostop: $treatPREPasPostop
      )
    }
  `,
};

const columns = [
  {
    title: 'Patient Discharge Timeout',
    lens: data => getNestedValue('signalProcessingConfig.dischargeTimeout', data),
    Component: ({ data }) => <TruncatedText>{data ? `${data} seconds` : '-'}</TruncatedText>,
  },
  {
    title: 'UnDischarge Timeout',
    lens: data => getNestedValue('signalProcessingConfig.unDischargeTimeout', data),
    Component: ({ data }) => <TruncatedText>{data ? `${data} seconds` : '-'}</TruncatedText>,
  },
  {
    title: 'Discharge Threshold',
    lens: data => getNestedValue('signalProcessingConfig.dischargeThreshold', data),
    Component: ({ data }) => <TruncatedText>{data ? `${data} dBm` : '-'}</TruncatedText>,
  },
  {
    title: 'Treat Recovery as Pre-Op',
    lens: data => getNestedValue('signalProcessingConfig.treatPACUasPREP', data),
    Component: ({ data }) => <TruncatedText>{!!data ? 'Yes' : 'No'}</TruncatedText>,
  },
  {
    title: 'Treat Pre-Op as Post-Op',
    lens: data => getNestedValue('signalProcessingConfig.treatPREPasPostop', data),
    Component: ({ data }) => <TruncatedText>{!!data ? 'Yes' : 'No'}</TruncatedText>,
  },
];

const SecondsInput = withProps({
  placeholder: 'e.g., 180 seconds',
  autoComplete: 'off',
  required: true,
})(NumberInput);

const Input = withProps({
  schema: {
    dischargeTimeout: withLabel('Patient Discharge Timeout')(SecondsInput),
    unDischargeTimeout: withLabel('UnDischarge Timeout')(SecondsInput),
    dischargeThreshold: compose(
      withLabel('Discharge Threshold'),
      withProps({ placeholder: 'e.g. -80 dBm', required: true })
    )(NumberInput),
    treatPACUasPREP: compose(withProps({ label: 'Treat Recovery as Pre-Op before surgery' }))(CheckboxInput),
    treatPREPasPostop: compose(withProps({ label: 'Treat Pre-Op as Post-Op after surgery' }))(CheckboxInput),
  },
})(ObjectInput);

const DischargeConfiguration = ({ data, history, mutate }) => {
  const handleUpdate = data => {
    const { dischargeTimeout, unDischargeTimeout, dischargeThreshold, treatPACUasPREP, treatPREPasPostop } = data;
    mutate({
      variables: { dischargeTimeout, unDischargeTimeout, dischargeThreshold, treatPACUasPREP, treatPREPasPostop },
      refetchQueries: [{ query: scheme.item }],
    });
  };

  const isEdit = queryString.parse(history.location.search).edit;
  return (
    <Fragment>
      <EntityView columns={columns} data={data} />
      {isEdit && (
        <EntityEdit
          history={history}
          idProvider={() => {}}
          backUrlProvider={() => history.goBack}
          Input={Input}
          handleUpdate={handleUpdate}
          getValue={data => data['signalProcessingConfig']}
          data={data}
        />
      )}
    </Fragment>
  );
};

export default compose(graphql(scheme.item), graphql(scheme.update), withRouter)(DischargeConfiguration);
